import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigurationService, ModelItem, MaterialVariant } from '../../../configuration.service';

@Component({
  selector: 'app-equipment-box',
  templateUrl: './equipment-box.component.html',
  styleUrls: ['./equipment-box.component.css']
})
export class EquipmentBoxComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public imageUrl: string;

  @Input()
  public link: string;

  @Input()
  public info: string;

  @Input()
  public addClass: string;

  @Output()
  public linkClicked = new EventEmitter<void>();

  constructor(public configService: ConfigurationService) { }

  ngOnInit() {
  }

}
