/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./viewer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./viewer.component";
import * as i3 from "../configuration.service";
import * as i4 from "../serialization.service";
import * as i5 from "../animation.service";
import * as i6 from "../three.service";
import * as i7 from "../model.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../backend.service";
var styles_ViewerComponent = [i0.styles];
var RenderType_ViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewerComponent, data: {} });
export { RenderType_ViewerComponent as RenderType_ViewerComponent };
export function View_ViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { canvasContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["canvasContainer", 1]], null, 0, "div", [["class", "canvasContainer"]], [[2, "smooth-visible", null], [4, "background-color", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.configurationService.isReady && _co.threeService.isReady); var currVal_1 = (_co.threeService.nightMode ? _co.threeService.backgroundColorCSS_night : _co.threeService.backgroundColorCSS); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-viewer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).resize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ViewerComponent_0, RenderType_ViewerComponent)), i1.ɵdid(1, 114688, null, 0, i2.ViewerComponent, [i3.ConfigurationService, i4.SerializationService, i5.AnimationService, i6.ThreeService, i7.ModelService, i8.ActivatedRoute, i8.Router, i9.Location, i10.BackendService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewerComponentNgFactory = i1.ɵccf("app-viewer", i2.ViewerComponent, View_ViewerComponent_Host_0, {}, {}, []);
export { ViewerComponentNgFactory as ViewerComponentNgFactory };
