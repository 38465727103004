import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import {
  Product,
  ConfigurationService,
  ModelItem,
  Language,
  MaterialVariant
} from '../../configuration.service';
import { faTrashAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Subscription, Observable } from 'rxjs';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  map
} from 'rxjs/operators';

@Component({
  selector: 'app-product-materials',
  templateUrl: './product-materials.component.html',
  styleUrls: ['./product-materials.component.css']
})
export class ProductMaterialsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public showBody = false;
  @Input() public product: Product;

  public currentItem: ModelItem;
  public currentLanguage: Language;
  public nextTarget: string;

  faTrashAlt = faTrashAlt;
  faPlusSquare = faPlusSquare;

  public materialVariants: MaterialVariant[];

  private subscription: Subscription;

  constructor(public configurationService: ConfigurationService) {}

  ngOnInit() {
    this.updateCurrentItem();
  }

  changeCurrentItem(item: ModelItem) {
    this.currentItem = item;
    this.updateCurrentItem();
  }

  changeCurrentLanguage(language: Language) {
    this.currentLanguage = language;
    this.updateCurrentItem();
  }

  addMaterialVariant() {

    if (this.nextTarget === undefined || this.nextTarget.length === 0) {
      return;
    }

    if (Array.isArray(this.currentItem.materialVariantsTarget)) {
      this.currentItem.materialVariantsTarget.push(this.nextTarget);
    } else {
      this.currentItem.materialVariantsTarget = [this.nextTarget];
    }
    this.nextTarget = '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product && changes.product.currentValue) {
      if (this.currentItem && this.product.items) {
        this.currentItem = this.product.items.find(item => item.objectName === this.currentItem.objectName);
      }
    }
  }

  updateMaterialVariants() {
    this.materialVariants = this.configurationService.materials.map(
      material => ({
        materialName: material.name
      })
    );
    if (this.currentItem) {
      this.currentItem.materialVariants.forEach(existingVariant => {
        let index = this.materialVariants.findIndex(
          availableVariant =>
            availableVariant.materialName === existingVariant.materialName
        );
        if (index !== -1) {
          this.materialVariants[index] = existingVariant;
        }
      });
    }
  }

  updateCurrentItem() {
    if (this.currentItem === undefined) {
      return;
    }
    this.updateMaterialVariants();

    if (this.currentItem.materialVariantsTarget === undefined) {
      this.currentItem.materialVariantsTarget = [];
    }

    if (this.currentItem.materialVariants === undefined) {
      this.currentItem.materialVariants = [];
    }

    this.currentItem.materialVariants.forEach(variant => {
      if (variant.countries === undefined) {
        variant.countries = {};
      }
      if (
        this.currentLanguage &&
        variant.countries[this.currentLanguage.code] === undefined
      ) {
        variant.countries[this.currentLanguage.code] = {
          name: 'Label',
          price: 0.0
        };
      }
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term =>
          term.length < 2
            ? []
            : this.configurationService.modelIds
                .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
                .slice(0, 10)
      )
    )

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
