import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Vector2 } from 'three';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent implements OnInit {
  @Input() public left = 'assets/icon/functions/day.svg';
  @Input() public middle = 'assets/icon/functions/toggl-day.svg';
  @Input() public right = 'assets/icon/functions/night.svg';

  @Input()
  public state = false;

  @Output()
  public stateChanged = new EventEmitter<boolean>();

  private startPosition: Vector2;
  private currentPosition: Vector2;

  constructor() {}

  ngOnInit() {}

  @HostListener('touchstart', ['$event'])
  public touchStart(e: TouchEvent | any) {
    console.log('touch start');
    this.startPosition = new Vector2(e.touches.item(0).clientX, e.touches.item(0).clientX);
  }

  @HostListener('touchmove', ['$event'])
  public touchMove(e: TouchEvent | any) {
    console.log('touch move');
    this.currentPosition = new Vector2(e.touches.item(0).clientX, e.touches.item(0).clientX);
    if (this.startPosition && this.startPosition.distanceTo(this.currentPosition) > 40) {
      this.toggleState();
      this.startPosition = undefined;
    }

  }

  @HostListener('click')
  public clicked() {
    console.log('click');
    this.toggleState();
  }

  private toggleState() {
    this.state = !this.state;
    this.stateChanged.next(this.state);
  }

}
