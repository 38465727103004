import { OnInit } from '@angular/core';
var ChipsComponent = /** @class */ (function () {
    function ChipsComponent() {
    }
    ChipsComponent.prototype.ngOnInit = function () {
    };
    ChipsComponent.prototype.remove = function (item) {
        this.list.splice(this.list.indexOf(item), 1);
    };
    return ChipsComponent;
}());
export { ChipsComponent };
