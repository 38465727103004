import { OnInit } from '@angular/core';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
var ProductLanguageAvailabilityComponent = /** @class */ (function () {
    function ProductLanguageAvailabilityComponent() {
        this.showBody = false;
        this.faCheckDouble = faCheckDouble;
    }
    ProductLanguageAvailabilityComponent.prototype.ngOnInit = function () { };
    ProductLanguageAvailabilityComponent.prototype.change = function (item, language, state) {
        if (item.countryAvailability === undefined) {
            item.countryAvailability = [];
        }
        if (state === undefined) {
            state = !item.countryAvailability.includes(language.code);
        }
        if (item.countryAvailability.includes(language.code) && state === false) {
            var index = item.countryAvailability.indexOf(language.code); // <-- Not supported in <IE9
            if (index !== -1) {
                item.countryAvailability.splice(index, 1);
            }
        }
        else if (!item.countryAvailability.includes(language.code) &&
            state === true) {
            item.countryAvailability.push(language.code);
        }
    };
    ProductLanguageAvailabilityComponent.prototype.toggleAll = function (language) {
        var _this = this;
        var availableForLanguage = this.product.items.filter(function (item) {
            return item.countryAvailability !== undefined &&
                item.countryAvailability.includes(language.code);
        });
        // Target state is
        var targetState = availableForLanguage.length > (this.product.items.length / 2);
        if (availableForLanguage.length === this.product.items.length) {
            targetState = false;
        }
        if (availableForLanguage.length === 0) {
            targetState = true;
        }
        this.product.items.forEach(function (item) { return _this.change(item, language, targetState); });
    };
    return ProductLanguageAvailabilityComponent;
}());
export { ProductLanguageAvailabilityComponent };
