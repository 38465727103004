import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Product, Language, ModelItem } from '../../configuration.service';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-language-availability',
  templateUrl: './product-language-availability.component.html',
  styleUrls: ['./product-language-availability.component.css']
})
export class ProductLanguageAvailabilityComponent implements OnInit {
  @Input()
  public showBody = false;

  @Input()
  public product: Product;

  faCheckDouble = faCheckDouble;

  constructor() {}

  ngOnInit() {}

  change(item: ModelItem, language: Language, state?: boolean) {
    if (item.countryAvailability === undefined) {
      item.countryAvailability = [];
    }

    if (state === undefined) {
      state = !item.countryAvailability.includes(language.code);
    }

    if (item.countryAvailability.includes(language.code) && state === false) {
      const index = item.countryAvailability.indexOf(language.code); // <-- Not supported in <IE9
      if (index !== -1) {
        item.countryAvailability.splice(index, 1);
      }
    } else if (
      !item.countryAvailability.includes(language.code) &&
      state === true
    ) {
      item.countryAvailability.push(language.code);
    }
  }

  toggleAll(language: Language) {
    const availableForLanguage = this.product.items.filter(
      item =>
        item.countryAvailability !== undefined &&
        item.countryAvailability.includes(language.code)
    );

    // Target state is
    let targetState = availableForLanguage.length > (this.product.items.length / 2);
    if (availableForLanguage.length === this.product.items.length) {
      targetState = false;
    }

    if (availableForLanguage.length === 0) {
      targetState = true;
    }

    this.product.items.forEach(item => this.change(item, language, targetState));

  }
}
