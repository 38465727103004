import { OnInit } from '@angular/core';
var DropdownComponent = /** @class */ (function () {
    function DropdownComponent() {
        this.visible = false;
    }
    DropdownComponent.prototype.dblclick = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };
    DropdownComponent.prototype.ngOnInit = function () {
    };
    DropdownComponent.prototype.click = function () {
        this.visible = !this.visible;
    };
    return DropdownComponent;
}());
export { DropdownComponent };
