<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Appearance
    </div>
    <ul *ngIf="showBody" class="list-group list-group-flush">

      <li class="list-group-item">
        <form>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="envMap">Environment Map</label>
              <select class="form-control" name="envMap" [(ngModel)]="product.appearance.envMapName">
                <option *ngFor="let envMap of threeService.envmaps" [ngValue]="envMap.name">
                  {{envMap.name}}
                </option>
              </select>
            </div>
            <div class="form-group col-3">
              <label for="envMap">Environment Map Intensity</label>
              <input class="form-control" type="number" name="envMapIntensity" [(ngModel)]="product.appearance.envMapIntensity">
            </div>
            <div class="form-group col-3">
              <label for="envMap">Intensity (Night)</label>
              <input class="form-control" type="number" name="envMapIntensity_night" [(ngModel)]="product.appearance.envMapIntensity_night">
            </div>
            <div class="form-group col-6">
              <label for="viewerBackground">Viewer Background</label>
              <input class="form-control" name="viewerBackground" [(ngModel)]="product.appearance.viewerBackground">
            </div>
            <div class="form-group col-6">
              <label for="viewerBackground_night">Background (Night)</label>
              <input class="form-control" name="viewerBackground_night" [(ngModel)]="product.appearance.viewerBackground_night">
            </div>
            <div class="form-group col-6">
              <label for="toneMappingExposure">Renderer Tone Mapping Exposure</label>
              <input class="form-control" type="number" name="toneMappingExposure" [(ngModel)]="product.appearance.toneMappingExposure">
            </div>
          </div>
        </form>
      </li>
    </ul>
  </div>
