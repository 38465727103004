import { OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
var ProductCamerasComponent = /** @class */ (function () {
    function ProductCamerasComponent(configurationService) {
        this.configurationService = configurationService;
        this.showBody = false;
        this.faTrashAlt = faTrashAlt;
    }
    ProductCamerasComponent.prototype.ngOnInit = function () {
    };
    ProductCamerasComponent.prototype.ngOnChanges = function (changes) {
        if (this.product && this.product.screenshotCamera === undefined) {
            this.product.screenshotCamera = {
                cameraReference: '',
                frustum: 5,
                height: 200,
                width: 300
            };
        }
    };
    ProductCamerasComponent.prototype.create = function () {
        if (this.product.perspectives === undefined) {
            this.product.perspectives = [];
        }
        this.product.perspectives.push({
            name: 'Camera',
            cameraReference: 'Null',
            duration: 1200,
            lowerDistance: 100,
            upperDistance: 300,
            lockPosition: false,
            lockTarget: false,
            animation: true
        });
    };
    ProductCamerasComponent.prototype.remove = function (index) {
        this.product.perspectives.splice(index, 1);
    };
    ProductCamerasComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return ProductCamerasComponent;
}());
export { ProductCamerasComponent };
