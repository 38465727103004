import { PipeTransform } from '@angular/core';
var VisibleItemsPipe = /** @class */ (function () {
    function VisibleItemsPipe() {
    }
    VisibleItemsPipe.prototype.transform = function (value, args) {
        if (value) {
            return value.filter(function (v) { return v.visible; });
        }
        else {
            return null;
        }
    };
    return VisibleItemsPipe;
}());
export { VisibleItemsPipe };
