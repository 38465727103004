<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Model Dependencies
    </div>
    <div *ngIf="showBody" class="card-body">
      <div class="row">
        <div class="col">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              <span *ngIf="currentItem">{{currentItem.productId}} - {{currentItem.trivialName}}</span>
              <span *ngIf="currentItem === undefined">Select Item</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let item of product.items" class="dropdown-item" (click)="currentItem = item">{{item.productId}} - {{item.trivialName}}</button>
            </div>
          </div>
        </div>
      </div>
      <form *ngIf="currentItem">
        <div class="form-row">
          <div class="form-group col">
            <label for="productDependencies">Product Dependencies</label>
            <select multiple name="productDependencies" [(ngModel)]="currentItem.productDependencies" class="form-control" id="productDependencies">
              <option *ngFor="let item of product.items" [value]="item.productId">{{item.productId}} - {{item.trivialName}}</option>
            </select>
          </div>
          <div class="form-group col">
            <label for="viewDependencies">View Dependencies</label>
            <select multiple name="viewDependencies" [(ngModel)]="currentItem.viewDependencies" class="form-control" id="viewDependencies">
              <option *ngFor="let item of product.items" [value]="item.productId">{{item.productId}} - {{item.trivialName}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col">
            <label for="viewCollisions">View Collisions</label>
            <select multiple name="viewCollisions" [(ngModel)]="currentItem.viewCollisions" class="form-control" id="viewCollisions">
              <option *ngFor="let item of product.items" [value]="item.productId">{{item.productId}} - {{item.trivialName}}</option>
            </select>
          </div>
          <div class="form-group col">
            <label for="mandatoryAlternatives">Mandatory Alternatives</label>
            <select multiple name="mandatoryAlternatives" [(ngModel)]="currentItem.mandatoryAlternatives" class="form-control" id="mandatoryAlternatives">
              <option *ngFor="let item of product.items" [value]="item.productId">{{item.productId}} - {{item.trivialName}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <div class="form-check">
              <input class="form-check-input" [(ngModel)]="currentItem.initialVisibility" name="published" type="checkbox" id="published">
              <label class="form-check-label" for="published">Initially visible</label>
            </div>
          </div>
          <div class="form-group col-6">
            <div class="form-check">
              <input class="form-check-input" [(ngModel)]="currentItem.userSelectable" name="userSelectable" type="checkbox" id="userSelectable">
              <label class="form-check-label" for="userSelectable">User can select</label>
            </div>
          </div>
          <div class="form-group col-6">
            <div class="form-check">
              <input class="form-check-input" [(ngModel)]="currentItem.invisibleForScreenshot" name="invisibleForScreenshot" type="checkbox" id="invisibleForScreenshot">
              <label class="form-check-label" for="invisibleForScreenshot">Invisible for screenshot</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
