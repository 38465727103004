var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as feathers from '@feathersjs/feathers';
import * as auth from '@feathersjs/authentication-client';
import * as io from 'socket.io-client';
import * as socketio from '@feathersjs/socketio-client';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
function reviveDates(d) {
    if (d.updatedAt) {
        d.udpatedAt = new Date(d.updatedAt);
    }
    if (d.createdAt) {
        d.createdAt = new Date(d.createdAt);
    }
}
var BackendService = /** @class */ (function () {
    function BackendService(http) {
        this.http = http;
        this.loggedIn = false;
        this.progress = new BehaviorSubject(undefined);
        console.log("Alber Testdrive backend " + environment.apiUrl);
        this.app = feathers()
            .configure(socketio(io(environment.socketio.url, environment.socketio.options)))
            .configure(auth({ storage: localStorage }));
        this.productService = this.app.service('products');
        this.serializationService = this.app.service('serializations');
        this.sessionService = this.app.service('sessions');
        this.userService = this.app.service('users');
        this.printService = this.app.service('print');
        this.upService = this.app.service('product-upload');
        this.installHooks();
    }
    BackendService.prototype.uploadImageFromCanvas = function (canvas, id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var httpOptions, image;
            return __generator(this, function (_a) {
                httpOptions = {
                    headers: new HttpHeaders({
                        Authorization: this.accessToken ? this.accessToken : ''
                    })
                };
                image = new Image();
                image.src = canvas
                    .toDataURL('image/png')
                    .replace('image/png', 'image/octet-stream');
                return [2 /*return*/, new Promise(function (resolve) {
                        image.onload = function () { return __awaiter(_this, void 0, void 0, function () {
                            var mycanvas, ctx, blob, formData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        mycanvas = document.createElement('canvas');
                                        mycanvas.width = canvas.width;
                                        mycanvas.height = canvas.height;
                                        ctx = mycanvas.getContext('2d');
                                        ctx.fillStyle = 'black';
                                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                                        ctx.scale(1, -1); // Set scale to flip the image
                                        ctx.drawImage(image, 0, -image.height, image.width, image.height); // draw the image
                                        return [4 /*yield*/, this.getPNGBlob(mycanvas)];
                                    case 1:
                                        blob = _a.sent();
                                        formData = new FormData();
                                        formData.append('a_file', blob, 'screenshot.png');
                                        formData.append('id', id);
                                        this.http
                                            .post(this.screenshotBackendUrl(), formData, httpOptions)
                                            .pipe(catchError(function (err, cought) {
                                            console.log(err);
                                            return of(err);
                                        }))
                                            .subscribe(function (res) {
                                            console.log(res);
                                            resolve(res.files[0]);
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); };
                    })];
            });
        });
    };
    BackendService.prototype.screenshotBackendUrl = function () {
        return environment.apiUrl ? environment.apiUrl + '/upload' : '/upload';
    };
    BackendService.prototype.modelBackendUrl = function () {
        return environment.apiUrl
            ? environment.apiUrl + '/product-upload'
            : '/product-upload';
    };
    BackendService.prototype.uploadFiles = function (files, url, type) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var formData, file, thisFile, httpOptions, request;
            return __generator(this, function (_a) {
                if (url === undefined) {
                    throw new Error('No model context given');
                }
                formData = new FormData();
                formData.append('type', type);
                formData.append('url', url);
                for (file in files) {
                    if (files.hasOwnProperty(file)) {
                        thisFile = files[file];
                        formData.append("file_" + file, thisFile);
                    }
                }
                httpOptions = {
                    headers: new HttpHeaders({
                        Authorization: this.accessToken ? this.accessToken : ''
                    }),
                    reportProgress: true
                };
                request = new HttpRequest('POST', this.modelBackendUrl(), formData, httpOptions);
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .request(request)
                            .pipe(catchError(function (err, cought) {
                            console.log(err);
                            return of(err);
                        }))
                            .subscribe(function (event) {
                            switch (event.type) {
                                case HttpEventType.Sent:
                                    _this.progress.next(0);
                                    break;
                                case HttpEventType.UploadProgress:
                                    if (event.total) {
                                        _this.progress.next((event.loaded / event.total) * 100);
                                    }
                                    else {
                                        _this.progress.next(-1);
                                    }
                                    break;
                                case HttpEventType.Response:
                                    _this.progress.next(undefined);
                                    resolve(event.body.files);
                                    break;
                            }
                        });
                    })];
            });
        });
    };
    BackendService.prototype.getPNGBlob = function (canvas) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        canvas.toBlob(function (blob) {
                            resolve(blob);
                        }, 'image/png');
                    })];
            });
        });
    };
    BackendService.prototype.login = function (credentials) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var options;
            return __generator(this, function (_a) {
                options = credentials
                    ? {
                        strategy: 'local',
                        email: credentials.email,
                        password: credentials.password
                    }
                    : undefined;
                return [2 /*return*/, this.app
                        .authenticate(options)
                        .then(function (res) {
                        _this.accessToken = res.accessToken;
                        return _this.app.passport.verifyJWT(res.accessToken);
                    })
                        .then(function (payload) {
                        return _this.userService.get(payload.userId);
                    })
                        .then(function (user) {
                        _this.loggedIn = true;
                        _this.email = user.email;
                        return _this.loggedIn;
                    })];
            });
        });
    };
    BackendService.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.app
                        .logout()
                        .then(function () {
                        _this.loggedIn = false;
                    })
                        .then(function () {
                        return true;
                    })];
            });
        });
    };
    BackendService.prototype.dateReviveHook = function (hook) {
        if (hook.result) {
            if (hook.result.data) {
                hook.result.data.forEach(reviveDates);
            }
            else if (Array.isArray(hook.result)) {
                hook.result.forEach(reviveDates);
            }
            else {
                reviveDates(hook.result);
            }
        }
        return hook;
    };
    BackendService.prototype.installHooks = function () {
        this.productService.hooks({ after: { all: this.dateReviveHook } });
    };
    BackendService.ngInjectableDef = i0.defineInjectable({ factory: function BackendService_Factory() { return new BackendService(i0.inject(i1.HttpClient)); }, token: BackendService, providedIn: "root" });
    return BackendService;
}());
export { BackendService };
