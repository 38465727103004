import { Injectable } from '@angular/core';
import { ConfigurationService, ModelItem } from './configuration.service';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class SerializationService {
  public screenshotUrl: string;
  public lastConfigurationVersion: number;
  public serializationId: string;
  public locked: boolean;

  constructor(
    private configService: ConfigurationService,
    private backend: BackendService
  ) {}

  public async serialize(id?: string, canvas?: HTMLCanvasElement) {
    if (this.locked) {
      id = undefined;
    }

    if (
      this.lastConfigurationVersion === this.configService.configurationVersion && !this.locked
    ) {
      return this.serializationId;
    }

    if (this.locked) {
      this.locked = false;
    }


    this.lastConfigurationVersion = this.configService.configurationVersion;

    let visibilities = this.configService.items.filter(item => item.userSelectable).map(item => ({
      productId: item.productId,
      visibility: item.visible,
      variantMaterialName: item.currentVariant
        ? item.currentVariant.materialName
        : undefined
    }));

    let shoppingCart = this.configService.shoppingCart.map(item => ({
      productId: item.modelItem.productId,
      amount: item.amount
    }));

    let object = {
      visibilities: visibilities,
      shoppingCart: shoppingCart,
      language: this.configService.language.code,
      screenshotUrl: this.screenshotUrl
    };

    if (id === undefined) {
      let res = await this.backend.serializationService.create(object);
      this.serializationId = res._id;
      id = res._id;
    } else {
      await this.backend.serializationService.update(id, object);
    }

    if (canvas) {
      try {
        await this.addScreenshot(id, canvas);
      } catch (e) {
        console.log('Error creating screenshot');
        console.log(e);
      }
    }

    return this.serializationId;
  }

  public async lock() {
    if (this.serializationId) {
      this.locked = true;
      let res = await this.backend.serializationService.patch(this.serializationId, {
        locked: true
      });
      // console.log(res);
    }
  }

  public async deserialize(id: string, languageOnly = false) {
    if (id === undefined) {
      return;
    }
    let object = await this.backend.serializationService.get(id);
    if (object === undefined || object == null) {
      throw new Error('No such saved configuration');
    }

    this.locked = (object.locked !== undefined) ? object.locked : false;

    if (languageOnly) {
      this.configService.languageHint = object.language;
      return;
    }

    this.screenshotUrl = object.screenshotUrl;

    object.visibilities.forEach(item => {
      let productId = item.productId;
      let visible = item.visibility;
      let targetItem = this.configService.items.find(
        thisItem => thisItem.productId === productId
      );
      this.configService.changeVisibility(targetItem, visible);

      let variant = targetItem.materialVariants.find(
        thisVariant => thisVariant.materialName === item.variantMaterialName
      );
      if (variant === undefined) {
        return;
      }
      targetItem.currentVariant = variant;
      this.configService.changeMaterialVariant(targetItem);
    });

    this.configService.removeAllCartItems();
    object.shoppingCart.forEach(item => {
      let productId = item.productId;
      let amount = item.amount;
      let targetItem = this.configService.items.find(
        thisItem => thisItem.productId === productId
      );
      this.configService.updateAmountForItem(targetItem, amount);
    });

    this.configService.language = this.configService.availableLanguages.find(
      thisLanguage => thisLanguage.code === object.language
    );
  }

  async addScreenshot(id: string, canvas: HTMLCanvasElement) {
    let fileURL = await this.backend.uploadImageFromCanvas(canvas, id);
    await this.backend.serializationService.patch(id, {
      screenshotUrl: fileURL
    });
  }
}
