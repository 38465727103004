import { OnInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import * as TWEEN from '@tweenjs/tween.js';
import { AnimationService } from '../../animation.service';
import { BehaviorSubject } from 'rxjs';
var SCROLL_DURATION = 700;
var SCROLL_AMOUNT = 0.4;
var ScrollListComponent = /** @class */ (function () {
    function ScrollListComponent(animationService) {
        this.animationService = animationService;
        this.activeDot = 0;
    }
    ScrollListComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateDots();
        }, 200);
        this.animationNeeded$ = new BehaviorSubject(false);
        this.animationService.registerAnimationSource(this.animationNeeded$);
    };
    ScrollListComponent.prototype.ngOnChanges = function (changes) {
        this.updateDots();
    };
    ScrollListComponent.prototype.resize = function () {
        this.updateDots();
    };
    ScrollListComponent.prototype.scroll = function (direction) {
        var smaller = this.longContent.nativeElement;
        // let bigger = this.contentContainer.nativeElement;
        var amount = (direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT) * smaller.clientWidth;
        this.executeScroll(smaller.scrollLeft + amount);
    };
    ScrollListComponent.prototype.executeScroll = function (scrollLeft) {
        var _this = this;
        var smaller = this.longContent.nativeElement;
        var bigger = this.contentContainer.nativeElement;
        var maxScroll = (bigger.clientWidth - smaller.clientWidth);
        scrollLeft = Math.min(maxScroll, scrollLeft);
        scrollLeft = Math.max(scrollLeft, 0);
        var tween = new TWEEN.Tween(smaller).to({
            scrollLeft: scrollLeft
        }, SCROLL_DURATION);
        tween.easing(TWEEN.Easing.Quartic.InOut);
        tween.onStop(function () {
            _this.animationNeeded$.next(false);
        });
        tween.onComplete(function () {
            _this.animationNeeded$.next(false);
        });
        this.animationNeeded$.next(true);
        tween.start();
    };
    ScrollListComponent.prototype.onScroll = function () {
        this.updateDots();
    };
    ScrollListComponent.prototype.activeDotChange = function (dot) {
        var smaller = this.longContent.nativeElement;
        this.executeScroll(smaller.clientWidth * dot);
    };
    ScrollListComponent.prototype.updateDots = function () {
        var _this = this;
        if (this.contentContainer && this.longContent) {
            var smaller_1 = this.longContent.nativeElement;
            var bigger_1 = this.contentContainer.nativeElement;
            setTimeout(function () {
                _this.dots = Math.ceil(bigger_1.clientWidth / smaller_1.clientWidth);
                _this.activeDot = Math.floor(smaller_1.scrollLeft / smaller_1.clientWidth);
                if (smaller_1.scrollLeft === bigger_1.clientWidth - smaller_1.clientWidth) {
                    _this.activeDot = _this.dots - 1;
                }
            }, 100);
        }
    };
    return ScrollListComponent;
}());
export { ScrollListComponent };
