import {
  Component,
  OnInit,
  HostListener,
  Input,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  @Input()
  public title: string;

  @HostBinding('class.active')
  public visible = false;

  @HostListener('dblclick', ['$event'])
  dblclick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  constructor() {}

  ngOnInit() {
  }

  @HostListener('click')
  click() {
    this.visible = !this.visible;
  }
}
