/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shop.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../is-active-language.pipe";
import * as i5 from "./dropdown/dropdown.component.ngfactory";
import * as i6 from "./dropdown/dropdown.component";
import * as i7 from "./active-equipment/active-equipment.component.ngfactory";
import * as i8 from "./active-equipment/active-equipment.component";
import * as i9 from "../configuration.service";
import * as i10 from "../clipboard.service";
import * as i11 from "../social-share.service";
import * as i12 from "../google-analytics.service";
import * as i13 from "../viewer/viewer.component.ngfactory";
import * as i14 from "../viewer/viewer.component";
import * as i15 from "../serialization.service";
import * as i16 from "../animation.service";
import * as i17 from "../three.service";
import * as i18 from "../model.service";
import * as i19 from "@angular/router";
import * as i20 from "../backend.service";
import * as i21 from "./control-buttons/control-buttons.component.ngfactory";
import * as i22 from "./control-buttons/control-buttons.component";
import * as i23 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i24 from "./selector/selector.component.ngfactory";
import * as i25 from "./selector/selector.component";
import * as i26 from "../progress.service";
import * as i27 from "./shop.component";
var styles_ShopComponent = [i0.styles];
var RenderType_ShopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShopComponent, data: {} });
export { RenderType_ShopComponent as RenderType_ShopComponent };
function View_ShopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Back to Alber")); _ck(_v, 1, 0, currVal_0); }); }
function View_ShopComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.redirectLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_ShopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "flex-item mr-4 image-full button-fullscreen clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.threeService.fullscreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "mr-2"], ["height", "40px"], ["src", "assets/shop/icon/functions/fullscreen.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "flex-item button button-alt mr-2 image-full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.window.location.href = _co.getLinkBack()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.redirectLabel == null); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.redirectLabel != null); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Fullscreen")); _ck(_v, 3, 0, currVal_0); }); }
function View_ShopComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "drop-item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.configService.language = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.configService.language === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_1); }); }
function View_ShopComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Back to Alber")); _ck(_v, 1, 0, currVal_0); }); }
function View_ShopComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.redirectLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_ShopComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "burger-item clickable"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.configService.language = _v.context.$implicit;
        var pd_0 = ((_co.burgerMenu = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "ml-2 mr-2 mb-1"], ["height", "15"], ["src", "assets/shop/icon-world.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.configService.language === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_1); }); }
function View_ShopComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "burger-item clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.window.location.href = _co.getLinkBack()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_7)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_8)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ShopComponent_9)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(8, 1), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.redirectLabel == null); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.redirectLabel != null); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), _co.configService.availableLanguages)); _ck(_v, 7, 0, currVal_2); }, null); }
function View_ShopComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "image-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notInIframe; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.IsActiveLanguagePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "flex-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "logo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "img", [["class", "image-full mt-2 mb-2"], ["height", "90px"]], [[8, "src", 4]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "image-mobile mt-2 mb-2 ml-2"], ["height", "50px"], ["src", "assets/shop/logo/logo.alber.mobile.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "flex-grower"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "app-dropdown", [["class", "flex-item button button-language image-full"]], [[2, "active", null]], [[null, "dblclick"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("dblclick" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dblclick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).click() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DropdownComponent_0, i5.RenderType_DropdownComponent)), i1.ɵdid(12, 114688, null, 0, i6.DropdownComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_ShopComponent_4)), i1.ɵdid(14, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(15, 1), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "flex-item image-mobile burger-menu clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.burgerMenu = !_co.burgerMenu) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["class", "mr-2 ml-2"], ["height", "16px"], ["src", "assets/shop/button-burger.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopComponent_5)), i1.ɵdid(21, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "app-active-equipment", [["class", "large-show"]], null, null, null, i7.View_ActiveEquipmentComponent_0, i7.RenderType_ActiveEquipmentComponent)), i1.ɵdid(23, 114688, null, 0, i8.ActiveEquipmentComponent, [i9.ConfigurationService, i10.ClipboardService, i11.SocialShareService, i12.GoogleAnalyticsService], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "app-viewer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).resize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ViewerComponent_0, i13.RenderType_ViewerComponent)), i1.ɵdid(25, 114688, null, 0, i14.ViewerComponent, [i9.ConfigurationService, i15.SerializationService, i16.AnimationService, i17.ThreeService, i18.ModelService, i19.ActivatedRoute, i19.Router, i3.Location, i20.BackendService], null, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-control-buttons", [["class", "large-show"]], null, null, null, i21.View_ControlButtonsComponent_0, i21.RenderType_ControlButtonsComponent)), i1.ɵdid(27, 114688, null, 0, i22.ControlButtonsComponent, [i16.AnimationService, i17.ThreeService, i11.SocialShareService, i15.SerializationService, i23.NgbModal, i12.GoogleAnalyticsService], null, null), (_l()(), i1.ɵeld(28, 0, null, null, 1, "app-selector", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).resize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i24.View_SelectorComponent_0, i24.RenderType_SelectorComponent)), i1.ɵdid(29, 1163264, null, 0, i25.SelectorComponent, [i9.ConfigurationService, i17.ThreeService, i15.SerializationService, i16.AnimationService, i26.ProgressService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.notInIframe; _ck(_v, 10, 0, currVal_1); var currVal_3 = ((_co.configService.language == null) ? null : _co.configService.language.shortLabel); _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), _co.configService.availableLanguages)); _ck(_v, 14, 0, currVal_4); var currVal_6 = _co.burgerMenu; _ck(_v, 21, 0, currVal_6); _ck(_v, 23, 0); _ck(_v, 25, 0); _ck(_v, 27, 0); _ck(_v, 29, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("LOGO_URL")), ""); _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 12).visible; _ck(_v, 11, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("Menu")); _ck(_v, 17, 0, currVal_5); }); }
export function View_ShopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shop", [], null, null, null, View_ShopComponent_0, RenderType_ShopComponent)), i1.ɵdid(1, 114688, null, 0, i27.ShopComponent, [i9.ConfigurationService, i17.ThreeService, i16.AnimationService, i2.TranslateService, i19.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShopComponentNgFactory = i1.ɵccf("app-shop", i27.ShopComponent, View_ShopComponent_Host_0, {}, {}, []);
export { ShopComponentNgFactory as ShopComponentNgFactory };
