import {
  Component,
  OnInit,
  QueryList,
  ContentChildren,
  HostListener,
  Input,
  AfterContentInit,
  Output,
  EventEmitter
} from '@angular/core';
import { TabItemComponent } from './tab-item/tab-item.component';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit, AfterContentInit {
  @Input()
  public maxWidth = 810;

  @Input()
  private activeTitle;

  @ContentChildren(TabItemComponent)
  items: QueryList<TabItemComponent>;

  @Output()
  titleChange = new EventEmitter<string>();

  public activeTab: TabItemComponent;
  public isSmall = false;

  constructor(public ga: GoogleAnalyticsService) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.resize();
    if ((!this.isSmall || this.activeTitle) && this.activeTab === undefined) {
      this.select(
        this.items.find((item, index) => item.title === this.activeTitle) ||
          this.items.first
      );
    }
  }

  @HostListener('window:resize')
  public resize() {
    this.isSmall = window.innerWidth <= this.maxWidth;

    if (this.items.find(i => i === this.activeTab) === undefined) {
      this.select(
        this.items.find((item, index) => item.title === this.activeTitle) ||
          this.items.first
      );
    }
  }

  getOutletContext(item, index) {
    return {
      $implicit: item === this.activeTab,
      isSmall: this.isSmall,
      first: index === 0,
      last: index === this.items.length - 1,
      select: () => {
        this.select(item);
      }
    };
  }

  public select(item: TabItemComponent) {
    if (this.isSmall && this.activeTab === item) {
      this.activeTab = undefined;
    } else {
      this.activeTab = item;
    }

    this.ga.setPage('/viewer/e-pilot/' + item.title);

    // Defer for later to avoid changes after view check errors
    setTimeout(() => {
      this.titleChange.emit(this.activeTab ? this.activeTab.title : undefined);
    });
  }
}
