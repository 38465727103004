/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../item-language.pipe";
import * as i3 from "../../../item-category.pipe";
import * as i4 from "../is-user-selectable.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./article-overview.component";
import * as i7 from "../../../configuration.service";
var styles_ArticleOverviewComponent = [i0.styles];
var RenderType_ArticleOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArticleOverviewComponent, data: {} });
export { RenderType_ArticleOverviewComponent as RenderType_ArticleOverviewComponent };
function View_ArticleOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "element"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "image"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "preformatted-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "url('", _v.context.$implicit.imageUrl, "')"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.information.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.information.description; _ck(_v, 5, 0, currVal_2); }); }
export function View_ArticleOverviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ItemLanguagePipe, []), i1.ɵpid(0, i3.ItemCategoryPipe, []), i1.ɵpid(0, i4.IsUserSelectablePipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 4, null, View_ArticleOverviewComponent_1)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(7, 1), i1.ɵppd(8, 2), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "element pseudo-element"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "element pseudo-element"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 2), _co.configService.items)), _co.category)), _co.configService.language)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 4, 0, currVal_0); }); }
export function View_ArticleOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-article-overview", [], null, null, null, View_ArticleOverviewComponent_0, RenderType_ArticleOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i6.ArticleOverviewComponent, [i7.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleOverviewComponentNgFactory = i1.ɵccf("app-article-overview", i6.ArticleOverviewComponent, View_ArticleOverviewComponent_Host_0, { heading: "heading", category: "category" }, {}, []);
export { ArticleOverviewComponentNgFactory as ArticleOverviewComponentNgFactory };
