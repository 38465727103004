import { Injectable, NgZone, EventEmitter, HostListener } from '@angular/core';

import 'imports-loader?THREE=three!three/examples/js/controls/OrbitControls';
import 'imports-loader?THREE=three!three/examples/js/pmrem/PMREMCubeUVPacker';
import 'imports-loader?THREE=three!three/examples/js/pmrem/PMREMGenerator';

import * as THREE from 'three';
import { ScreenshotCamera } from './configuration.service';
import { Object3D, Vector3 } from 'three';
import { HttpClient } from '../../node_modules/@angular/common/http';
import * as TWEEN from '@tweenjs/tween.js';
import { BehaviorSubject } from '../../node_modules/rxjs';
import { GoogleAnalyticsService } from './google-analytics.service';

const myScreen = screen as any;

@Injectable({
  providedIn: 'root'
})
export class ThreeService extends EventEmitter<any> {
  public window = window as any;

  public scene: THREE.Scene;
  public renderer: THREE.WebGLRenderer;
  public rendererPromise: Promise<THREE.WebGLRenderer>;
  public camera: THREE.PerspectiveCamera;
  public controls: THREE.OrbitControls;
  public nightModeShift$ = new BehaviorSubject<boolean>(false);
  public envMapIntensity = 0;
  public envMapIntensity_night = 0;
  public currentEnvMapIntensity = 0;

  private _canvasContainer: HTMLElement;
  private _screenshotCamera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
  private _screenshotSetting: ScreenshotCamera;
  public _rendering: boolean;
  public isReady = false;
  public renderingDisabled = false;
  public nightMode = false;
  public backgroundColorCSS: string;
  public backgroundColorCSS_night: string;
  public screenshotUnwantedObjectNames: string[];

  public materials = new Set<THREE.MeshStandardMaterial>();

  private resolveRenderer: (renderer: THREE.WebGLRenderer) => void;

  public envmaps;
  public _envmap;
  private envmapTexture: THREE.CubeTexture;

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    public ga: GoogleAnalyticsService
  ) {
    super();

    (window as any).threeService = this;

    this.registerFullscreenChanges();
    this.camera = new THREE.PerspectiveCamera(45);
    this.scene = new THREE.Scene();

    this.scene.addEventListener('added', event => {
      console.log(event);
    });

    this.http.get('assets/envmap/envmaps.json').subscribe((data: any) => {
      this.envmaps = data.envmaps;
      this.envmap =
        this.envmaps.find(map => map.name === data.default) || this.envmaps[0];
    });

    this.rendererPromise = new Promise<THREE.WebGLRenderer>(resolve => {
      this.resolveRenderer = resolve;
    });

    this.window.scene = this.scene;
  }

  public fullscreen() {
    let domElement = this.renderer.domElement.parentElement as any;
    if ('requestFullscreen' in domElement) {
      domElement.requestFullscreen();
    } else if ('webkitRequestFullscreen' in domElement) {
      domElement.webkitRequestFullscreen();
    } else if ('mozRequestFullScreen' in domElement) {
      domElement.mozRequestFullScreen();
    } else if ('msRequestFullscreen' in domElement) {
      domElement.msRequestFullscreen();
    }
    this.controls.autoRotateSpeed = 0.4;
  }

  private registerFullscreenChanges() {
    let handler = () => {

      const isFullscreen = ((window as any).fullScreen) ||
      (window.innerWidth === screen.width && window.innerHeight === screen.height);

      this.controls.autoRotate = isFullscreen;

      this.ga.sendEvent('Fullscreen', 'Page functions', isFullscreen ? 'Active' : 'Inactive');

      this.controls.dispatchEvent({ target: this, type: 'change' });
    };
    document.onfullscreenchange = handler;
    (document as any).onwebkitfullscreenchange = handler;
    (document as any).onmozfullscreenchange = handler;
  }

  public clearScene() {
    this.scene.children = [];
  }
  public addToScene(object: Object3D) {
    this.scene.add(object);
    this.applyEnvmap();
    this.createMaterialList();
  }

  public createMaterialList() {
    this.materials.clear();
    this.scene.traverse((child: any) => {
      if ('material' in child) {
        this.materials.add(child.material);
      }
    });
  }

  set domElement(value: HTMLElement) {
    this._canvasContainer = value;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.resolveRenderer(this.renderer);

    this.window.renderer = this.renderer;
    this.window.fixLight = () => {
      let material = (this.scene.getObjectByName('light_planeShape') as any)
        .material;
      material.alphaMap = material.emissiveMap;
      material.emissiveMap = null;
      material.emissive = new THREE.Color('white');
      material.needsUpdate = true;
    };

    while (value.firstChild) {
      value.removeChild(value.firstChild);
    }
    value.appendChild(this.renderer.domElement);

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.gammaOutput = true;

    if (this.controls) {
      this.controls.dispose();
    }

    this.controls = new THREE.OrbitControls(
      this.camera,
      this.renderer.domElement
    );
    this.controls.addEventListener('end', () => {
      const isFullscreen = ((window as any).fullScreen) ||
      (window.innerWidth === screen.width && window.innerHeight === screen.height);
      if (!isFullscreen) {
        this.controls.autoRotate = false;
      }
    });
    this.controls.maxPolarAngle = 1.746;
    this.controls.autoRotateSpeed = 0.4;
    this.controls.autoRotate = true;
    this.controls.enablePan = false;
    this.resize();
  }

  set screenshotCamera(screenshotCamera: ScreenshotCamera) {
    this._screenshotSetting = screenshotCamera;
    let aspect = screenshotCamera.width / screenshotCamera.height;

    this._screenshotCamera = new THREE.OrthographicCamera(
      (screenshotCamera.frustum * aspect) / -2,
      (screenshotCamera.frustum * aspect) / 2,
      screenshotCamera.frustum / 2,
      screenshotCamera.frustum / -2,
      0.25,
      2000
    );

    if (screenshotCamera.pointOfView) {
      this.scene.updateMatrixWorld(true);
      let pos = screenshotCamera.pointOfView.position.getWorldPosition(
        new Vector3()
      );
      let lookAt = screenshotCamera.pointOfView.lookAt.getWorldPosition(
        new Vector3()
      );
      this._screenshotCamera.position.copy(pos);
      this._screenshotCamera.lookAt(lookAt);
    }
  }

  get envmap() {
    return this._envmap;
  }

  set envmap(value: any) {
    if (this._envmap === value) {
      return;
    }
    this._envmap = value;
    this.updateEnvmap(value, true);
  }

  screenshot() {
    if (this._screenshotCamera === undefined) {
      console.warn('No screenshot camera defined');
      return;
    }

    let renderTarget = new THREE.WebGLRenderTarget(
      this._screenshotSetting.width,
      this._screenshotSetting.height,
      {
        minFilter: THREE.LinearMipMapLinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat
      }
    );

    // Take screenshot at daytime
    this.materials.forEach(material => {
      material.envMapIntensity = this.envMapIntensity;
    });

    // Make objects invisible that shouldn't be there
    let previousVisiblityState = this.screenshotUnwantedObjectNames.map(
      name => {
        let object = this.scene.getObjectByName(name);
        let previousState = object.visible;
        this.scene.getObjectByName(name).visible = false;
        return {
          object: object,
          previousState: previousState
        };
      }
    );

    this.renderer.setClearColor(new THREE.Color('white'), 1.0);

    this.renderer.render(
      this.scene,
      this._screenshotCamera,
      renderTarget,
      true
    );

    this.renderer.setClearColor(new THREE.Color('white'), 0.0);

    previousVisiblityState.forEach(state => {
      state.object.visible = state.previousState;
    });

    this.materials.forEach(material => {
      material.envMapIntensity = this.nightMode
        ? this.envMapIntensity_night
        : this.envMapIntensity;
    });

    let pixelBuffer = new Uint8Array(
      renderTarget.width * renderTarget.height * 4
    );
    this.renderer.readRenderTargetPixels(
      renderTarget,
      0,
      0,
      renderTarget.width,
      renderTarget.height,
      pixelBuffer
    );

    let clampedArray = new Uint8ClampedArray(pixelBuffer);
    let DAT = new ImageData(
      clampedArray,
      renderTarget.width,
      renderTarget.height
    );

    let mycanvas: HTMLCanvasElement = document.createElement('canvas');
    mycanvas.width = renderTarget.width;
    mycanvas.height = renderTarget.height;
    let ctx: CanvasRenderingContext2D = mycanvas.getContext('2d');

    ctx.putImageData(DAT, 0, 0);
    ctx.scale(1, -1);
    // ctx.translate(mycanvas.height, 0);
    // ctx.scale(1, -1);
    return mycanvas;
  }

  resize() {
    if (
      this.camera === undefined ||
      this.renderer === undefined ||
      this.renderer.domElement === undefined
    ) {
      return;
    }

    this.camera.aspect =
      this._canvasContainer.clientWidth / this._canvasContainer.clientHeight;
    this.camera.updateProjectionMatrix();
    this.camera.near = 0.25;
    this.camera.far = 2000;

    this.renderer.setSize(
      this._canvasContainer.clientWidth,
      this._canvasContainer.clientHeight
    );

    if (!this._rendering) {
      this.setRunning(true);
      this.setRunning(false);
    }
  }

  public setRunning(v: boolean) {
    if (v === this._rendering) {
      return;
    }
    this._rendering = v;
    if (this._rendering) {
      this.zone.runOutsideAngular(() => {
        this.runloop();
      });
    }
  }

  public setEnvMapIntensity() {}

  public setNightMode(value: boolean) {
    this.currentEnvMapIntensity = value
      ? this.envMapIntensity_night
      : this.envMapIntensity;
    if (this.nightMode === value) {
      return;
    }
    this.nightMode = value;

    this.ga.sendEvent('Night Mode', 'Page functions', value ? 'Active' : 'Inactive');

    let tweens = Array.from(this.materials).map(mat =>
      new TWEEN.Tween(mat).to(
        { envMapIntensity: this.currentEnvMapIntensity },
        1000
      )
    );
    if (tweens.length > 0) {
      tweens[0].onComplete(() => {
        this.nightModeShift$.next(false);
      });
    }
    this.nightModeShift$.next(true);
    tweens.forEach(tween => tween.start());
  }

  public runloop() {
    this.zone.run(() => {
      this.emit({ type: 'update' });
    });

    this.scene.updateMatrixWorld(true);
    this.renderer.render(this.scene, this.camera);

    if (this._rendering) {
      requestAnimationFrame(() => {
        this.runloop();
      });
    }
  }

  private async updateEnvmap(envmap, pmrem = true) {
    if (this.renderingDisabled === false) {
      await this.rendererPromise;
    }
    await new Promise(resolve => {
      new THREE.CubeTextureLoader().load(envmap.images, ldrCubeMap => {
        if (this.renderer === undefined || pmrem === false) {
          console.log('returnd ldr cubemap');
          ldrCubeMap.generateMipmaps = true;
          this.envmapTexture = ldrCubeMap;
          resolve(ldrCubeMap);
          return;
        }
        // ldrCubeMap.encoding = THREE.GammaEncoding;
        // ldrCubeMap.flipY = true;
        let t1 = performance.now();
        let pmremGenerator = new (THREE as any).PMREMGenerator(ldrCubeMap);
        pmremGenerator.update(this.renderer);
        // pmremGenerator.update(this._screenshotRenderer);
        let pmremCubeUVPacker = new (THREE as any).PMREMCubeUVPacker(
          pmremGenerator.cubeLods
        );
        pmremCubeUVPacker.update(this.renderer);
        // pmremCubeUVPacker.update(this._screenshotRenderer);
        let ldrCubeRenderTarget = pmremCubeUVPacker.CubeUVRenderTarget;
        ldrCubeMap.dispose();
        pmremGenerator.dispose();
        pmremCubeUVPacker.dispose();
        let t2 = performance.now();
        console.log('pmrem generation time', t2 - t1);
        this.envmapTexture = ldrCubeRenderTarget.texture;
        resolve();
      });
    });

    this.applyEnvmap();
    this.isReady = true;
  }

  private applyEnvmap() {
    this.scene.traverse((child: THREE.Mesh) => {
      if ('geometry' in child) {
        child.geometry.computeVertexNormals();
      }
      if ('material' in child && !Array.isArray(child.material)) {
        if ('envMap' in child.material) {
          (child.material as any).envMap = this.envmapTexture;
          (child.material as any).envMapIntensity = this.currentEnvMapIntensity;
          (child.material as any).needsUpdate = true;
        }
      } else if (Array.isArray(child.material)) {
        child.material.forEach(material => {
          (material as any).envMap = this.envmapTexture;
          (material as any).envMapIntensity = this.currentEnvMapIntensity;
          (material as any).needsUpdate = true;
        });
      }
    });
  }
}
