import { OnInit } from '@angular/core';
import { Product, ConfigurationService } from '../../configuration.service';
var ProductCategoryComponent = /** @class */ (function () {
    function ProductCategoryComponent(configurationService) {
        this.configurationService = configurationService;
        this.showBody = false;
    }
    ProductCategoryComponent.prototype.ngOnInit = function () { };
    return ProductCategoryComponent;
}());
export { ProductCategoryComponent };
