<div class="grey-background">
  <app-tabs class="content" (titleChange)="tabChanged($event)" activeTitle="Variants" [maxWidth]="810">

    <app-tab-item *ngIf="small" title="Control-Buttons">
      <ng-template tabItemTitle>
        <app-control-buttons></app-control-buttons>
      </ng-template>
      <ng-template tabItemContent>
        <app-active-equipment></app-active-equipment>
      </ng-template>
    </app-tab-item>

    <app-tab-item *ngIf="small" title="CurrentConfig">
      <ng-template tabItemTitle let-active let-first="first" let-select="select" let-isSmall="isSmall">
        <span class="tab clickable" (click)="select()" [class.first]="first" [class.active]="active" [class.small]="small">{{'Current
          Configuration' | translate}}
          <img *ngIf="small" [class.flip]="active" src="assets/shop/arrow-down.svg" height="10">
        </span>
      </ng-template>
      <ng-template tabItemContent>
        <app-active-equipment></app-active-equipment>
      </ng-template>
    </app-tab-item>

    <app-tab-item title="Variants">
      <ng-template tabItemTitle let-active let-first="first" let-select="select" let-isSmall="isSmall">
        <span class="tab clickable" (click)="select()" [class.first]="first" [class.active]="active" [class.small]="small">{{'Variant'
          | translate}}
          <span *ngIf="!small && active" class="active-lower-bar"></span>
          <img *ngIf="small" [class.flip]="active" src="assets/shop/arrow-down.svg" height="10">
        </span>
      </ng-template>
      <ng-template tabItemContent let-foo="bar">
        <app-scroll-list>
          <app-equipment-box *ngFor="let item of configService.items | isUserSelectable | itemCategory:'step1' | itemLanguage:configService.language" [title]="item.information.shortTitle"
            [imageUrl]="item.imageUrl" [link]="'Add to basket' | translate" [info]="(configService.isInShoppingCart(item) ? ('In basket' | translate): undefined)"
            (dblclick)="$event.preventDefault()" (click)="configService.changeVisibility(item, !item.visible); $event.preventDefault()"
            (linkClicked)="configService.updateAmountForItem(item)" [class.active]="item.visible">
          </app-equipment-box>
        </app-scroll-list>
      </ng-template>
    </app-tab-item>

    <app-tab-item title="Colors">
      <ng-template tabItemTitle let-active let-select="select" let-isSmall="isSmall">
        <span class="tab clickable" (click)="select()" [class.active]="active" [class.small]="small">{{'Colors' |
          translate}}
          <span *ngIf="!small && active" class="active-lower-bar"></span>
          <img *ngIf="small" [class.flip]="active" src="assets/shop/arrow-down.svg" height="10">
        </span>
      </ng-template>
      <ng-template tabItemContent>
        <app-scroll-list *ngFor="let item of variantItems | visibleItems">
          <app-equipment-box *ngFor="let variant of item.materialVariants" [title]="variant.information.name"
            [addClass]="'color-select'"
            [imageUrl]="variant.imageUrl" (dblclick)="$event.preventDefault()" (click)="item.currentVariant = variant; configService.changeMaterialVariant(item); $event.preventDefault()"
            [class.active]="item.currentVariant === variant">
          </app-equipment-box>
        </app-scroll-list>
        <div class="color-footnote">*{{'COLOR DISCLAIMER' | translate}}</div>
      </ng-template>
    </app-tab-item>

    <app-tab-item title="Equipment">
      <ng-template tabItemTitle let-active let-first="first" let-select="select" let-isSmall="isSmall">
        <span class="tab clickable" (click)="select()" [class.first]="first" [class.active]="active" [class.small]="small">{{'Equipment'
          | translate}}
          <span *ngIf="!small && active" class="active-lower-bar"></span>
          <img *ngIf="small" [class.flip]="active" src="assets/shop/arrow-down.svg" height="10">
        </span>
      </ng-template>
      <ng-template tabItemContent let-foo="bar">
        <app-scroll-list>
          <app-equipment-box *ngFor="let item of configService.items | isUserSelectable | itemCategory:'step2' | itemLanguage:configService.language" [title]="item.information.shortTitle"
            [imageUrl]="item.imageUrl" [link]="'Add to basket' | translate" [info]="(configService.isInShoppingCart(item) ? ('In basket' | translate): undefined)"
            (dblclick)="$event.preventDefault()" (click)="configService.changeVisibility(item, !item.visible); $event.preventDefault()"
            (linkClicked)="configService.updateAmountForItem(item)" [class.active]="item.visible">
          </app-equipment-box>
        </app-scroll-list>
      </ng-template>
    </app-tab-item>

    <app-tab-item title="Basket">
      <ng-template tabItemTitle let-active let-select="select" let-isSmall="isSmall" let-last="last">
        <span class="tab clickable" (click)="select()" [class.last]="last && !small" [class.active]="active" [class.small]="small">{{'Configuration' | translate}}
          <span *ngIf="configService.shoppingCart.length">({{configService.shoppingCart.length}})</span>
          <span *ngIf="!small && active" class="active-lower-bar"></span>
          <img *ngIf="small" [class.flip]="active" src="assets/shop/arrow-down.svg" height="10">
        </span>
      </ng-template>
      <ng-template tabItemContent>
        <app-shopping-basket>
        </app-shopping-basket>
      </ng-template>
    </app-tab-item>
  </app-tabs>
</div>
<div [class.invisible]="!progressService.active" class="progress-bar" [style.width]="progressService.progress * 100 + '%'"></div>

<div class="content articles large-show" *ngIf="!small && currentTabName === 'Variants' || currentTabName === 'Equipment'">
  <app-article-overview *ngIf="currentTabName == 'Variants'" [heading]="'E-Pilot Variants' | translate" [category]="'step1'" class="mt-4"></app-article-overview>
  <app-article-overview *ngIf="currentTabName == 'Equipment'" [heading]="'E-Pilot Equipments' | translate" [category]="'step2'" class="mt-4"></app-article-overview>
</div>
