import { Injectable } from '@angular/core';
import * as feathers from '@feathersjs/feathers';
import * as auth from '@feathersjs/authentication-client';
import * as io from 'socket.io-client';
import * as socketio from '@feathersjs/socketio-client';

import { environment } from '../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';

function reviveDates(d) {
  if (d.updatedAt) {
    d.udpatedAt = new Date(d.updatedAt);
  }
  if (d.createdAt) {
    d.createdAt = new Date(d.createdAt);
  }
}

interface UploadedFiles {
  message: string;
  files: string[];
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public client: feathers.Application;
  public loggedIn = false;
  public email;

  private app;
  private accessToken;

  public productService;
  public serializationService;
  public sessionService;
  public userService;
  public printService;
  public upService;

  public progress = new BehaviorSubject<number>(undefined);

  constructor(private http: HttpClient) {
    console.log(`Alber Testdrive backend ${environment.apiUrl}`);
    this.app = feathers()
      .configure(socketio(io(environment.socketio.url, environment.socketio.options)))
      .configure(auth({ storage: localStorage }));

    this.productService = this.app.service('products');
    this.serializationService = this.app.service('serializations');
    this.sessionService = this.app.service('sessions');
    this.userService = this.app.service('users');
    this.printService = this.app.service('print');
    this.upService = this.app.service('product-upload');

    this.installHooks();
  }

  async uploadImageFromCanvas(canvas: HTMLCanvasElement, id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.accessToken ? this.accessToken : ''
      })
    };

    let image = new Image();
    image.src = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');

    return new Promise<string>(resolve => {
      image.onload = async () => {


        let mycanvas: HTMLCanvasElement = document.createElement('canvas');
        mycanvas.width = canvas.width;
        mycanvas.height = canvas.height;

        let ctx = mycanvas.getContext('2d');

        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.scale(1, -1); // Set scale to flip the image
        ctx.drawImage(
          image,
          0,
          -image.height,
          image.width,
          image.height
        ); // draw the image

        let blob = await this.getPNGBlob(mycanvas);

        let formData = new FormData();

        formData.append('a_file', blob, 'screenshot.png');
        formData.append('id', id);

        this.http
          .post<UploadedFiles>(
            this.screenshotBackendUrl(),
            formData,
            httpOptions
          )
          .pipe(
            catchError((err, cought) => {
              console.log(err);
              return of(err);
            })
          )
          .subscribe(res => {
            console.log(res);
            resolve(res.files[0]);
          });
      };
    });
  }

  private screenshotBackendUrl() {
    return environment.apiUrl ? environment.apiUrl + '/upload' : '/upload';
  }

  private modelBackendUrl() {
    return environment.apiUrl
      ? environment.apiUrl + '/product-upload'
      : '/product-upload';
  }

  async uploadFiles(files: File[], url: string, type: string) {
    if (url === undefined) {
      throw new Error('No model context given');
    }

    let formData = new FormData();
    formData.append('type', type);
    formData.append('url', url);
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        let thisFile = files[file];
        formData.append(`file_${file}`, thisFile);
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.accessToken ? this.accessToken : ''
      }),
      reportProgress: true
    };

    const request = new HttpRequest(
      'POST',
      this.modelBackendUrl(),
      formData,
      httpOptions
    );

    return new Promise<string[]>(resolve => {
      this.http
        .request(request)
        .pipe(
          catchError((err, cought) => {
            console.log(err);
            return of(err);
          })
        )
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.progress.next(0);
              break;
            case HttpEventType.UploadProgress:
              if (event.total) {
                this.progress.next((event.loaded / event.total) * 100);
              } else {
                this.progress.next(-1);
              }
              break;
            case HttpEventType.Response:
              this.progress.next(undefined);
              resolve(event.body.files);
              break;
          }
        });
    });
  }

  async getPNGBlob(canvas: HTMLCanvasElement) {
    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(blob => {
        resolve(blob);
      }, 'image/png');
    });
  }

  async login(credentials?) {
    let options = credentials
      ? {
          strategy: 'local',
          email: credentials.email,
          password: credentials.password
        }
      : undefined;

    return this.app
      .authenticate(options)
      .then(res => {
        this.accessToken = res.accessToken;
        return this.app.passport.verifyJWT(res.accessToken);
      })
      .then(payload => {
        return this.userService.get(payload.userId);
      })
      .then(user => {
        this.loggedIn = true;
        this.email = user.email;
        return this.loggedIn;
      });
  }

  async logout() {
    return this.app
      .logout()
      .then(() => {
        this.loggedIn = false;
      })
      .then(() => {
        return true;
      });
  }

  dateReviveHook(hook: any) {
    if (hook.result) {
      if (hook.result.data) {
        hook.result.data.forEach(reviveDates);
      } else if (Array.isArray(hook.result)) {
        hook.result.forEach(reviveDates);
      } else {
        reviveDates(hook.result);
      }
    }
    return hook;
  }
  installHooks() {
    this.productService.hooks({ after: { all: this.dateReviveHook } });
  }
}
