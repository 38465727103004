import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  public active = false;
  public _progress = 0;

  constructor() {}

  get progress() {
    return this._progress;
  }

  set progress(value: number) {
    this._progress = value;
    if (this._progress > 0) {
      this.active = true;
    }
    if (this._progress > 0.99) {
      setTimeout(() => {
        this.active = false;
      }, 2000);
    }
  }
}
