import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { ThreeService } from '../../three.service';
var ProductAnimationComponent = /** @class */ (function () {
    function ProductAnimationComponent(configService, threeService) {
        this.configService = configService;
        this.threeService = threeService;
        this.showBody = false;
    }
    ProductAnimationComponent.prototype.ngOnInit = function () { };
    ProductAnimationComponent.prototype.ngOnChanges = function (changes) {
        if (this.product && this.product.animation === undefined) {
            this.product.animation = {
                dependencies: [],
                startState: 'begin',
                timeScale: 1.0
            };
        }
    };
    return ProductAnimationComponent;
}());
export { ProductAnimationComponent };
