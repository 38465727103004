import { Component, OnInit, Input } from '@angular/core';
import {
  ModelItem,
  ConfigurationService,
  Language,
  Product
} from '../../configuration.service';
import * as _ from 'lodash';
import { BackendService } from '../../backend.service';

@Component({
  selector: 'app-product-item-detail',
  templateUrl: './product-item-detail.component.html',
  styleUrls: ['./product-item-detail.component.css']
})
export class ProductItemDetailComponent implements OnInit {
  @Input() showBody = false;
  @Input() product: Product;

  public currentItem: ModelItem;
  public currentLanguage: Language;
  public acceptFile: boolean;

  constructor(
    public configService: ConfigurationService,
    public backendService: BackendService
  ) {}

  ngOnInit() {
    // Debug
    // this.currentItem = this.product.items[0];
  }

  eventIsAccepted(event) {
    if (event.dataTransfer) {
      if (event.dataTransfer.items.length === 0) {
        return false;
      }
      let types = [];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        types.push(event.dataTransfer.items[i].type);
      }
      return types.every(type => type === 'image/png' || type === 'image/jpeg');
    } else {
      return false;
    }
  }

  async drop_handler(event: any) {
    event.preventDefault();
    let filenames = await this.backendService.uploadFiles(
      event.dataTransfer.files,
      this.configService.url,
      'items'
    );
    this.currentItem.imageUrl = filenames[0];
    this.acceptFile = false;
  }

  dragover_handler(e) {
    this.acceptFile = this.eventIsAccepted(e);
    event.preventDefault();
  }

  dragleave_handler(e) {
    this.acceptFile = false;
  }

  get showDetail() {
    if (this.currentItem && this.currentLanguage) {
      if (this.currentItem.countries === undefined) {
        this.currentItem.countries = {};
      }

      if (this.currentItem.countries[this.currentLanguage.code] === undefined) {
        this.currentItem.countries[this.currentLanguage.code] = {};
      }
    }
    return this.currentItem !== undefined && this.currentLanguage !== undefined;
  }
}
