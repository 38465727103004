var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as THREE from 'three';
import 'imports-loader?THREE=three!three/examples/js/loaders/FBXLoader';
import 'imports-loader?THREE=three!three/examples/js/loaders/GLTFLoader';
import 'imports-loader?THREE=three!three/examples/js/loaders/ColladaLoader';
import { ThreeService } from './three.service';
import { ProgressService } from './progress.service';
import * as i0 from "@angular/core";
import * as i1 from "./three.service";
import * as i2 from "./progress.service";
window.THREE = THREE;
var ModelService = /** @class */ (function () {
    function ModelService(threeService, progress) {
        this.threeService = threeService;
        this.progress = progress;
        this.pointsOfView = [];
    }
    ModelService.prototype.load = function (url, size) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var extension, loaderInfo, contents;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.manager = new THREE.LoadingManager();
                        this.manager.onStart = function (myurl, itemsLoaded, itemsTotal) {
                            _this.progress.progress = 0.1;
                        };
                        this.manager.onLoad = function () {
                            _this.progress.progress = 1.0;
                        };
                        this.manager.onProgress = function (myurl, itemsLoaded, itemsTotal) {
                            _this.progress.progress = itemsLoaded / itemsTotal;
                            // console.log('Set progress to: ' + this.progress.progress);
                        };
                        this.loadSize = size;
                        if (url === undefined) {
                            throw new Error('Undefined url');
                        }
                        extension = url.split('.').pop();
                        switch (extension.toLowerCase()) {
                            case 'fbx':
                                loaderInfo = this.loadFBX();
                                break;
                            case 'gltf':
                            case 'glb':
                                loaderInfo = this.loadGLTF();
                                break;
                            case 'dae':
                                loaderInfo = this.loadCollada();
                                break;
                            default:
                                return [2 /*return*/, Promise.reject('Unknown file format')];
                        }
                        return [4 /*yield*/, this.executeLoader(loaderInfo, url)];
                    case 1:
                        contents = _a.sent();
                        this.threeService.clearScene();
                        return [4 /*yield*/, this.threeService.addToScene(contents.group)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModelService.prototype.loadGLTF = function () {
        return {
            loader: new THREE.GLTFLoader(this.manager),
            map: function (object) { return ({
                group: object.scene,
                animations: object.animations
            }); }
        };
    };
    ModelService.prototype.loadFBX = function () {
        return {
            loader: new THREE.FBXLoader(),
            map: function (object) { return ({
                group: object,
                animations: object.animations
            }); }
        };
    };
    ModelService.prototype.loadCollada = function () {
        return {
            loader: new THREE.ColladaLoader(),
            map: function (object) { return ({
                group: object.scene,
                animations: object.animations
            }); }
        };
    };
    ModelService.prototype.executeLoader = function (loaderInfo, url) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.progress.progress = 0.1;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        loaderInfo.loader.load(url, function (object) {
                            _this.contents = loaderInfo.map(object);
                            _this.getCameras();
                            _this.getMaterials();
                            _this.animations = _this.contents.animations;
                            _this.progress.progress = 1.0;
                            resolve(_this.contents);
                        }, function (xhr) {
                            // console.log(`loaded: ${xhr.loaded}`);
                            // console.log(
                            //   `Progress: ${Math.min(1, xhr.loaded / 12000000) * 100}% loaded`
                            // );
                            // this.progress.progress = Math.min(1, xhr.loaded / this.loadSize);
                        }, function (error) {
                            reject(error);
                        });
                    })];
            });
        });
    };
    ModelService.prototype.getCameras = function () {
        var _this = this;
        var cameras = [];
        this.contents.group.traverse(function (child) {
            if (child.name.startsWith('Camera') &&
                child.name.indexOf('_target') === -1) {
                cameras.push(child);
            }
        });
        this.pointsOfView = cameras.map(function (camera) { return ({
            name: camera.name,
            position: camera,
            lookAt: _this.contents.group.getObjectByName(camera.name + '_target')
        }); });
        this.pointsOfView = this.pointsOfView.filter(function (pov) { return pov.lookAt && pov.position && pov.name; });
        this.pointsOfView.forEach(function (pov) {
            pov.position.visible = false;
            pov.lookAt.visible = false;
        });
    };
    ModelService.prototype.getMaterials = function () {
        var _this = this;
        var materialsGroup = this.contents.group.getObjectByName('Materials');
        if (materialsGroup === undefined) {
            materialsGroup = this.contents.group.getObjectByName('materials');
        }
        this.materials = [];
        if (materialsGroup) {
            materialsGroup.visible = false;
            materialsGroup.traverse(function (child) {
                if ('material' in child) {
                    var material = child.material;
                    try {
                        material.name = material.name ? material.name : child.name;
                        material.name = material.name
                            ? material.name
                            : child.parent.name;
                    }
                    catch (e) {
                        console.error(e);
                        console.warn('No name for material', material);
                    }
                    _this.materials.push(material);
                    // Sometimes materials don't have names. Use the parents name then.
                }
            });
        }
    };
    ModelService.ngInjectableDef = i0.defineInjectable({ factory: function ModelService_Factory() { return new ModelService(i0.inject(i1.ThreeService), i0.inject(i2.ProgressService)); }, token: ModelService, providedIn: "root" });
    return ModelService;
}());
export { ModelService };
