<div class="header">
  <div class="content">
    <div class="flex-col">
      <div class="logo-container">
        <img class="image-full mt-2 mb-2" height="90px" src="{{'LOGO_URL' | translate}}">
        <img class="image-mobile mt-2 mb-2 ml-2" height="50px" src="assets/shop/logo/logo.alber.mobile.svg">

      </div>
      <span class="flex-grower"></span>
      <ng-container *ngIf="notInIframe">
      <div class="flex-item mr-4 image-full button-fullscreen clickable" (click)="threeService.fullscreen()">
        <img height="40px" class="mr-2" src="assets/shop/icon/functions/fullscreen.svg">{{'Fullscreen' | translate}}</div>
      <button class="flex-item button button-alt mr-2 image-full" (click)="window.location.href = getLinkBack()">
        <ng-container *ngIf="redirectLabel == null">
        {{'Back to Alber' | translate}}
        </ng-container>
        <ng-container *ngIf="redirectLabel != null">
        {{redirectLabel}}
        </ng-container>

      </button>
    </ng-container>
      <app-dropdown class="flex-item button button-language image-full" [title]="configService.language?.shortLabel">
        <div *ngFor="let language of configService.availableLanguages | isActiveLanguage" [class.active]="configService.language === language"
          class="drop-item" (click)="configService.language = language">{{language.label}}</div>
      </app-dropdown>
      <div class="flex-item image-mobile burger-menu clickable" (click)="burgerMenu = !burgerMenu">{{'Menu' |
        translate}}<img height="16px" class="mr-2 ml-2" src="assets/shop/button-burger.png"></div>
    </div>
    <div class="image-mobile" *ngIf="burgerMenu">
      <ng-container *ngIf="notInIframe">
      <div class="burger-item clickable" (click)="window.location.href = getLinkBack()">
        <ng-container *ngIf="redirectLabel == null">
          {{'Back to Alber' | translate}}
          </ng-container>
          <ng-container *ngIf="redirectLabel != null">
          {{redirectLabel}}
          </ng-container>
      </div>
      <div *ngFor="let language of configService.availableLanguages | isActiveLanguage" [class.active]="configService.language === language"
        class="burger-item clickable" (click)="configService.language = language; burgerMenu = false;"><img class="ml-2 mr-2 mb-1"
          height="15" src="assets/shop/icon-world.svg">{{language.label}}</div>
        </ng-container>
    </div>
  </div>
</div>

<app-active-equipment class="large-show"></app-active-equipment>
<app-viewer></app-viewer>

<app-control-buttons class="large-show"></app-control-buttons>

<app-selector></app-selector>
