<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Animation
    </div>
    <ul *ngIf="showBody" class="list-group list-group-flush">

      <li class="list-group-item">
        <form>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="animationDependecies">Animation Dependencies</label>
              <select multiple [(ngModel)]="product.animation.dependencies" class="form-control" name="animationDependecies">
                <option *ngFor="let item of product.items" [ngValue]="item.trivialName">
                  {{item.trivialName}}
                </option>
              </select>
            </div>
            <div class="form-group col-3">
              <label for="startState">Start State</label>
              <select [(ngModel)]="product.animation.startState" class="form-control" name="startState">
                <option [ngValue]="'begin'">Begin</option>
                <option [ngValue]="'end'">End</option>
              </select>
            </div>
            <div class="form-group col-3">
              <label for="timeScale">Time Scale</label>
              <input type="number" [(ngModel)]="product.animation.timeScale" class="form-control" name="timeScale">
            </div>
          </div>
        </form>
      </li>
    </ul>
  </div>
