var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationStatic } from './configuration-static';
import { BehaviorSubject, Subject } from 'rxjs';
import { BackendService } from './backend.service';
import { ModelService } from './model.service';
import { ThreeService } from './three.service';
import * as _ from 'lodash';
import { GoogleAnalyticsService } from './google-analytics.service';
import { Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./backend.service";
import * as i3 from "./model.service";
import * as i4 from "./three.service";
import * as i5 from "./google-analytics.service";
import * as i6 from "@angular/platform-browser";
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(translate, backend, modelService, threeService, ga, titleService) {
        this.translate = translate;
        this.backend = backend;
        this.modelService = modelService;
        this.threeService = threeService;
        this.ga = ga;
        this.titleService = titleService;
        this.isReady = false;
        this.configurationInUse$ = new BehaviorSubject(false);
        this.configurationChanged$ = new Subject();
        this.configurationVersion = 0;
        this.shoppingCart = [];
        this.visibilityChanges = {};
        this.skipThree = false;
        translate.setDefaultLang('de');
    }
    ConfigurationService.prototype.load = function (identifier, skipThree) {
        if (skipThree === void 0) { skipThree = false; }
        return __awaiter(this, void 0, void 0, function () {
            var res, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.skipThree = skipThree;
                        this.isReady = false;
                        if (!identifier) return [3 /*break*/, 4];
                        this.productId = identifier;
                        return [4 /*yield*/, this.backend.productService.find({
                                query: { url: identifier }
                            })];
                    case 1:
                        res = _a.sent();
                        if (!(res.total > 0)) return [3 /*break*/, 3];
                        payload = res.data[0];
                        this.product = payload;
                        this.assumeLanguage();
                        return [4 /*yield*/, this.processProduct()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3: throw new Error("Product \"" + identifier + "\" not found.");
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationService.prototype._dev_load = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isReady = false;
                        _a = this;
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                var answer = Object.assign({}, ConfigurationStatic.content, {
                                    modelUrl: url
                                });
                                setTimeout(function () { return resolve(answer); }, 200);
                            })];
                    case 1:
                        _a.product = _b.sent();
                        this.assumeLanguage();
                        return [4 /*yield*/, this.processProduct()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationService.prototype.processProduct = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Object.assign(this, this.product);
                        if (this.perspectives === undefined || this.perspectives.length === 0) {
                            throw new Error('No camera available');
                        }
                        this.mergeLanguageInformation();
                        if (this.skipThree) {
                            this.isReady = true;
                            return [2 /*return*/];
                        }
                        if (this.threeService.renderer) {
                            this.threeService.renderer.toneMappingExposure = this.appearance.toneMappingExposure;
                        }
                        this.threeService.envMapIntensity = this.appearance.envMapIntensity;
                        this.threeService.envMapIntensity_night = this.appearance.envMapIntensity_night;
                        this.threeService.backgroundColorCSS = this.appearance.viewerBackground;
                        this.threeService.backgroundColorCSS_night = this.appearance.viewerBackground_night;
                        this.threeService.setNightMode(false);
                        this.threeService.envmap = this.threeService.envmaps.find(function (envmap) { return envmap.name === _this.appearance.envMapName; });
                        this.threeService.screenshotUnwantedObjectNames = this.items
                            .filter(function (item) { return item.invisibleForScreenshot; })
                            .map(function (item) { return item.objectName; });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.loadModel()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        throw new Error(e_1);
                    case 4:
                        this.initialPerspective = this.perspectives.find(function (camera) { return camera.name === _this.product.initialPerspective; });
                        this.modelIds = [];
                        if (this.threeService.scene) {
                            this.threeService.scene.traverse(function (child) {
                                _this.modelIds.push(child.name);
                            });
                        }
                        this.resetConfiguration();
                        this.perspectives.forEach(function (camera) {
                            camera.pointOfView = _this.pointsOfView.find(function (pov) { return pov.name === camera.cameraReference; });
                        });
                        if (this.screenshotCamera) {
                            this.screenshotCamera.pointOfView = this.pointsOfView.find(function (pov) { return pov.name === _this.screenshotCamera.cameraReference; });
                        }
                        this.threeService.screenshotCamera = this.screenshotCamera;
                        this.isReady = true;
                        this.configurationChanged$.next();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationService.prototype.resetConfiguration = function () {
        var _this = this;
        if (this.items) {
            this.items.forEach(function (item) {
                _this.changeVisibility(item, item.initialVisibility);
                _this.changeMaterialVariant(item);
                if (item.materialStandardVariant === undefined) {
                    item.materialStandardVariant = item.currentVariant;
                }
                if (item.materialStandardVariant) {
                    item.currentVariant = item.materialStandardVariant;
                    _this.changeMaterialVariant(item);
                }
            });
        }
    };
    ConfigurationService.prototype.mergeLanguageInformation = function () {
        var _this = this;
        var langCode = this._language.code;
        if (this.items) {
            this.items.forEach(function (thisItem) {
                if ('countries' in thisItem &&
                    !(_this._language.code in thisItem.countries)) {
                    throw new Error("Incomplete product information for language " + _this._language.label + " (" + _this._language.code + "), item " + thisItem.productId + "/" + thisItem.trivialName);
                }
                if (thisItem.materialVariants) {
                    thisItem.materialVariants.forEach(function (materialVariant) {
                        if (materialVariant.countries &&
                            langCode in materialVariant.countries) {
                            materialVariant.information = materialVariant.countries[langCode];
                        }
                        else {
                            throw new Error("Incomplete material configuration: " + thisItem.objectName + " has no language information (" + langCode + ") for variant " + materialVariant.materialName);
                        }
                    });
                }
                thisItem.information = thisItem.countries[langCode];
            });
            this.updateShoppingCart();
        }
    };
    ConfigurationService.prototype.getPrice = function (item) {
        var variantPrice = item.currentVariant
            ? item.currentVariant.information.price
            : 0;
        return item.information.price + variantPrice;
    };
    ConfigurationService.prototype.loadModel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, this.modelService.load(this.modelUrl, 12000000)];
                    case 1:
                        _a.sent();
                        this.animations = this.modelService.animations;
                        this.pointsOfView = this.modelService.pointsOfView;
                        this.materials = this.modelService.materials;
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        throw e_2;
                    case 3:
                        setTimeout(function () {
                            _this.threeService.resize();
                        }, 500);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationService.prototype.assumeLanguage = function () {
        var navigator = window.navigator;
        var languageCode = navigator.userLanguage || navigator.language;
        if (this.languageHint) {
            languageCode = this.languageHint;
        }
        if (this.product.availableLanguages === undefined) {
            return;
        }
        var lang = this.product.availableLanguages.find(function (language) { return language.code.toLowerCase() === languageCode.toLowerCase(); });
        if (lang === undefined) {
            // Remove trailing de-DE, en-US
            languageCode = languageCode.split('-')[0];
            lang = this.product.availableLanguages.find(function (language) { return language.code.toLowerCase() === languageCode.toLowerCase(); });
        }
        if (lang) {
            this.language = lang;
        }
        else {
            this.language = this.product.availableLanguages[0];
        }
    };
    ConfigurationService.prototype.removeAllCartItems = function () {
        this.configurationVersion++;
        this.shoppingCart = [];
        this.updateShoppingCart();
    };
    ConfigurationService.prototype.isInShoppingCart = function (item) {
        return (this.shoppingCart.find(function (cartItem) { return cartItem.modelItem === item; }) !==
            undefined);
    };
    ConfigurationService.prototype.updateAmountForItem = function (modelItem, amount) {
        this.configurationVersion++;
        var cartItem = this.shoppingCart.find(function (thisCartItem) { return thisCartItem.modelItem === modelItem; });
        if (cartItem && amount === undefined) {
            return;
        }
        if (cartItem) {
            cartItem.amount = amount;
        }
        else {
            if (amount === undefined) {
                amount = 1;
            }
            this.shoppingCart.push({
                modelItem: modelItem,
                amount: amount
            });
        }
        this.shoppingCart = this.shoppingCart.filter(function (thisItem) { return thisItem.amount > 0; });
        this.updateShoppingCart();
    };
    ConfigurationService.prototype.clearShoppingCart = function () {
        this.shoppingCart = [];
        this.updateShoppingCart();
    };
    ConfigurationService.prototype.amountForItem = function (modelItem) {
        var cartItem = this.shoppingCart.find(function (thisCartItem) { return thisCartItem.modelItem === modelItem; });
        return cartItem ? cartItem.amount : 0;
    };
    ConfigurationService.prototype.updateShoppingCart = function () {
        var _this = this;
        this.shoppingCartSum = this.shoppingCart.reduce(function (prev, curr) {
            return prev + _this.getPrice(curr.modelItem) * curr.amount;
        }, 0);
        this.shoppingCartTax = this.shoppingCartSum * (this.language.vat / 100);
        // Check if all product dependencies are met
        this.shoppingCart.forEach(function (cartItem) {
            cartItem.unmetDependencies = [];
            var modelItem = cartItem.modelItem;
            if (modelItem.productDependencies) {
                modelItem.productDependencies.forEach(function (dependency) {
                    var dependencyModelItem = _this.items.find(function (thisItem) { return thisItem.productId === dependency; });
                    if (_this.shoppingCart.find(function (thisCartItem) { return thisCartItem.modelItem === dependencyModelItem; }) === undefined) {
                        cartItem.unmetDependencies.push(dependencyModelItem);
                    }
                });
            }
        });
    };
    Object.defineProperty(ConfigurationService.prototype, "language", {
        get: function () {
            return this._language;
        },
        set: function (language) {
            var _this = this;
            if (this._language !== language &&
                this.product.availableLanguages.includes(language)) {
                this._language = language;
                this.configurationVersion++;
                this.translate.use(this._language.code);
                this.mergeLanguageInformation();
                // Set title
                this.translate.get('PAGE_TITLE').subscribe(function (title) {
                    _this.titleService.setTitle(title);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationService.prototype.changeMaterialVariant = function (item) {
        var _this = this;
        if (this.skipThree) {
            return;
        }
        this.configurationVersion++;
        if (item.materialVariants === undefined) {
            return;
        }
        if (item.materialVariantsTarget === undefined) {
            item.materialVariantsTarget = [];
        }
        if (!Array.isArray(item.materialVariantsTarget)) {
            item.materialVariantsTarget = [item.materialVariantsTarget];
        }
        var targets = item.materialVariantsTarget
            .map(function (target) { return _this.threeService.scene.getObjectByName(target); })
            .filter(function (target) { return target !== undefined; });
        // If item has variants but no current variant assigned, try if the current material is in the variants
        if (item.currentVariant === undefined &&
            targets.length > 0 &&
            item.materialVariants) {
            var currentMaterial_1 = this.materials.find(function (thisMaterial) { return thisMaterial.name === _.get(targets[0], 'material.name'); });
            if (currentMaterial_1) {
                item.currentVariant = item.materialVariants.find(function (variant) { return variant.materialName === currentMaterial_1.name; });
            }
        }
        // If there is still no current variant set, just leave this object alone
        if (item.currentVariant === undefined) {
            return;
        }
        // if (this.materials === undefined) {
        //   console.assert(this.materials, 'oh no, no materials!!11');
        // }
        var material = this.materials.find(function (thisMaterial) { return thisMaterial.name === item.currentVariant.materialName; });
        if (targets && material) {
            targets.forEach(function (target) { return (target.material = material); });
        }
        this.requestUpdate();
        this.updateShoppingCart();
    };
    ConfigurationService.prototype.changeVisibility = function (item, visible, depth) {
        var _this = this;
        if (depth === void 0) { depth = 0; }
        if (this.skipThree) {
            return;
        }
        this.configurationVersion++;
        item.visible = visible;
        if (item.materialStandardVariant) {
            item.currentVariant = item.materialStandardVariant;
        }
        if (visible) {
            this.changeMaterialVariant(item);
        }
        if (depth > 10) {
            return;
        }
        if (visible) {
            // Make dependencies visible
            item.viewDependencies
                .map(function (dep) { return _this.items.find(function (thisItem) { return thisItem.productId === dep; }); })
                .filter(function (dep) { return dep !== undefined; })
                .filter(function (dep) { return dep.visible === false; })
                .forEach(function (dep) { return _this.changeVisibility(dep, true, depth + 1); });
            // Make conflicts invisible
            item.viewCollisions
                .map(function (dep) { return _this.items.find(function (thisItem) { return thisItem.productId === dep; }); })
                .filter(function (dep) { return dep !== undefined; })
                .filter(function (dep) { return dep.visible; })
                .forEach(function (dep) { return _this.changeVisibility(dep, false, depth + 1); });
        }
        else {
            this.items
                .filter(function (thisItem) { return thisItem.viewDependencies.includes(item.productId); })
                .forEach(function (thisItem) { return _this.changeVisibility(thisItem, false, depth + 1); });
            if (item.mandatoryAlternatives && depth === 0) {
                item.mandatoryAlternatives
                    .map(function (alternative) {
                    return _this.items.find(function (thisItem) { return thisItem.productId === alternative; });
                })
                    .filter(function (dep) { return dep !== undefined; })
                    .forEach(function (alternative) {
                    return _this.changeVisibility(alternative, true, depth + 1);
                });
            }
        }
        if (depth === 0) {
            this.requestUpdate();
        }
    };
    ConfigurationService.prototype.clearStagedVisibilityChanges = function () {
        this.visibilityChanges = {};
    };
    ConfigurationService.prototype.stageVisibilityChange = function (item, visibility) {
        if (item.objectName === '') {
            return;
        }
        if (!this.visibilityChanges.hasOwnProperty(item.objectName)) {
            this.visibilityChanges[item.objectName] = [];
        }
        this.visibilityChanges[item.objectName].push(visibility);
    };
    ConfigurationService.prototype.commitStagedVisibilityChanges = function () {
        for (var objectName in this.visibilityChanges) {
            if (this.visibilityChanges.hasOwnProperty(objectName)) {
                var object = this.threeService.scene.getObjectByName(objectName);
                if (object) {
                    object.visible = this.visibilityChanges[objectName].some(function (d) { return d; });
                }
            }
        }
    };
    ConfigurationService.prototype.requestUpdate = function () {
        var _this = this;
        this.clearStagedVisibilityChanges();
        this.items.forEach(function (item) {
            _this.stageVisibilityChange(item, item.visible);
        });
        this.commitStagedVisibilityChanges();
        this.configurationChanged$.next();
        if (this.configurationInUse$.getValue() === false) {
            this.configurationInUse$.next(true);
            setTimeout(function () {
                _this.configurationInUse$.next(false);
            }, 1000);
        }
    };
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.inject(i1.TranslateService), i0.inject(i2.BackendService), i0.inject(i3.ModelService), i0.inject(i4.ThreeService), i0.inject(i5.GoogleAnalyticsService), i0.inject(i6.Title)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
