import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Product, ConfigurationService } from '../../configuration.service';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ThreeService } from '../../three.service';

@Component({
  selector: 'app-product-appearance',
  templateUrl: './product-appearance.component.html',
  styleUrls: ['./product-appearance.component.css']
})
export class ProductAppearanceComponent implements OnInit, OnChanges {
  @Input() public showBody = false;
  @Input() public product: Product;

  faTrashAlt = faTrashAlt;

  constructor(
    public configService: ConfigurationService,
    public threeService: ThreeService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.product && this.product.appearance === undefined) {
      this.product.appearance = {
        envMapIntensity: 1.0,
        envMapIntensity_night: 0.1,
        envMapName: 'Bridge2',
        toneMappingExposure: 1.2,
        viewerBackground: 'white',
        viewerBackground_night: 'darkgrey'
      };
    }
  }

  create() {
    if (this.product.perspectives === undefined) {
      this.product.perspectives = [];
    }
    this.product.perspectives.push({
      name: 'Camera',
      cameraReference: 'Null',
      duration: 1200,
      lowerDistance: 100,
      upperDistance: 300,
      lockPosition: false,
      lockTarget: false,
      animation: true
    });
  }

  remove(index: number) {
    this.product.perspectives.splice(index, 1);
  }
}
