<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Material Variants
    </div>
    <ul *ngIf="showBody" class="list-group list-group-flush">
      <li class="list-group-item">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-primary" id="dropdownItem" ngbDropdownToggle>
            <span *ngIf="currentItem">{{currentItem.trivialName}}</span>
            <span *ngIf="currentItem === undefined">Select Item</span>
          </button>
          <div ngbDropdownMenu>
            <button *ngFor="let item of product.items" class="dropdown-item" (click)="changeCurrentItem(item)">{{item.trivialName}}</button>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block ml-2">
          <button class="btn btn-outline-primary" id="dropdownLanguage" ngbDropdownToggle>
            <span *ngIf="currentLanguage">{{currentLanguage.label}}</span>
            <span *ngIf="currentLanguage === undefined">Select Language</span>
          </button>
          <div ngbDropdownMenu>
            <button *ngFor="let language of product.availableLanguages" class="dropdown-item" (click)="changeCurrentLanguage(language)">{{language.label}}</button>
          </div>
        </div>
      </li>
      <li class="list-group-item" *ngIf="currentItem">
        <select multiple [(ngModel)]="currentItem.materialVariants" (ngModelChange)="updateCurrentItem()" class="form-control">
          <option *ngFor="let materialVariant of materialVariants" [ngValue]="materialVariant">{{materialVariant.materialName}}</option>
        </select>
      </li>

      <!-- Edit table of item -->
      <li class="list-group-item" *ngIf="currentItem">
        <form>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="targets">Targets</label>
              <app-chips id="targets" name="targets" [list]="currentItem.materialVariantsTarget" type="text" class="form-control" style="overflow-x: hidden">
              </app-chips>
            </div>
            <div class="form-group col-6">
              <label for="materialVariantsTarget">Add target</label>
              <div class="input-group mb-2">
                <input id="materialVariantsTarget" name="materialVariantsTarget" [(ngModel)]="nextTarget" type="text" class="form-control"
                  [ngbTypeahead]="search" />
                <div class="input-group-append clickable" (click)="addMaterialVariant()">
                  <fa-icon [icon]="faPlusSquare" class="input-group-text"></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </form>
      </li>

      <li class="list-group-item" *ngIf="currentItem">
        <div class="form-row" *ngFor="let materialVariant of currentItem.materialVariants">
          <div class="form-group col-6">
            <label>Image for <em>{{materialVariant.materialName}}</em></label>
            <app-image-drop [(imageUrl)]="materialVariant.imageUrl"></app-image-drop>
          </div>
          <div class="form-group col-6">
            <label>Product ID</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="materialVariant.productId">
          </div>
        </div>
      </li>

      <!-- Edit table of item x language -->
      <li class="list-group-item" *ngIf="currentItem && currentLanguage">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Identifier</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let variant of currentItem.materialVariants; let i = index">
              <td>
                {{variant.materialName}}
              </td>
              <td>
                <input type="text" class="form-control" name="name" [(ngModel)]="variant.countries[currentLanguage.code].name">
              </td>
              <td>
                <input type="text" class="form-control" name="description" [(ngModel)]="variant.countries[currentLanguage.code].description">
              </td>
              <td>
                <input type="number" class="form-control" name="price" [(ngModel)]="variant.countries[currentLanguage.code].price">
              </td>
            </tr>
          </tbody>
        </table>
      </li>

    </ul>
  </div>
