import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfigurationService } from '../../configuration.service';
import { Product } from '../../configuration.service';
import { BackendService } from '../../backend.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  @Input() public product: Product;
  @Output() public requestDelete = new EventEmitter<Product>();
  @Output() public modelUrlChanged = new EventEmitter<string>();

  @Input() public showBody = false;

  @ViewChild('content') content;

  public faCheck = faCheck;
  public faTimes = faTimes;
  public acceptFile = false;

  constructor(
    private modalService: NgbModal,
    public configurationService: ConfigurationService,
    public backendService: BackendService
  ) {}

  remove() {
    this.modalService
      .open(this.content)
      .result.then(() => {
        this.requestDelete.next(this.product);
      })
      .catch(e => {
        // consume, not interesting
      });
  }

  ngOnInit() {}

  eventIsAccepted(event) {
    if (event.dataTransfer) {
      if (event.dataTransfer.items.length === 0) {
        return false;
      }
      let types = [];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        types.push(event.dataTransfer.items[i].type);
      }
      return true; // types.every(type => type === 'image/png' || type === 'image/jpeg');
    } else {
      return false;
    }
  }

  async drop_handler(event: any) {
    event.preventDefault();
    if (!this.eventIsAccepted(event)) {
      return;
    }
    let filenames = await this.backendService.uploadFiles(
      event.dataTransfer.files,
      this.product.url,
      'model'
    );
    let modelFile = filenames.find(
      filename =>
        filename.endsWith('.gltf') ||
        filename.endsWith('.fbx') ||
        filename.endsWith('.gltb') ||
        filename.endsWith('.dae')
    );

    if (modelFile) {
      this.product.modelUrl = modelFile;
      this.modelUrlChanged.next(modelFile);
    }
    this.acceptFile = false;

  }

  dragover_handler(e) {
    this.acceptFile = this.eventIsAccepted(e);
    event.preventDefault();
  }

  dragleave_handler(e) {
    this.acceptFile = false;
  }
}
