export const environment = {
  production: true,
  apiUrl: undefined,
  socketio: {
    url: 'https://e-pilot.alber.de',
    options: {
      secure: true,
      reconnect: true
    }

  }
};
