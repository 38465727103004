/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-drop.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-drop.component";
import * as i4 from "../../backend.service";
import * as i5 from "../../configuration.service";
var styles_ImageDropComponent = [i0.styles];
var RenderType_ImageDropComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageDropComponent, data: {} });
export { RenderType_ImageDropComponent as RenderType_ImageDropComponent };
function View_ImageDropComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["id", "productImage"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageDropComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageDropComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["Drop Image\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageDropComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-drop", [], [[2, "form-control", null], [2, "active", null]], [[null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dropHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).dragOverHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onDragLeave($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_ImageDropComponent_0, RenderType_ImageDropComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageDropComponent, [i4.BackendService, i5.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isFormGroup; var currVal_1 = i1.ɵnov(_v, 1).isActive; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ImageDropComponentNgFactory = i1.ɵccf("app-image-drop", i3.ImageDropComponent, View_ImageDropComponent_Host_0, { imageUrl: "imageUrl", context: "context" }, { imageUrlChange: "imageUrlChange" }, []);
export { ImageDropComponentNgFactory as ImageDropComponentNgFactory };
