import { OnInit } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { ThreeService } from '../three.service';
import { AnimationService } from '../animation.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
var ShopComponent = /** @class */ (function () {
    function ShopComponent(configService, threeService, animationService, translate, route) {
        var _this = this;
        this.configService = configService;
        this.threeService = threeService;
        this.animationService = animationService;
        this.translate = translate;
        this.route = route;
        this.window = window;
        this.burgerMenu = false;
        this.notInIframe = true;
        route.params.subscribe(function (p) {
            console.log('params', p);
            if (p.hasOwnProperty('lang')) {
                _this.configService.languageHint = p.lang;
            }
        });
        route.queryParams.pipe(take(1)).subscribe(function (p) {
            if (p.hasOwnProperty("redirectURL") && p.hasOwnProperty("redirectLabel")) {
                _this.redirectLabel = decodeURIComponent(p.redirectLabel);
                _this.redirectURL = decodeURIComponent(p.redirectURL);
            }
        });
        this.notInIframe = this.window == this.window.top;
    }
    ShopComponent.prototype.ngOnInit = function () { };
    ShopComponent.prototype.getLinkBack = function () {
        if (this.redirectURL != null) {
            return this.redirectURL;
        }
        return this.translate.instant('BACK LINK');
    };
    return ShopComponent;
}());
export { ShopComponent };
