import { Injectable } from '@angular/core';
import {
  Perspective,
  ConfigurationService,
  PointOfView
} from './configuration.service';
import { ThreeService } from './three.service';
import { Vector3, Object3D } from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import {
  BehaviorSubject,
  timer,
  combineLatest,
  Observable,
  Subscription
} from 'rxjs';
import { map, distinctUntilChanged, debounce } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';

export interface StartStoppableRenderer {
  setRunning(boolean);
}

@Injectable({
  providedIn: 'root'
})
export class AnimationService {
  public currentPerspective: Perspective;
  public _animationRunning = false;
  private mixer: THREE.AnimationMixer;
  private clock: THREE.Clock;
  private time = 0;
  public animations: THREE.AnimationClip[];

  public animationDuration$ = new BehaviorSubject<number>(1);
  public animationPosition$ = new BehaviorSubject<number>(0);
  private animationAction: THREE.AnimationAction;

  private animationInUse$ = new BehaviorSubject<boolean>(false);
  private cameraMoveInUse$ = new BehaviorSubject<boolean>(false);

  private animationSources = [] as Observable<boolean>[];
  private animationSourceSubscription: Subscription;

  public currentState: 'begin' | 'end';
  private duration: number;

  constructor(
    private configurationService: ConfigurationService,
    private threeService: ThreeService,
    private ga: GoogleAnalyticsService
  ) {
    this.clock = new THREE.Clock();
    this.threeService.subscribe(event => {
      if (event.type === 'update') {
        this.update();
      }
    });
  }

  public registerAnimationSource(observable: Observable<boolean>) {
    this.animationSources.push(observable);

    if (this.animationSourceSubscription) {
      this.animationSourceSubscription.unsubscribe();
    }

    this.animationSourceSubscription = combineLatest(this.animationSources)
      .pipe(
        map(v => {
          return v.some(a => a);
        }),
        distinctUntilChanged(),
        debounce(v => (v ? timer(0) : timer(1000)))
      )
      .subscribe(v => {
        this.threeService.setRunning(v);
      });
  }

  init() {
    this.prepareAnimations();

    this.currentState = this.configurationService.animation.startState;
    if (this.currentState === 'end') {
      // this.mixer.time = this.duration;
      this.configurationService.animations.map(animation => this.mixer.clipAction(animation)).forEach(action => {
        action.time = this.duration;
        action.play();
      });
    }

    if (this.configurationService.initialPerspective) {
      if (
        this.configurationService.initialPerspective.pointOfView === undefined
      ) {
        let pos = new THREE.Object3D();
        let lookat = new THREE.Object3D();
        pos.position.set(1, 1, 1);
        lookat.position.set(0, 0, 0);
        this.configurationService.initialPerspective.pointOfView = {
          name: this.configurationService.initialPerspective.cameraReference,
          position: pos,
          lookAt: lookat
        };
        // throw new Error('No initial perspective defined or found'
      }
      this.currentPerspective = this.configurationService
        .initialPerspective as Perspective;
      this.setPointOfView(
        (this.configurationService.initialPerspective as Perspective)
          .pointOfView
      );
      this.threeService.controls.minDistance = this.currentPerspective.lowerDistance;
      this.threeService.controls.maxDistance = this.currentPerspective.upperDistance;
    }
    this.threeService.controls.dampingFactor = 0.1;
    this.threeService.controls.rotateSpeed = 0.1;
    this.threeService.controls.enableDamping = true;

    let zooming$ = new BehaviorSubject<boolean>(false);

    let zoomTimer;
    this.threeService.controls.addEventListener('change', () => {
      zooming$.next(true);
      if (zoomTimer) {
        clearTimeout(zoomTimer);
      }
      zoomTimer = setTimeout(() => {
        zooming$.next(false);
      }, 500);
    });

    this.threeService.nightModeShift$.subscribe(mode => {
      console.log(`night shift mode ${mode}`);
    });

    [
      this.configurationService.configurationInUse$,
      this.animationInUse$,
      this.cameraMoveInUse$,
      this.threeService.nightModeShift$,
      zooming$
    ].forEach(animationSource => this.registerAnimationSource(animationSource));

    this.threeService.setRunning(true);
    this.threeService.setRunning(false);
  }

  set animationRunning(value: boolean) {
    this._animationRunning = value;
    this.animationInUse$.next(value);
  }

  get animationRunning() {
    return this._animationRunning;
  }

  setPointOfView(camera: PointOfView) {
    this.threeService.scene.updateMatrixWorld(true);
    let camLookAt = camera.lookAt.getWorldPosition(new Vector3());
    let camPosition = camera.position.getWorldPosition(new Vector3());
    this.threeService.camera.position.set(
      camPosition.x,
      camPosition.y,
      camPosition.z
    );
    this.threeService.controls.target.set(
      camLookAt.x,
      camLookAt.y,
      camLookAt.z
    );
    // this.threeService.controls.update();
  }

  update() {
    TWEEN.update();
    if (this.animationRunning) {
      this.checkCameraLock();
    }
    let delta = this.clock.getDelta();
    if (this.mixer) {
      this.time += delta;
      this.mixer.time = this.time;
      this.mixer.update(delta);
      if (this.animationAction) {
        this.animationPosition$.next(this.animationAction.time);
      }
    }
    if (this.threeService.controls.enableDamping) {
      this.threeService.controls.update();
    }
  }

  checkCameraLock() {
    if (this.currentPerspective) {
      if (this.currentPerspective.lockTarget) {
        let target = new Vector3();
        this.currentPerspective.pointOfView.lookAt.getWorldPosition(target);
        this.threeService.controls.target = target;
      }
      if (this.currentPerspective.lockPosition) {
        let target = new Vector3();
        this.currentPerspective.pointOfView.position.getWorldPosition(target);
        Object.assign(this.threeService.camera.position, target);
      }
      // this.threeService.controls.update();
    }
  }

  async changePerspective(
    perspective: Perspective,
    changePosition = true,
    changeTarget = true
  ) {
    this.currentPerspective = perspective;

    let promises = [];
    if (
      changePosition &&
      this.globalDistance(
        this.threeService.camera,
        perspective.pointOfView.position
      ) >= 0.01
    ) {
      promises.push(
        this.getTween(
          this.threeService.camera.position,
          perspective.pointOfView.position.getWorldPosition(new Vector3()),
          perspective.duration
        )
      );
    }
    if (
      changeTarget &&
      this.globalDistance(
        this.threeService.controls.target,
        perspective.pointOfView.lookAt
      ) >= 0.01
    ) {
      promises.push(
        this.getTween(
          this.threeService.controls.target,
          perspective.pointOfView.lookAt.getWorldPosition(new Vector3()),
          perspective.duration
        )
      );
    }
    this.cameraMoveInUse$.next(true);
    await Promise.all(promises);
    this.cameraMoveInUse$.next(false);
  }

  private getTween(object, to, duration) {
    return new Promise((resolve, reject) => {
      let tween = new TWEEN.Tween(object).to(to, duration);
      this.threeService.controls.enableDamping = false;
      tween.easing(TWEEN.Easing.Quartic.InOut);
      tween.onUpdate(a => {
        // this.threeService.controls.update();
      });
      tween.onStart(() => {
        this.threeService.controls.enableDamping = false;
        this.threeService.controls.enabled = false;
      });
      tween.onComplete(() => {
        this.threeService.controls.enableDamping = true;
        this.threeService.controls.enabled = true;
        resolve();
      });
      tween.start();
    });
  }

  async prepareCameraForAnimation() {
    if (this.currentPerspective) {
      await this.changePerspective(
        this.currentPerspective,
        this.currentPerspective.lockPosition,
        this.currentPerspective.lockTarget
      );
    }
  }

  async togglePlay() {

    this.ga.sendEvent('Play Animation', 'Page functions', this.currentState === 'begin' ? 'Forward' : 'Backward');

    this.clock.getDelta();
    await this.prepareCameraForAnimation();
    this.prepareModelForAnimation();
    this.animationRunning = true;

    if (this.currentState === 'begin') {
      this.mixer.timeScale = this.configurationService.animation.timeScale;
      this.currentState = 'end';
    } else {
      this.mixer.timeScale = -this.configurationService.animation.timeScale;
      this.currentState = 'begin';
    }

    this.animations
      .map(animation => this.mixer.existingAction(animation))
      .forEach(action => {
        action.play();
        action.paused = false;
      });
  }

  prepareModelForAnimation() {
    let dependencies = this.configurationService.items.filter(item =>
      this.configurationService.animation.dependencies.includes(item.objectName)
    );

    dependencies.forEach(dependency => this.configurationService.changeVisibility(dependency, true));
  }

  prepareAnimations() {
    this.mixer = new THREE.AnimationMixer(this.threeService.scene);
    this.mixer.timeScale = this.configurationService.animation.timeScale;
    this.animations = this.configurationService.animations || [];
    this.animations.forEach(animation => {
      let action = this.mixer.clipAction(animation);
      this.duration = animation.duration;
      action.clampWhenFinished = true;
      action.setLoop(THREE.LoopOnce, 1);
    });

    if (this.animations && this.animations.length > 0) {
      this.animationAction = this.mixer.existingAction(this.animations[0]);
    }

    this.mixer.addEventListener('finished', () => {
      this.animationRunning = false;
    });
  }

  private globalDistance(o1: Object3D | Vector3, o2: Object3D | Vector3) {
    let p1, p2;
    if ((o1 as any).isVector3) {
      p1 = o1;
    } else {
      p1 = (o1 as Object3D).getWorldPosition(new Vector3());
    }
    if ((o2 as any).isVector3) {
      p2 = o2;
    } else {
      p2 = (o2 as Object3D).getWorldPosition(new Vector3());
    }
    return p1.distanceTo(p2);
  }
}
