import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Product, Language } from '../../configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-languages',
  templateUrl: './product-languages.component.html',
  styleUrls: ['./product-languages.component.css']
})
export class ProductLanguagesComponent implements OnInit {
  @Input()
  public showBody = false;
  @Input()
  public product: Product;

  @ViewChild('content')
  private content;
  public faTrashAlt = faTrashAlt;

  public currentLanguage: Language;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    if (this.product.availableLanguages === undefined) {
      this.product.availableLanguages = [];
    }
  }

  create() {
    this.product.availableLanguages.push({
      code: 'de',
      label: 'Deutsch',
      shortLabel: 'DE',
      currency: 'EUR',
      showPrice: false,
      showRetailerButton: false,
      vat: 19,
      active: false
    });
  }

  remove(index: number) {
    this.currentLanguage = this.product.availableLanguages[index];
    this.open(this.content)
      .then(() => {
        this.product.availableLanguages.splice(index, 1);
      })
      .catch(() => {
        // nothing
      });
  }

  open(content: ElementRef) {
    return this.modalService.open(content).result;
  }
}
