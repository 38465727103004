import { OnInit, ElementRef } from '@angular/core';
import { AnimationService } from '../../animation.service';
import { ThreeService } from '../../three.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialShareService } from '../../social-share.service';
import { SerializationService } from '../../serialization.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
var ControlButtonsComponent = /** @class */ (function () {
    function ControlButtonsComponent(animationService, threeService, socialShare, serialization, modalService, ga) {
        this.animationService = animationService;
        this.threeService = threeService;
        this.socialShare = socialShare;
        this.serialization = serialization;
        this.modalService = modalService;
        this.ga = ga;
    }
    ControlButtonsComponent.prototype.ngOnInit = function () { };
    ControlButtonsComponent.prototype.showPopover = function (p) {
        this.ga.sendEvent('Share menu opened', 'Page functions', undefined);
        this.currentPopover = p;
    };
    ControlButtonsComponent.prototype.share = function (where) {
        this.socialShare.share(where);
        if (this.currentPopover) {
            this.currentPopover.close();
        }
    };
    ControlButtonsComponent.prototype.openModal = function () {
        this.ga.sendEvent('Open Check', 'Page functions', undefined);
        return this.modalService
            .open(this.checkModal, { windowClass: 'check-wheelchair-modal', size: 'lg' });
    };
    return ControlButtonsComponent;
}());
export { ControlButtonsComponent };
