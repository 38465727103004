import * as i0 from "@angular/core";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService() {
        this.id = 'UA-9330466-7';
        window.gtag('js', new Date());
        this.setPage('viewer/e-pilot');
    }
    GoogleAnalyticsService.prototype.send = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // console.log('Sending gtag: ', ...args);
        (_a = window).gtag.apply(_a, args);
        var _a;
    };
    GoogleAnalyticsService.prototype.setPage = function (page) {
        window.gtag('config', this.id, { 'page_path': page });
    };
    GoogleAnalyticsService.prototype.sendEvent = function (action, category, label, value) {
        window.gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': value
        });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
