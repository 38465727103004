<ng-template #loginContent let-c="close">
  <div class="modal-header">
    <h4 class="modal-title">Login</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="email">Email</label>
        <div class="input-group">
          <input id="email" class="form-control" name="email" type="text" [(ngModel)]="credentials.email">
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="input-group">
          <input id="password" class="form-control" (keyup.enter)="c('login')" name="password" type="password" [(ngModel)]="credentials.password">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('login')">Login</button>
  </div>
</ng-template>

<nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
  <span class="navbar-brand" href="#">Alber Testdrive Administration</span>

  <ul class="navbar-nav mr-auto">
    <li class="nav-item clickable" [class.active]="pane === 'products'">
      <a class="nav-link" (click)="pane='products'">Products</a>
    </li>
    <li class="nav-item clickable" [class.active]="pane === 'files'">
        <a class="nav-link" (click)="pane='files'">Model Files</a>
      </li>
    </ul>
  <button *ngIf="saveState !== SaveStates.Pristine" class="btn btn-primary btn-sm float-right mr-2" (click)="save(currentProduct); $event.stopPropagation()"
    [disabled]="saveState === SaveStates.Saving">
    <span *ngIf="saveState === SaveStates.Saving">
      <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
    </span>
    Save ({{currentProduct.updatedAt | date:'short':'de':'de' }})
  </button>

  <button *ngIf="backend.loggedIn" class="btn btn-sm btn-secondary float-right" (click)="logout()">
    Logout {{backend.email}}
  </button>

</nav>

<div class="container mt-2" *ngIf="pane == 'products'">
  <app-product-select class="row" [products]="products" [(currentProduct)]="currentProduct" (currentProductChange)="productChanged()"
    (requestCreate)="create()">
  </app-product-select>
  <ng-container *ngIf="currentProduct">
    <app-product-detail class="row mt-2" [product]="currentProduct" (requestDelete)="delete($event)" (modelUrlChanged)="updateModelUrl()"></app-product-detail>
    <app-product-items class="row mt-2" [product]="currentProduct"></app-product-items>
    <app-product-category class="row mt-2" [product]="currentProduct"></app-product-category>
    <app-product-item-dependencies class="row mt-2" [product]="currentProduct"></app-product-item-dependencies>
    <app-product-item-detail class="row mt-2" [product]="currentProduct"></app-product-item-detail>
    <app-product-materials class="row mt-2" [product]="currentProduct"></app-product-materials>
    <app-product-languages class="row mt-2" [product]="currentProduct"></app-product-languages>
    <app-product-language-availability class="row mt-2" [product]="currentProduct"></app-product-language-availability>
    <app-product-cameras class="row mt-2" [product]="currentProduct"></app-product-cameras>
    <app-product-appearance class="row mt-2" [product]="currentProduct"></app-product-appearance>
    <app-product-animation class="row mt-2" [product]="currentProduct"></app-product-animation>
    <app-product-coder class="row mt-2" [product]="currentProduct" (productChange)="productChanged($event)"></app-product-coder>
  </ng-container>
</div>
<div #glContainer></div>
