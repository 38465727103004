import { PipeTransform } from '@angular/core';
var IsUserSelectablePipe = /** @class */ (function () {
    function IsUserSelectablePipe() {
    }
    IsUserSelectablePipe.prototype.transform = function (value, args) {
        if (value) {
            return value.filter(function (item) { return item.userSelectable; });
        }
        else {
            return null;
        }
    };
    return IsUserSelectablePipe;
}());
export { IsUserSelectablePipe };
