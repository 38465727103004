/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dropdown.component";
var styles_DropdownComponent = [i0.styles];
var RenderType_DropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_DropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "current-title"]], null, [[null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.dblclick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "content"]], null, [[null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.dblclick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.visible; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_DropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown", [], [[2, "active", null]], [[null, "dblclick"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("dblclick" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dblclick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).click() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_DropdownComponent_0, RenderType_DropdownComponent)), i1.ɵdid(1, 114688, null, 0, i3.DropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).visible; _ck(_v, 0, 0, currVal_0); }); }
var DropdownComponentNgFactory = i1.ɵccf("app-dropdown", i3.DropdownComponent, View_DropdownComponent_Host_0, { title: "title" }, {}, ["*"]);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
