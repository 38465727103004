<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Item Details
    </div>
    <ul *ngIf="showBody" class="list-group list-group-flush">
      <li class="list-group-item">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-primary" id="dropdownItem" ngbDropdownToggle>
            <span *ngIf="currentItem">{{currentItem.trivialName}}</span>
            <span *ngIf="currentItem === undefined">Select Item</span>
          </button>
          <div ngbDropdownMenu>
            <button *ngFor="let item of product.items" class="dropdown-item" (click)="currentItem = item">{{item.productId}} - {{item.trivialName}}</button>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block ml-2">
          <button class="btn btn-outline-primary" id="dropdownLanguage" ngbDropdownToggle>
            <span *ngIf="currentLanguage">{{currentLanguage.label}}</span>
            <span *ngIf="currentLanguage === undefined">Select Language</span>
          </button>
          <div ngbDropdownMenu>
            <button *ngFor="let language of product.availableLanguages" class="dropdown-item" (click)="currentLanguage = language">{{language.label}}</button>
          </div>
        </div>
      </li>
      <li *ngIf="currentItem" class="list-group-item">
        <div class="form-row">
          <div class="form-group col-6">
            <label for="productImage">Item Image</label>
            <app-image-drop id="productImage" [(imageUrl)]="currentItem.imageUrl"></app-image-drop>
          </div>
        </div>
      </li>
      <li *ngIf="showDetail" class="list-group-item">
        <form>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="name">Title</label>
              <input [(ngModel)]="currentItem.countries[currentLanguage.code].title" name="title" type="text" class="form-control" id="title"
                placeholder="Title">
            </div>
            <div class="form-group col-6">
              <label for="shortTitle">Short Title</label>
              <input [(ngModel)]="currentItem.countries[currentLanguage.code].shortTitle" name="shortTitle" type="text" class="form-control" id="shortTitle"
                placeholder="Short Name">
            </div>
            <div class="form-group col-6">
              <label for="name">Price (Brutto)</label>
              <input [(ngModel)]="currentItem.countries[currentLanguage.code].price" name="price" type="number" min="0" step="0.01" class="form-control"
                id="price" placeholder="0.00">
            </div>
          </div>
        </form>
      </li>
      <li *ngIf="showDetail" class="list-group-item">
        <div class="form">
          <div class="form-group">
            <label>Description</label>
            <textarea class="form-control" [(ngModel)]="currentItem.countries[currentLanguage.code].description" rows="5">
            </textarea>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
