/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./toggle-button.component";
var styles_ToggleButtonComponent = [i0.styles];
var RenderType_ToggleButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToggleButtonComponent, data: {} });
export { RenderType_ToggleButtonComponent as RenderType_ToggleButtonComponent };
export function View_ToggleButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "left"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "middle"]], [[8, "src", 4], [2, "flipped", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "right"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.left; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.middle; var currVal_2 = _co.state; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.right; _ck(_v, 2, 0, currVal_3); }); }
export function View_ToggleButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toggle-button", [], null, [[null, "touchstart"], [null, "touchmove"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).touchStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchmove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).touchMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).clicked() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_ToggleButtonComponent_0, RenderType_ToggleButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.ToggleButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToggleButtonComponentNgFactory = i1.ɵccf("app-toggle-button", i2.ToggleButtonComponent, View_ToggleButtonComponent_Host_0, { left: "left", middle: "middle", right: "right", state: "state" }, { stateChanged: "stateChanged" }, []);
export { ToggleButtonComponentNgFactory as ToggleButtonComponentNgFactory };
