import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService, CartItem } from '../../configuration.service';
import { SerializationService } from '../../serialization.service';
import { BackendService } from '../../backend.service';
import { ThreeService } from '../../three.service';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {
  public currentDate = new Date();
  private productId: string;
  private serializationId: string;
  public slices: CartItem[][];

  public mywindow = window as any;

  @ViewChild('screenshot')
  public screenshotImage: ElementRef<HTMLImageElement>;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigurationService,
    private threeService: ThreeService,
    public serializationService: SerializationService,
    private backendService: BackendService
  ) {
    this.threeService.renderingDisabled = true;
  }

  getSlices(): CartItem[][] {
    let source = [...this.configService.shoppingCart];
    let slices = [];
    slices.push([...source.splice(0, 4)]);
    while (source.length > 0) {
      slices.push([...source.splice(0, 4)]);
    }
    return slices;
  }

  ngOnInit() {
    this.mywindow.renderingDone = false;
    this.route.paramMap.subscribe(async paramMap => {
      if (paramMap.has('product') && paramMap.has('uuid')) {
        this.productId = paramMap.get('product');
        this.serializationId = paramMap.get('uuid');
        await this.configService.load(this.productId, true);
        await this.serializationService.deserialize(this.serializationId);
        this.slices = this.getSlices();
      }
    });
  }

  imageLoaded() {
    (window as any).renderingDone = true;
  }

  async print() {
    let pdf = await this.backendService.printService.create({
      productId: this.productId,
      serializationId: this.serializationId
    });
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    const blob = new Blob([pdf.pdf], { type: 'application/pdf' });
    const objectURL = URL.createObjectURL(blob);

    link.href = objectURL;
    link.href = URL.createObjectURL(blob);
    link.download = 'data.pdf';
    link.click();
  }
}
