import {
  Component,
  OnInit,
  AfterContentChecked,
  Input,
  Directive,
  TemplateRef,
  ContentChildren,
  QueryList
} from '@angular/core';

@Directive({ selector: 'ng-template[tabItemContent]' })
export class TabItemContent {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[tabItemTitle]' })
export class TabItemTitle {
  constructor(public templateRef: TemplateRef<any>) {}
}

export class TabItemContext {
  constructor(public active: boolean) {}
}

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.css']
})
export class TabItemComponent implements OnInit, AfterContentChecked {
  @Input()
  public title: string;

  @ContentChildren(TabItemTitle, { descendants: false })
  titleTpls: QueryList<TabItemContent>;
  @ContentChildren(TabItemContent, { descendants: false })
  contentTpls: QueryList<TabItemContent>;

  contentTpl: TabItemContent | null;
  titleTpl: TabItemContent | null;

  constructor() {}

  ngOnInit() {}

  ngAfterContentChecked() {
    console.assert(
      this.titleTpls.length > 0,
      'Title template marked ng-template[tabItemTitle] is mandatory'
    );

    this.contentTpl = this.contentTpls ? this.contentTpls.first : undefined;
    this.titleTpl = this.titleTpls.first;
  }
}
