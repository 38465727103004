import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationStatic } from './configuration-static';
import { BehaviorSubject, Subject } from 'rxjs';
import { BackendService } from './backend.service';
import { ModelService } from './model.service';
import { ThreeService } from './three.service';
import * as _ from 'lodash';
import { GoogleAnalyticsService } from './google-analytics.service';
import { Title } from '@angular/platform-browser';

type ProductId = string;
type ObjectName = string;

export interface Localized<T> {
  [country: string]: T;
}

export interface Appearance {
  envMapIntensity: number;
  envMapIntensity_night: number;
  envMapName: string;
  viewerBackground: string;
  viewerBackground_night: string;
  toneMappingExposure: number;
}

export interface Animation {
  dependencies: string[];
  startState: 'begin' | 'end';
  timeScale: number;
}

export interface Product {
  _id: string;
  name: string;
  url: string;
  published: boolean;
  modelUrl: string;
  updatedAt: Date;
  createdAt: Date;
  availableLanguages: Language[];
  items: ModelItem[];
  perspectives: Perspective[];
  initialPerspective: string | Perspective;
  screenshotCamera: ScreenshotCamera;
  appearance: Appearance;
  animation: Animation;
}

export interface ScreenshotCamera {
  cameraReference: string;
  frustum: number;
  width: number;
  height: number;
  pointOfView?: PointOfView;
}

export interface PointOfView {
  name: string;
  position: THREE.Object3D;
  lookAt: THREE.Object3D;
}

export interface Perspective {
  name: string;
  cameraReference: string;
  pointOfView?: PointOfView;
  animation: boolean;
  duration: number;
  lowerDistance: number;
  upperDistance: number;
  lockTarget: boolean;
  lockPosition: boolean;
}

export interface MaterialVariant {
  materialName?: string;
  productId?: ProductId;
  material?: THREE.Material;
  imageUrl?: string;
  countries?: Localized<MaterialInformation>;
  information?: MaterialInformation;
}

export interface ModelItem {
  productId: ProductId;
  objectName: ObjectName;
  trivialName?: string;
  category?: string;
  imageUrl?: string;
  productDependencies?: ProductId[];
  viewDependencies?: ObjectName[];
  viewCollisions?: ObjectName[];
  mandatoryAlternatives?: ProductId[];
  initialVisibility: boolean;
  userSelectable: boolean;
  invisibleForScreenshot?: boolean;
  visible?: boolean;
  countries?: Localized<ItemInformation>;
  countryAvailability?: string[];
  information?: ItemInformation;
  materialStandardVariant?: MaterialVariant;
  materialVariants?: MaterialVariant[];
  materialVariantsTarget?: string[];
  currentVariant?: MaterialVariant;
}

export interface ItemInformation {
  title?: string;
  shortTitle?: string;
  description?: string;
  price?: number;
}

export interface MaterialInformation {
  name?: string;
  description?: string;
  price?: number;
}

export interface CartItem {
  modelItem: ModelItem;
  amount?: number;
  unmetDependencies?: ModelItem[];
}

export interface Language {
  code: string;
  label: string;
  shortLabel: string;
  currency: string;
  showPrice: boolean;
  showRetailerButton: boolean;
  active: boolean;
  vat: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements Product {
  public isReady = false;

  // Raw database answer
  private product: Product;

  public _id: string;
  public name: string;
  public items: ModelItem[];
  public url: string;
  public published: boolean;
  public modelUrl: string;
  public updatedAt: Date;
  public createdAt: Date;
  public availableLanguages: Language[];
  public perspectives: Perspective[];
  public initialPerspective: Perspective;
  public screenshotCamera: ScreenshotCamera;
  public appearance: Appearance;
  public animation: Animation;

  public animations: THREE.AnimationClip[];
  public materials: THREE.Material[];
  public pointsOfView: PointOfView[];
  public modelIds: string[];

  public configurationInUse$ = new BehaviorSubject<boolean>(false);
  public configurationChanged$ = new Subject();

  public configurationVersion = 0;

  public productId: string;

  public languageHint: string;
  // Private currently set language
  private _language: Language;

  public shoppingCart: CartItem[] = [];
  public shoppingCartSum: number;
  public shoppingCartTax: number;
  public visibilityChanges = {};

  public skipThree = false;

  constructor(
    private translate: TranslateService,
    private backend: BackendService,
    private modelService: ModelService,
    private threeService: ThreeService,
    private ga: GoogleAnalyticsService,
    private titleService: Title
  ) {
    translate.setDefaultLang('de');
  }

  async load(identifier?: string, skipThree = false) {
    this.skipThree = skipThree;
    this.isReady = false;
    if (identifier) {
      this.productId = identifier;
      let res = await this.backend.productService.find({
        query: { url: identifier }
      });
      if (res.total > 0) {
        let payload = res.data[0];
        this.product = payload;
        this.assumeLanguage();
        await this.processProduct();
      } else {
        throw new Error(`Product "${identifier}" not found.`);
      }
    }
  }

  async _dev_load(url: string) {
    this.isReady = false;

    this.product = await new Promise<Product>((resolve, reject) => {
      let answer = Object.assign({}, ConfigurationStatic.content, {
        modelUrl: url
      });
      setTimeout(() => resolve(answer as any), 200);
    });

    this.assumeLanguage();
    await this.processProduct();
  }

  async processProduct() {
    Object.assign(this, this.product);

    if (this.perspectives === undefined || this.perspectives.length === 0) {
      throw new Error('No camera available');
    }

    this.mergeLanguageInformation();

    if (this.skipThree) {
      this.isReady = true;
      return;
    }

    if (this.threeService.renderer) {
      this.threeService.renderer.toneMappingExposure = this.appearance.toneMappingExposure;
    }
    this.threeService.envMapIntensity = this.appearance.envMapIntensity;
    this.threeService.envMapIntensity_night = this.appearance.envMapIntensity_night;
    this.threeService.backgroundColorCSS = this.appearance.viewerBackground;
    this.threeService.backgroundColorCSS_night = this.appearance.viewerBackground_night;
    this.threeService.setNightMode(false);
    this.threeService.envmap = this.threeService.envmaps.find(
      envmap => envmap.name === this.appearance.envMapName
    );
    this.threeService.screenshotUnwantedObjectNames = this.items
      .filter(item => item.invisibleForScreenshot)
      .map(item => item.objectName);

    try {
      await this.loadModel();
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }

    this.initialPerspective = this.perspectives.find(
      camera => camera.name === this.product.initialPerspective
    );

    this.modelIds = [];
    if (this.threeService.scene) {
      this.threeService.scene.traverse(child => {
        this.modelIds.push(child.name);
      });
    }

    this.resetConfiguration();

    this.perspectives.forEach(camera => {
      camera.pointOfView = this.pointsOfView.find(
        pov => pov.name === camera.cameraReference
      );
    });

    if (this.screenshotCamera) {
      this.screenshotCamera.pointOfView = this.pointsOfView.find(
        pov => pov.name === this.screenshotCamera.cameraReference
      );
    }

    this.threeService.screenshotCamera = this.screenshotCamera;

    this.isReady = true;
    this.configurationChanged$.next();
  }

  public resetConfiguration() {
    if (this.items) {
      this.items.forEach(item => {
        this.changeVisibility(item, item.initialVisibility);
        this.changeMaterialVariant(item);
        if (item.materialStandardVariant === undefined) {
          item.materialStandardVariant = item.currentVariant;
        }
        if (item.materialStandardVariant) {
          item.currentVariant = item.materialStandardVariant;
          this.changeMaterialVariant(item);
        }
      });
    }
  }

  private mergeLanguageInformation() {
    let langCode = this._language.code;
    if (this.items) {
      this.items.forEach(thisItem => {
        if (
          'countries' in thisItem &&
          !(this._language.code in thisItem.countries)
        ) {
          throw new Error(
            `Incomplete product information for language ${
              this._language.label
            } (${this._language.code}), item ${thisItem.productId}/${
              thisItem.trivialName
            }`
          );
        }

        if (thisItem.materialVariants) {
          thisItem.materialVariants.forEach(materialVariant => {
            if (
              materialVariant.countries &&
              langCode in materialVariant.countries
            ) {
              materialVariant.information = materialVariant.countries[langCode];
            } else {
              throw new Error(
                `Incomplete material configuration: ${
                  thisItem.objectName
                } has no language information (${langCode}) for variant ${
                  materialVariant.materialName
                }`
              );
            }
          });
        }

        thisItem.information = thisItem.countries[langCode];
      });
      this.updateShoppingCart();
    }
  }

  public getPrice(item: ModelItem) {
    let variantPrice = item.currentVariant
      ? item.currentVariant.information.price
      : 0;
    return item.information.price + variantPrice;
  }

  private async loadModel() {
    try {
      await this.modelService.load(this.modelUrl, 12000000);
      this.animations = this.modelService.animations;
      this.pointsOfView = this.modelService.pointsOfView;
      this.materials = this.modelService.materials;
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => {
        this.threeService.resize();
      }, 500);
    }
  }

  private assumeLanguage() {
    let navigator = window.navigator as any;
    let languageCode = navigator.userLanguage || navigator.language;
    if (this.languageHint) {
      languageCode = this.languageHint;
    }
    if (this.product.availableLanguages === undefined) {
      return;
    }

    let lang = this.product.availableLanguages.find(
      language => language.code.toLowerCase() === languageCode.toLowerCase()
    );

    if (lang === undefined) {
      // Remove trailing de-DE, en-US
      languageCode = languageCode.split('-')[0];
      lang = this.product.availableLanguages.find(
        language => language.code.toLowerCase() === languageCode.toLowerCase()
      );
    }

    if (lang) {
      this.language = lang;
    } else {
      this.language = this.product.availableLanguages[0];
    }
  }

  public removeAllCartItems() {
    this.configurationVersion++;
    this.shoppingCart = [];
    this.updateShoppingCart();
  }

  public isInShoppingCart(item: ModelItem) {
    return (
      this.shoppingCart.find(cartItem => cartItem.modelItem === item) !==
      undefined
    );
  }

  public updateAmountForItem(modelItem: ModelItem, amount?: number) {
    this.configurationVersion++;
    let cartItem = this.shoppingCart.find(
      thisCartItem => thisCartItem.modelItem === modelItem
    );

    if (cartItem && amount === undefined) {
      return;
    }

    if (cartItem) {
      cartItem.amount = amount;
    } else {
      if (amount === undefined) {
        amount = 1;
      }
      this.shoppingCart.push({
        modelItem: modelItem,
        amount: amount
      });
    }

    this.shoppingCart = this.shoppingCart.filter(
      thisItem => thisItem.amount > 0
    );

    this.updateShoppingCart();
  }

  public clearShoppingCart() {
    this.shoppingCart = [];
    this.updateShoppingCart();
  }

  public amountForItem(modelItem: ModelItem) {
    let cartItem = this.shoppingCart.find(
      thisCartItem => thisCartItem.modelItem === modelItem
    );
    return cartItem ? cartItem.amount : 0;
  }

  private updateShoppingCart() {
    this.shoppingCartSum = this.shoppingCart.reduce((prev, curr) => {
      return prev + this.getPrice(curr.modelItem) * curr.amount;
    }, 0);
    this.shoppingCartTax = this.shoppingCartSum * (this.language.vat / 100);

    // Check if all product dependencies are met
    this.shoppingCart.forEach(cartItem => {
      cartItem.unmetDependencies = [];
      let modelItem = cartItem.modelItem;
      if (modelItem.productDependencies) {
        modelItem.productDependencies.forEach(dependency => {
          let dependencyModelItem = this.items.find(
            thisItem => thisItem.productId === dependency
          );
          if (
            this.shoppingCart.find(
              thisCartItem => thisCartItem.modelItem === dependencyModelItem
            ) === undefined
          ) {
            cartItem.unmetDependencies.push(dependencyModelItem);
          }
        });
      }
    });
  }

  set language(language: Language) {
    if (
      this._language !== language &&
      this.product.availableLanguages.includes(language)
    ) {
      this._language = language;
      this.configurationVersion++;
      this.translate.use(this._language.code);
      this.mergeLanguageInformation();
      // Set title
      this.translate.get('PAGE_TITLE').subscribe(title => {
        this.titleService.setTitle(title);
      });
    }
  }

  get language() {
    return this._language;
  }

  changeMaterialVariant(item: ModelItem) {
    if (this.skipThree) {
      return;
    }

    this.configurationVersion++;
    if (item.materialVariants === undefined) {
      return;
    }
    if (item.materialVariantsTarget === undefined) {
      item.materialVariantsTarget = [];
    }
    if (!Array.isArray(item.materialVariantsTarget)) {
      item.materialVariantsTarget = [item.materialVariantsTarget];
    }

    let targets = item.materialVariantsTarget
      .map(target => this.threeService.scene.getObjectByName(target))
      .filter(target => target !== undefined);

    // If item has variants but no current variant assigned, try if the current material is in the variants
    if (
      item.currentVariant === undefined &&
      targets.length > 0 &&
      item.materialVariants
    ) {
      let currentMaterial = this.materials.find(
        thisMaterial => thisMaterial.name === _.get(targets[0], 'material.name')
      );
      if (currentMaterial) {
        item.currentVariant = item.materialVariants.find(
          variant => variant.materialName === currentMaterial.name
        );
      }
    }

    // If there is still no current variant set, just leave this object alone
    if (item.currentVariant === undefined) {
      return;
    }

    // if (this.materials === undefined) {
    //   console.assert(this.materials, 'oh no, no materials!!11');
    // }

    let material = this.materials.find(
      thisMaterial => thisMaterial.name === item.currentVariant.materialName
    );
    if (targets && material) {
      (targets as any).forEach(target => (target.material = material));
    }
    this.requestUpdate();
    this.updateShoppingCart();
  }

  changeVisibility(item: ModelItem, visible: boolean, depth: number = 0) {
    if (this.skipThree) {
      return;
    }
    this.configurationVersion++;

    item.visible = visible;

    if (item.materialStandardVariant) {
      item.currentVariant = item.materialStandardVariant;
    }

    if (visible) {
      this.changeMaterialVariant(item);
    }

    if (depth > 10) {
      return;
    }

    if (visible) {
      // Make dependencies visible
      item.viewDependencies
        .map(dep => this.items.find(thisItem => thisItem.productId === dep))
        .filter(dep => dep !== undefined)
        .filter(dep => dep.visible === false)
        .forEach(dep => this.changeVisibility(dep, true, depth + 1));

      // Make conflicts invisible
      item.viewCollisions
        .map(dep => this.items.find(thisItem => thisItem.productId === dep))
        .filter(dep => dep !== undefined)
        .filter(dep => dep.visible)
        .forEach(dep => this.changeVisibility(dep, false, depth + 1));
    } else {
      this.items
        .filter(thisItem => thisItem.viewDependencies.includes(item.productId))
        .forEach(thisItem => this.changeVisibility(thisItem, false, depth + 1));

      if (item.mandatoryAlternatives && depth === 0) {
        item.mandatoryAlternatives
          .map(alternative =>
            this.items.find(thisItem => thisItem.productId === alternative)
          )
          .filter(dep => dep !== undefined)
          .forEach(alternative =>
            this.changeVisibility(alternative, true, depth + 1)
          );
      }
    }

    if (depth === 0) {
      this.requestUpdate();
    }
  }

  private clearStagedVisibilityChanges() {
    this.visibilityChanges = {};
  }

  private stageVisibilityChange(item, visibility) {
    if (item.objectName === '') {
      return;
    }
    if (!this.visibilityChanges.hasOwnProperty(item.objectName)) {
      this.visibilityChanges[item.objectName] = [];
    }
    this.visibilityChanges[item.objectName].push(visibility);
  }

  private commitStagedVisibilityChanges() {
    for (let objectName in this.visibilityChanges) {
      if (this.visibilityChanges.hasOwnProperty(objectName)) {
        let object = this.threeService.scene.getObjectByName(objectName);
        if (object) {
          object.visible = this.visibilityChanges[objectName].some(d => d);
        }
      }
    }
  }

  requestUpdate() {
    this.clearStagedVisibilityChanges();
    this.items.forEach(item => {
      this.stageVisibilityChange(item, item.visible);
    });
    this.commitStagedVisibilityChanges();

    this.configurationChanged$.next();
    if (this.configurationInUse$.getValue() === false) {
      this.configurationInUse$.next(true);
      setTimeout(() => {
        this.configurationInUse$.next(false);
      }, 1000);
    }
  }
}
