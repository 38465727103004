<!-- Teilen -->
<div class="toggle-animation clickable svg-button-container large-show" [ngbPopover]="popContent" placement="left" #p="ngbPopover"
  (click)="showPopover(p)">
  <svg viewBox="0 0 80 80" class="svg-button">
    <use xlink:href="assets/shop/icon/functions/share.svg#icon/functions/share"></use>
  </svg>
  <span class="large-show show-only-big-screen">{{'Share' | translate}}</span>
</div>
<div class="toggle-animation clickable svg-button-container small-show" [ngbPopover]="popContent" placement="left">
  <svg viewBox="0 0 80 80" class="svg-button">
    <use xlink:href="assets/shop/icon/functions/share.svg#icon/functions/share"></use>
  </svg>
  <span class="large-show show-only-big-screen">{{'Share' | translate}}</span>
</div>

<!-- Andocken -->
<div (click)="animationService.togglePlay()" class="toggle-animation clickable mr-4 svg-button-container">
  <svg viewBox="0 0 80 80" class="svg-button">
    <use xlink:href="assets/shop/icon/functions/rollstuhl.svg#icon/functions/rollstuhl"></use>
  </svg>
  <img src="">
  <span class="large-show show-only-big-screen" *ngIf="animationService.currentState === 'end'">{{'Attach wheelchair' | translate}}</span>
  <span class="large-show show-only-big-screen" *ngIf="animationService.currentState === 'begin'">{{'Detach wheelchair' | translate}}</span>
</div>

<!-- Check -->
<div (click)="openModal()" class="toggle-animation clickable mr-4 svg-button-container">
  <svg viewBox="0 0 80 80" class="svg-button">
    <use xlink:href="assets/shop/icon/functions/rollstuhl-check.svg#icon/functions/rollstuhl-check"></use>
  </svg>
  <img src="">
  <span class="large-show show-only-big-screen">{{'Wheelchair Check Button' | translate}}</span>
</div>

<!-- day night toggle -->
<app-toggle-button class="clickable mr-4" (stateChanged)="threeService.setNightMode($event)"></app-toggle-button>

<ng-template #checkModal let-c="close">
  <div class="modal-header">
    <h4 class="modal-title ">{{'Wheelchair Check' | translate}}</h4>
  </div>
  <div class="modal-body preformatted-text">
{{'WHEELCHAIR_CHECK_TEXT' | translate}}
  </div>
  <div class="modal-footer">
    <button class="button button-alt" (click)="c('close')">{{'Close' | translate}}</button>
  </div>
</ng-template>

<ng-template #popContent>

  <div class="sharing-buttons">
    <div (click)="share('link')">
      <div class="toggle-animation clickable svg-button-container">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/copylink.svg#icon/social/copylink"></use>
        </svg>
        <span class="large-show">{{'Copy Link' | translate}}</span>
      </div>
    </div>
    <div class="sharing-links">
      <div class="toggle-animation clickable svg-button-container" (click)="share('facebook')">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/facebook.svg#icon/social/facebook"></use>
        </svg>
      </div>
      <div class="toggle-animation clickable svg-button-container" (click)="share('googleplus')">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/googleplus.svg#icon/social/googleplus"></use>
        </svg>
      </div>
      <!-- <div class="toggle-animation clickable svg-button-container" (click)="share('instagram')">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/instagram.svg#icon/social/instagram"></use>
        </svg>
      </div> -->
      <div class="toggle-animation clickable svg-button-container" (click)="share('pinterest')">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/pinterest.svg#icon/social/pinterest"></use>
        </svg>
      </div>
      <div class="toggle-animation clickable svg-button-container" (click)="share('twitter')">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/twitter.svg#icon/social/twitter"></use>
        </svg>
      </div>
      <div class="toggle-animation clickable svg-button-container" (click)="share('mail')">
        <svg viewBox="0 0 80 80" class="svg-button">
          <use xlink:href="assets/icon/social/mail.svg#icon/social/mail"></use>
        </svg>
      </div>
    </div>
  </div>
</ng-template>
