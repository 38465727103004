import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AnimationService } from '../../animation.service';
import { ThreeService } from '../../three.service';
import { NgbPopover, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialShareService } from '../../social-share.service';
import { SerializationService } from '../../serialization.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';

@Component({
  selector: 'app-control-buttons',
  templateUrl: './control-buttons.component.html',
  styleUrls: ['./control-buttons.component.css']
})
export class ControlButtonsComponent implements OnInit {
  @ViewChild('checkModal')
  public checkModal: ElementRef;

  private currentPopover: NgbPopover;

  constructor(
    public animationService: AnimationService,
    public threeService: ThreeService,
    public socialShare: SocialShareService,
    public serialization: SerializationService,
    public modalService: NgbModal,
    public ga: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  showPopover(p: NgbPopover) {
    this.ga.sendEvent('Share menu opened', 'Page functions', undefined);

    this.currentPopover = p;
  }

  share(where: string) {
    this.socialShare.share(where);

    if (this.currentPopover) {
      this.currentPopover.close();
    }
  }

  openModal() {

    this.ga.sendEvent('Open Check', 'Page functions', undefined);

    return this.modalService
    .open(this.checkModal, { windowClass: 'check-wheelchair-modal', size: 'lg' });
  }
}
