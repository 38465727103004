import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { faTrashAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
var ProductMaterialsComponent = /** @class */ (function () {
    function ProductMaterialsComponent(configurationService) {
        var _this = this;
        this.configurationService = configurationService;
        this.showBody = false;
        this.faTrashAlt = faTrashAlt;
        this.faPlusSquare = faPlusSquare;
        this.search = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : _this.configurationService.modelIds
                        .filter(function (v) { return v.toLowerCase().indexOf(term.toLowerCase()) > -1; })
                        .slice(0, 10);
            }));
        };
    }
    ProductMaterialsComponent.prototype.ngOnInit = function () {
        this.updateCurrentItem();
    };
    ProductMaterialsComponent.prototype.changeCurrentItem = function (item) {
        this.currentItem = item;
        this.updateCurrentItem();
    };
    ProductMaterialsComponent.prototype.changeCurrentLanguage = function (language) {
        this.currentLanguage = language;
        this.updateCurrentItem();
    };
    ProductMaterialsComponent.prototype.addMaterialVariant = function () {
        if (this.nextTarget === undefined || this.nextTarget.length === 0) {
            return;
        }
        if (Array.isArray(this.currentItem.materialVariantsTarget)) {
            this.currentItem.materialVariantsTarget.push(this.nextTarget);
        }
        else {
            this.currentItem.materialVariantsTarget = [this.nextTarget];
        }
        this.nextTarget = '';
    };
    ProductMaterialsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.product && changes.product.currentValue) {
            if (this.currentItem && this.product.items) {
                this.currentItem = this.product.items.find(function (item) { return item.objectName === _this.currentItem.objectName; });
            }
        }
    };
    ProductMaterialsComponent.prototype.updateMaterialVariants = function () {
        var _this = this;
        this.materialVariants = this.configurationService.materials.map(function (material) { return ({
            materialName: material.name
        }); });
        if (this.currentItem) {
            this.currentItem.materialVariants.forEach(function (existingVariant) {
                var index = _this.materialVariants.findIndex(function (availableVariant) {
                    return availableVariant.materialName === existingVariant.materialName;
                });
                if (index !== -1) {
                    _this.materialVariants[index] = existingVariant;
                }
            });
        }
    };
    ProductMaterialsComponent.prototype.updateCurrentItem = function () {
        var _this = this;
        if (this.currentItem === undefined) {
            return;
        }
        this.updateMaterialVariants();
        if (this.currentItem.materialVariantsTarget === undefined) {
            this.currentItem.materialVariantsTarget = [];
        }
        if (this.currentItem.materialVariants === undefined) {
            this.currentItem.materialVariants = [];
        }
        this.currentItem.materialVariants.forEach(function (variant) {
            if (variant.countries === undefined) {
                variant.countries = {};
            }
            if (_this.currentLanguage &&
                variant.countries[_this.currentLanguage.code] === undefined) {
                variant.countries[_this.currentLanguage.code] = {
                    name: 'Label',
                    price: 0.0
                };
            }
        });
    };
    ProductMaterialsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return ProductMaterialsComponent;
}());
export { ProductMaterialsComponent };
