import { PipeTransform } from '@angular/core';
import { ModelItem } from './configuration.service';
var ItemCategoryPipe = /** @class */ (function () {
    function ItemCategoryPipe() {
    }
    ItemCategoryPipe.prototype.transform = function (value, category) {
        if (category) {
            if (value && Array.isArray(value)) {
                return value.filter(function (v) { return v.category === category; });
            }
            else {
                return null;
            }
        }
        else {
            return value;
        }
    };
    return ItemCategoryPipe;
}());
export { ItemCategoryPipe };
