import { OnInit } from '@angular/core';
var ProductItemDependenciesComponent = /** @class */ (function () {
    function ProductItemDependenciesComponent() {
        this.showBody = false;
    }
    ProductItemDependenciesComponent.prototype.ngOnInit = function () {
        // this.currentItem = this.product.items[0];
    };
    return ProductItemDependenciesComponent;
}());
export { ProductItemDependenciesComponent };
