import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
var ProductCoderComponent = /** @class */ (function () {
    function ProductCoderComponent() {
        this.productChange = new EventEmitter();
        this.showBody = false;
    }
    ProductCoderComponent.prototype.ngOnInit = function () {
        this.setInnerText();
    };
    ProductCoderComponent.prototype.ngOnChanges = function (changes) {
        this.setInnerText();
    };
    ProductCoderComponent.prototype.setInnerText = function () {
        this.innerText = JSON.stringify(this.product, undefined, 2);
    };
    ProductCoderComponent.prototype.save = function () {
        this.productChange.next(JSON.parse(this.innerText));
    };
    ProductCoderComponent.prototype.check = function () {
        try {
            JSON.parse(this.innerText);
            this.error = undefined;
        }
        catch (e) {
            this.error = e.toString();
        }
    };
    return ProductCoderComponent;
}());
export { ProductCoderComponent };
