var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConfigurationService } from './configuration.service';
import { BackendService } from './backend.service';
import * as i0 from "@angular/core";
import * as i1 from "./configuration.service";
import * as i2 from "./backend.service";
var SerializationService = /** @class */ (function () {
    function SerializationService(configService, backend) {
        this.configService = configService;
        this.backend = backend;
    }
    SerializationService.prototype.serialize = function (id, canvas) {
        return __awaiter(this, void 0, void 0, function () {
            var visibilities, shoppingCart, object, res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.locked) {
                            id = undefined;
                        }
                        if (this.lastConfigurationVersion === this.configService.configurationVersion && !this.locked) {
                            return [2 /*return*/, this.serializationId];
                        }
                        if (this.locked) {
                            this.locked = false;
                        }
                        this.lastConfigurationVersion = this.configService.configurationVersion;
                        visibilities = this.configService.items.filter(function (item) { return item.userSelectable; }).map(function (item) { return ({
                            productId: item.productId,
                            visibility: item.visible,
                            variantMaterialName: item.currentVariant
                                ? item.currentVariant.materialName
                                : undefined
                        }); });
                        shoppingCart = this.configService.shoppingCart.map(function (item) { return ({
                            productId: item.modelItem.productId,
                            amount: item.amount
                        }); });
                        object = {
                            visibilities: visibilities,
                            shoppingCart: shoppingCart,
                            language: this.configService.language.code,
                            screenshotUrl: this.screenshotUrl
                        };
                        if (!(id === undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.backend.serializationService.create(object)];
                    case 1:
                        res = _a.sent();
                        this.serializationId = res._id;
                        id = res._id;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.backend.serializationService.update(id, object)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!canvas) return [3 /*break*/, 8];
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.addScreenshot(id, canvas)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        e_1 = _a.sent();
                        console.log('Error creating screenshot');
                        console.log(e_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/, this.serializationId];
                }
            });
        });
    };
    SerializationService.prototype.lock = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.serializationId) return [3 /*break*/, 2];
                        this.locked = true;
                        return [4 /*yield*/, this.backend.serializationService.patch(this.serializationId, {
                                locked: true
                            })];
                    case 1:
                        res = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SerializationService.prototype.deserialize = function (id, languageOnly) {
        if (languageOnly === void 0) { languageOnly = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var object;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (id === undefined) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.backend.serializationService.get(id)];
                    case 1:
                        object = _a.sent();
                        if (object === undefined || object == null) {
                            throw new Error('No such saved configuration');
                        }
                        this.locked = (object.locked !== undefined) ? object.locked : false;
                        if (languageOnly) {
                            this.configService.languageHint = object.language;
                            return [2 /*return*/];
                        }
                        this.screenshotUrl = object.screenshotUrl;
                        object.visibilities.forEach(function (item) {
                            var productId = item.productId;
                            var visible = item.visibility;
                            var targetItem = _this.configService.items.find(function (thisItem) { return thisItem.productId === productId; });
                            _this.configService.changeVisibility(targetItem, visible);
                            var variant = targetItem.materialVariants.find(function (thisVariant) { return thisVariant.materialName === item.variantMaterialName; });
                            if (variant === undefined) {
                                return;
                            }
                            targetItem.currentVariant = variant;
                            _this.configService.changeMaterialVariant(targetItem);
                        });
                        this.configService.removeAllCartItems();
                        object.shoppingCart.forEach(function (item) {
                            var productId = item.productId;
                            var amount = item.amount;
                            var targetItem = _this.configService.items.find(function (thisItem) { return thisItem.productId === productId; });
                            _this.configService.updateAmountForItem(targetItem, amount);
                        });
                        this.configService.language = this.configService.availableLanguages.find(function (thisLanguage) { return thisLanguage.code === object.language; });
                        return [2 /*return*/];
                }
            });
        });
    };
    SerializationService.prototype.addScreenshot = function (id, canvas) {
        return __awaiter(this, void 0, void 0, function () {
            var fileURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.backend.uploadImageFromCanvas(canvas, id)];
                    case 1:
                        fileURL = _a.sent();
                        return [4 /*yield*/, this.backend.serializationService.patch(id, {
                                screenshotUrl: fileURL
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SerializationService.ngInjectableDef = i0.defineInjectable({ factory: function SerializationService_Factory() { return new SerializationService(i0.inject(i1.ConfigurationService), i0.inject(i2.BackendService)); }, token: SerializationService, providedIn: "root" });
    return SerializationService;
}());
export { SerializationService };
