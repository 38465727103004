import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ThreeService } from '../../three.service';
var ProductAppearanceComponent = /** @class */ (function () {
    function ProductAppearanceComponent(configService, threeService) {
        this.configService = configService;
        this.threeService = threeService;
        this.showBody = false;
        this.faTrashAlt = faTrashAlt;
    }
    ProductAppearanceComponent.prototype.ngOnInit = function () { };
    ProductAppearanceComponent.prototype.ngOnChanges = function (changes) {
        if (this.product && this.product.appearance === undefined) {
            this.product.appearance = {
                envMapIntensity: 1.0,
                envMapIntensity_night: 0.1,
                envMapName: 'Bridge2',
                toneMappingExposure: 1.2,
                viewerBackground: 'white',
                viewerBackground_night: 'darkgrey'
            };
        }
    };
    ProductAppearanceComponent.prototype.create = function () {
        if (this.product.perspectives === undefined) {
            this.product.perspectives = [];
        }
        this.product.perspectives.push({
            name: 'Camera',
            cameraReference: 'Null',
            duration: 1200,
            lowerDistance: 100,
            upperDistance: 300,
            lockPosition: false,
            lockTarget: false,
            animation: true
        });
    };
    ProductAppearanceComponent.prototype.remove = function (index) {
        this.product.perspectives.splice(index, 1);
    };
    return ProductAppearanceComponent;
}());
export { ProductAppearanceComponent };
