import { Injectable, OnInit } from '@angular/core';
import * as THREE from 'three';

import 'imports-loader?THREE=three!three/examples/js/loaders/FBXLoader';
import 'imports-loader?THREE=three!three/examples/js/loaders/GLTFLoader';
import 'imports-loader?THREE=three!three/examples/js/loaders/ColladaLoader';

import { PointOfView } from './configuration.service';

import * as _ from 'lodash';
import { ThreeService } from './three.service';
import { ProgressService } from './progress.service';

(window as any).THREE = THREE;

interface LoaderAnswer {
  group: THREE.Object3D;
  animations: THREE.AnimationClip[];
}

interface LoaderInfo {
  loader: any;
  map: (object: any) => LoaderAnswer;
}

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  public renderer: THREE.WebGLRenderer;

  public pointsOfView: PointOfView[] = [];
  public materials: THREE.Material[];
  public animations: THREE.AnimationClip[];

  public contents: LoaderAnswer;
  public currentEnvmap: Promise<THREE.CubeTexture>;

  public loadSize: number;

  public manager: THREE.LoadingManager;

  constructor(
    private threeService: ThreeService,
    private progress: ProgressService
  ) {}

  public async load(url, size?: number) {
    this.manager = new THREE.LoadingManager();
    this.manager.onStart = (myurl, itemsLoaded, itemsTotal) => {
      this.progress.progress = 0.1;
    };

    this.manager.onLoad = () => {
      this.progress.progress = 1.0;
    };

    this.manager.onProgress = (myurl, itemsLoaded, itemsTotal) => {
      this.progress.progress = itemsLoaded / itemsTotal;
      // console.log('Set progress to: ' + this.progress.progress);
    };

    this.loadSize = size;

    if (url === undefined) {
      throw new Error('Undefined url');
    }
    let extension = url.split('.').pop();

    let loaderInfo;
    switch (extension.toLowerCase()) {
      case 'fbx':
        loaderInfo = this.loadFBX();
        break;
      case 'gltf':
      case 'glb':
        loaderInfo = this.loadGLTF();
        break;
      case 'dae':
        loaderInfo = this.loadCollada();
        break;
      default:
        return Promise.reject('Unknown file format');
    }

    let contents = await this.executeLoader(loaderInfo, url);
    this.threeService.clearScene();
    await this.threeService.addToScene(contents.group);
    // (window as any).fixLight();
  }

  loadGLTF() {
    return {
      loader: new (THREE as any).GLTFLoader(this.manager),
      map: object => ({
        group: object.scene,
        animations: object.animations
      })
    };
  }

  loadFBX() {
    return {
      loader: new (THREE as any).FBXLoader(),
      map: object => ({
        group: object,
        animations: object.animations
      })
    };
  }

  loadCollada() {
    return {
      loader: new (THREE as any).ColladaLoader(),
      map: object => ({
        group: object.scene,
        animations: object.animations
      })
    };
  }

  private async executeLoader(
    loaderInfo: LoaderInfo,
    url: string
  ): Promise<LoaderAnswer> {
    this.progress.progress = 0.1;
    return new Promise<LoaderAnswer>((resolve, reject) => {
      loaderInfo.loader.load(
        url,
        object => {
          this.contents = loaderInfo.map(object);
          this.getCameras();
          this.getMaterials();
          this.animations = this.contents.animations;
          this.progress.progress = 1.0;
          resolve(this.contents);
        },
        xhr => {
          // console.log(`loaded: ${xhr.loaded}`);
          // console.log(
          //   `Progress: ${Math.min(1, xhr.loaded / 12000000) * 100}% loaded`
          // );
          // this.progress.progress = Math.min(1, xhr.loaded / this.loadSize);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getCameras() {
    let cameras = [];
    this.contents.group.traverse(child => {
      if (
        child.name.startsWith('Camera') &&
        child.name.indexOf('_target') === -1
      ) {
        cameras.push(child);
      }
    });

    this.pointsOfView = cameras.map(camera => ({
      name: camera.name,
      position: camera,
      lookAt: this.contents.group.getObjectByName(camera.name + '_target')
    }));

    this.pointsOfView = this.pointsOfView.filter(
      pov => pov.lookAt && pov.position && pov.name
    );

    this.pointsOfView.forEach(pov => {
      pov.position.visible = false;
      pov.lookAt.visible = false;
    });
  }

  getMaterials() {
    let materialsGroup = this.contents.group.getObjectByName('Materials');
    if (materialsGroup === undefined) {
      materialsGroup = this.contents.group.getObjectByName('materials');
    }
    this.materials = [];
    if (materialsGroup) {
      materialsGroup.visible = false;
      materialsGroup.traverse(child => {
        if ('material' in child) {
          let material = (child as any).material;

          try {
            material.name = material.name ? material.name : (child as any).name;
            material.name = material.name
              ? material.name
              : (child as any).parent.name;
          } catch (e) {
            console.error(e);
            console.warn('No name for material', material);
          }

          this.materials.push(material);
          // Sometimes materials don't have names. Use the parents name then.
        }
      });
    }
  }
}
