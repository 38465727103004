/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./scroll-list.component";
import * as i4 from "../../animation.service";
var styles_ScrollListComponent = [i0.styles];
var RenderType_ScrollListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollListComponent, data: {} });
export { RenderType_ScrollListComponent as RenderType_ScrollListComponent };
function View_ScrollListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "nav-arrow nav-arrow-left"], ["src", "assets/shop/slideshow-nav.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll("left") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_ScrollListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "nav-arrow nav-arrow-right"], ["src", "assets/shop/slideshow-nav.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll("right") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_ScrollListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { longContent: 0 }), i1.ɵqud(402653184, 2, { contentContainer: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["longContent", 1]], null, 2, "div", [["class", "long-content"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["contentContainer", 1]], null, 1, "div", [["class", "content-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScrollListComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScrollListComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dots > 1); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.dots > 1); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ScrollListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scroll-list", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).resize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ScrollListComponent_0, RenderType_ScrollListComponent)), i1.ɵdid(1, 638976, null, 0, i3.ScrollListComponent, [i4.AnimationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollListComponentNgFactory = i1.ɵccf("app-scroll-list", i3.ScrollListComponent, View_ScrollListComponent_Host_0, {}, {}, ["*"]);
export { ScrollListComponentNgFactory as ScrollListComponentNgFactory };
