<app-basket-box *ngFor="let item of configService.shoppingCart" [item]="item">
</app-basket-box>
<table *ngIf="configService.shoppingCart.length && configService.language.showPrice">
  <tr>
    <td class="main">{{'Total' | translate}}</td>
    <td class="main">{{configService.shoppingCartSum | currency:configService.language.currency}}</td>
    <td class="large-show">{{'All prices with tax' | translate:configService.language}}</td>
  </tr>
  <tr>
    <td>{{'Tax' | translate:configService.language}}</td>
    <td>{{configService.shoppingCartTax | currency:configService.language.currency}}</td>
  </tr>
</table>
<div class="buttons" *ngIf="configService.shoppingCart.length">
  <button [disabled]="printing" class="button button-prim" *ngIf="serializationService.serializationId" (click)="print()">
    <fa-icon *ngIf="printing" [spin]="true" [icon]="faCircleNotch"></fa-icon> {{'Generate datasheet' | translate}}
  </button>
  <button class="button button-prim" (click)="clearSelection()">{{'Clear basket' | translate}}</button>
  <button *ngIf="configService.language.showRetailerButton" class="button button-prim" (click)="openLink()">{{'Search retailer' | translate}}</button>
</div>

<div *ngIf="configService.shoppingCart.length === 0" class="empty-basket text-huge">
  {{'Basket is empty' | translate}}
</div>
