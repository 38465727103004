<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      <span *ngIf="currentProduct">
        <h3>
          <span class="badge badge-secondary">{{currentProduct?.name}}</span>
        </h3>
      </span>
      <span *ngIf="currentProduct === undefined">
        <a>Select product here</a>
      </span>
    </div>
    <div *ngIf="showBody" class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">URL</th>
            <th scope="col">Published</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products; let i = index" (click)="clickedProduct(product)" class="clickable">
            <th scope="row">{{i+1}}</th>
            <td>{{product.name}}</td>
            <td>{{product.url}}</td>
            <td>
              <input type="checkbox" [checked]="product.published" disabled>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <button class="btn btn-primary btn-sm" (click)="create()">Create</button>
        </div>
      </div>

    </div>
  </div>
</div>
