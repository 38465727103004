import { Injectable } from '@angular/core';
import { ClipboardService } from './clipboard.service';
import { TranslateService } from '@ngx-translate/core';
import { SerializationService } from './serialization.service';
import { GoogleAnalyticsService } from './google-analytics.service';

function facebookLink(url) {
  return `https://www.facebook.com/sharer.php?u=${url}`;
}

function googlePlusLink(url, text, language_code) {
  return `https://plus.google.com/share?url=${url}&text=${text}&hl=${language_code}`;
}

function pinterestLink(url, imageLink, text) {
  return `https://pinterest.com/pin/create/button/?url=${url}&media=${imageLink}&description=${text}`;
}

function mailLink(subject, body) {
  return `mailto:?subject=${subject}&body=${body}`;
}

function twitterLink(text) {
  return `https://twitter.com/home?status=${text}`;
}

@Injectable({
  providedIn: 'root'
})
export class SocialShareService {
  constructor(
    private clipboard: ClipboardService,
    private translateService: TranslateService,
    private serializationService: SerializationService,
    private ga: GoogleAnalyticsService
  ) {}

  public share(where: string) {

    this.ga.sendEvent('Share button clicked', 'Page functions', where);

    switch (where) {
      case 'link':
        this.clipboard.copyToClipboard(location.href, true);
        break;
      case 'facebook':
        window.open(facebookLink(location.href), '_blank');
        break;
      case 'googleplus':
        window.open(
          googlePlusLink(
            location.href,
            this.translateService.instant('sharing text'),
            undefined
          ),
          '_blank'
        );
        break;
      case 'pinterest':
        window.open(
          pinterestLink(
            location.href,
            location.origin + '/' + this.serializationService.screenshotUrl,
            this.translateService.instant('sharing text')
          ),
          '_blank'
        );
        break;
      case 'twitter':
        window.open(
          twitterLink(
            this.translateService.instant('tweet', { url: location.href })
          ),
          '_blank'
        );
        break;
      case 'mail':
        window.open(
          mailLink(
            this.translateService.instant('sharing mail subject'),
            this.translateService.instant('sharing mail body', {
              url: location.href
            })
          ),
          '_blank'
        );
    }
    this.serializationService.lock();
  }
}
