import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from '../backend.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ConfigurationService } from '../configuration.service';
import { Product } from '../configuration.service';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import * as _ from 'lodash';
import { ThreeService } from '../three.service';

export enum SaveState {
  Pristine,
  Saving,
  Changed
}

const CHANGE_CHECK_TIME_MS = 500;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  @ViewChild('loginContent') public loginContent;
  @ViewChild('glContainer') public glContainer;

  public credentials = {
    email: '',
    password: ''
  };

  public faCircleNotch = faCircleNotch;

  public products: Product[];
  public currentProduct: Product;
  public currentProductCopy: Product;
  public saveState: SaveState;
  public SaveStates = SaveState;
  public pane = 'products';

  constructor(
    public backend: BackendService,
    private modalService: NgbModal,
    public configurationService: ConfigurationService,
    public threeService: ThreeService,
    private router: Router
  ) {
    threeService.renderingDisabled = true;
  }

  async ngOnInit() {
    this.saveState = SaveState.Pristine;
    await this.loginLoop();
    this.load();
    setInterval(() => {
      this.checkSaveState();
    }, CHANGE_CHECK_TIME_MS);
  }

  createProductCopy() {
    this.currentProductCopy = _.cloneDeep(this.currentProduct);
  }

  checkSaveState() {
    if (!_.isEqual(this.currentProduct, this.currentProductCopy)) {
      this.saveState = SaveState.Changed;
    } else {
      this.saveState = SaveState.Pristine;
    }
  }

  async loginLoop() {
    while (!this.backend.loggedIn) {
      try {
        if (this.credentials.email === '') {
          await this.backend.login();
        } else {
          await this.backend.login(this.credentials);
        }
      } catch (e) {
        console.error(e);
        let dismissReason;
        while (dismissReason !== 'login') {
          dismissReason = await this.open(this.loginContent);
        }
      }
    }
  }

  async logout() {
    await this.backend.logout().then(() => {
      this.router.navigateByUrl('/viewer');
    });
  }

  async productChanged(product?: Product) {
    if (product) {
      await this.save(product);
    }
    this.createProductCopy();
    this.updateModelUrl();
  }

  updateModelUrl() {
    if (this.currentProduct) {
      this.configurationService
        ._dev_load(this.currentProduct.modelUrl)
        .catch((err) => {
          console.error('loading model failed', err);
        });
    }
  }

  open(content) {
    return this.modalService
      .open(content)
      .result.then(() => {
        return Promise.resolve('login');
      })
      .catch(() => {
        return Promise.resolve('cancel');
      });
  }

  load() {
    return this.backend.productService
      .find({
        query: {
          $sort: 'updatedAt'
        }
      })
      .then(productResponse => {
        this.products = productResponse.data;
        this.currentProduct = undefined;
      });
  }

  create() {
    this.backend.productService
      .create({
        name: 'Product',
        url: 'product',
        published: false
      })
      .then(() => {
        return this.load();
      })
      .catch(e => {
        alert('Product not created. ' + e);
      });
  }

  async save(product) {
    this.saveState = SaveState.Saving;
    await this.backend.productService
      .update(product._id, product)
      .then(updatedProduct => {
        let foundProduct = this.products.find(
          thisProduct => thisProduct._id === updatedProduct._id
        );
        if (foundProduct) {
          // Object.assign(foundProduct, updatedProduct);
          this.currentProduct = updatedProduct;
          this.createProductCopy();
        }
      });
  }

  delete(product) {
    return this.backend.productService.remove(product._id).then(() => {
      this.load();
    });
  }
}
