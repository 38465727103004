<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Country Restrictions
    </div>
    <div *ngIf="showBody" class="card-body">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Item</th>
            <th *ngFor="let language of product.availableLanguages" scope="col">{{language.shortLabel}} <a><fa-icon [icon]="faCheckDouble" (click)="toggleAll(language)" class="clickable"></fa-icon></a></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of product.items; let i = index">
            <td>{{item.trivialName}}</td>
            <td *ngFor="let language of product.availableLanguages"><input type="checkbox" (change)="change(item, language)" [checked]="item.countryAvailability?.includes(language.code)"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
