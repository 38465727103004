<div class="flex-container large-show">
  <div class="box left-box">
    <div style.background-image="url('{{item.modelItem.imageUrl}}')"></div>
  </div>
  <div class="box stretch-box">
    <div class="heading">{{item.modelItem.information.title}}<span *ngIf="item.modelItem.currentVariant"> -
        {{item.modelItem.currentVariant.information.name}}</span></div>
    <div class="preformatted-text">{{item.modelItem.information.description}}</div>
  </div>
  <div (click)="remove()" class="box fixed-box link-box large-show">
    <span class="clickable"><img class="img-button sm" src="assets/shop/button-remove.svg">{{'remove' | translate}}</span>
  </div>
  <div *ngIf="configService.language.showPrice" class="box fixed-box">
    <div class="heading">{{'Price' | translate}}</div>
    <div>{{configService.getPrice(item.modelItem) | currency:configService.language.currency }}</div>
  </div>
  <!-- <div class="box fixed-box">
    <div class="heading">{{'Amount' | translate}}</div>
    <input [(ngModel)]="amount" type="number">
    <div class="refresh-container" (click)="configService.updateAmountForItem(item.modelItem, amount)">
      <img class="img-button" src="assets/shop/button-refresh.svg">
    </div>
  </div> -->
</div>
<div *ngIf="item.unmetDependencies?.length > 0" class="box lower-box large-show">{{'Unmet product dependency' |
  translate}}
  <span class="clickable underlined" (click)="configService.updateAmountForItem(depItem)" *ngFor="let depItem of item.unmetDependencies; let last = last">{{depItem.information.title}}{{last
    ? '' : ', '}}</span>
</div>

<div class="flex-container small-show small">

  <div class="image-box" style.background-image="url('{{item.modelItem.imageUrl}}')"></div>

  <div class="box full-width heading">
    <div>{{item.modelItem.information.title}}<span *ngIf="item.modelItem.currentVariant"> -
        {{item.modelItem.currentVariant.information.name}}</span></div>
  </div>

  <div class="full-width textbox">{{configService.getPrice(item.modelItem) | currency:configService.language.currency }}</div>
  <div class="full-width textbox preformatted-text">{{item.modelItem.information.description}}</div>

  <div (click)="remove()" class="box fixed-box link-box mobile-remove">
    <span class="clickable"><img class="img-button sm" src="assets/shop/button-remove.svg">{{'remove' | translate}}</span>
  </div>


  <div *ngIf="item.unmetDependencies?.length > 0" class="box lower-box">{{'Unmet product dependency' | translate}}
    <span class="clickable underlined" (click)="configService.updateAmountForItem(depItem)" *ngFor="let depItem of item.unmetDependencies; let last = last">{{depItem.information.title}}{{last
      ? '' : ', '}}</span>
  </div>

</div>
