import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CartItem, ConfigurationService } from '../../../configuration.service';

@Component({
  selector: 'app-basket-box',
  templateUrl: './basket-box.component.html',
  styleUrls: ['./basket-box.component.css']
})
export class BasketBoxComponent implements OnInit, OnChanges {

  @Input()
  item: CartItem;

  public amount;

  constructor(public configService: ConfigurationService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.amount = this.item.amount || 0;
  }

  remove() {
    this.configService.updateAmountForItem(this.item.modelItem, 0);
  }

}
