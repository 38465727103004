import {
  Component,
  OnInit,
  HostListener,
  AfterContentInit,
  Input
} from '@angular/core';
import { ConfigurationService, ModelItem } from '../../configuration.service';
import { ThreeService } from '../../three.service';
import { SerializationService } from '../../serialization.service';
import { AnimationService } from '../../animation.service';
import { ProgressService } from '../../progress.service';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit, AfterContentInit {
  @Input()
  public maxWidth = 810;

  public variantItems: ModelItem[];
  public small: boolean;
  public currentTabName: string;

  constructor(
    public configService: ConfigurationService,
    public threeService: ThreeService,
    public serializationService: SerializationService,
    public animationService: AnimationService,
    public progressService: ProgressService
  ) {
    configService.configurationChanged$.subscribe(() => {
      this.update();
    });
  }

  ngOnInit() {}

  ngAfterContentInit() {
    this.resize();
  }

  @HostListener('window:resize')
  public resize() {
    this.small = window.innerWidth <= this.maxWidth;
  }

  update() {
    this.variantItems = this.configService.items.filter(item => {
      return item.materialVariants && item.materialVariants.length > 0;
    });
  }

  tabChanged(tabName?: string) {
    this.currentTabName = tabName;
  }
}
