<div
  class="page"
  *ngFor="let slice of slices; let first = first; let last = last"
>
  <div *ngIf="configService.isReady && first">
    <img class="logo" src="{{'LOGO_URL' | translate}}" />
    <img
      #screenshot
      class="float-right"
      (load)="imageLoaded()"
      *ngIf="serializationService.screenshotUrl"
      [src]="serializationService.screenshotUrl"
    />
    <p class="emphasize">
      {{ 'Quotation date' | translate }}
      {{
        currentDate | date: 'shortDate':undefined:configService.language.code
      }}
    </p>
    <p></p>
  </div>

  <div class="footer">
    <div class="adress">
      <div>Alber GmbH</div>
      <div>Vor dem Weißen Stein 14</div>
      <div>72461 Albstadt</div>
      <div>Germany</div>
    </div>
    <div class="phone">
      <div>Telefon +49 7432 2006-0</div>
      <div>Telefax +49 7432 2006-299</div>
      <div>info@alber.de</div>
    </div>
    <div class="legal">
      <div>Amtsgericht Stuttgart HRB 401393</div>
      <div>Geschäftsführer: Joost Beltman, Kai Zhu</div>
      <div>USt-IdNr.: DE 195 954 239</div>
      <div>Steuer-Nummer: 91060/13499</div>
      <div>WEEE-Reg.-Nr.: DE 28190166</div>
    </div>
  </div>
  <table *ngIf="configService.isReady">
    <thead>
      <tr>
        <th></th>
        <th>{{ 'Description' | translate }}</th>
        <th>{{ 'Article Number' | translate }}</th>
        <th *ngIf="configService.language.showPrice">
          {{ 'Price' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let cartItem of slice">
        <tr>
          <td>
            <img class="item-image" [src]="cartItem.modelItem.imageUrl" />
          </td>
          <td>
            <div class="emphasize">
              {{ cartItem.modelItem.information.title
              }}<span *ngIf="cartItem.modelItem.currentVariant">
                - {{ cartItem.modelItem.currentVariant.information.name }}</span
              >
            </div>
            <pre>{{ cartItem.modelItem.information.description }}</pre>
          </td>
          <td>{{ cartItem.modelItem.productId }}</td>
          <td *ngIf="configService.language.showPrice">
            {{
              configService.getPrice(cartItem.modelItem)
                | currency: configService.language.currency
            }}
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="last && configService.language.showPrice">
        <tr>
          <td colspan="3" style="text-align: right">
            {{ 'Total' | translate }}
          </td>
          <td>
            {{
              configService.shoppingCartSum
                | currency: configService.language.currency
            }}
          </td>
        </tr>
        <tr style="page-break-before: avoid">
          <td colspan="3" style="text-align: right">
            {{ 'Including tax' | translate: configService.language }}
          </td>
          <td>
            {{
              configService.shoppingCartTax
                | currency: configService.language.currency
            }}
          </td>
        </tr>
        <tr style="page-break-before: avoid">
          <td
            colspan="4"
            class="disclaimer"
            [innerHTML]="'Check disclaimer' | translate: configService.language"
          ></td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!configService.language.showPrice">
        <tr style="page-break-before: avoid">
          <td colspan="3" style="text-align: right">
            {{ 'OBTAIN PRICE' | translate: configService.language }}
          </td>
        </tr>
        <tr style="page-break-before: avoid">
          <td
            colspan="4"
            class="disclaimer"
            [innerHTML]="'Check disclaimer' | translate: configService.language"
          ></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
