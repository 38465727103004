/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tabs.component";
import * as i4 from "../google-analytics.service";
var styles_TabsComponent = [i0.styles];
var RenderType_TabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsComponent, data: {} });
export { RenderType_TabsComponent as RenderType_TabsComponent };
function View_TabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TabsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TabsComponent_4)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.parent.context.$implicit === _co.activeTab)); var currVal_1 = _co.activeTab.contentTpl.templateRef; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TabsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"]))], null, null); }
function View_TabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getOutletContext(_v.context.$implicit, _v.context.index); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.titleTpl.templateRef); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.isSmall && (_v.context.$implicit === _co.activeTab)); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_v.context.index < (_co.items.length - 1)) && !_co.isSmall); _ck(_v, 6, 0, currVal_3); }, null); }
function View_TabsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TabsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_7)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.activeTab == null) ? null : _co.activeTab.contentTpl.templateRef); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[2, "flex-container", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; _ck(_v, 2, 0, currVal_1); var currVal_2 = (!_co.isSmall && (_co.activeTab !== undefined)); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSmall; _ck(_v, 0, 0, currVal_0); }); }
export function View_TabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-tabs", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).resize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TabsComponent_0, RenderType_TabsComponent)), i1.ɵdid(1, 1163264, null, 1, i3.TabsComponent, [i4.GoogleAnalyticsService], null, null), i1.ɵqud(603979776, 1, { items: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabsComponentNgFactory = i1.ɵccf("app-tabs", i3.TabsComponent, View_TabsComponent_Host_0, { maxWidth: "maxWidth", activeTitle: "activeTitle" }, { titleChange: "titleChange" }, []);
export { TabsComponentNgFactory as TabsComponentNgFactory };
