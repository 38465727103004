import { OnInit, QueryList, AfterContentInit, EventEmitter } from '@angular/core';
import { TabItemComponent } from './tab-item/tab-item.component';
import { GoogleAnalyticsService } from '../google-analytics.service';
var TabsComponent = /** @class */ (function () {
    function TabsComponent(ga) {
        this.ga = ga;
        this.maxWidth = 810;
        this.titleChange = new EventEmitter();
        this.isSmall = false;
    }
    TabsComponent.prototype.ngOnInit = function () { };
    TabsComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.resize();
        if ((!this.isSmall || this.activeTitle) && this.activeTab === undefined) {
            this.select(this.items.find(function (item, index) { return item.title === _this.activeTitle; }) ||
                this.items.first);
        }
    };
    TabsComponent.prototype.resize = function () {
        var _this = this;
        this.isSmall = window.innerWidth <= this.maxWidth;
        if (this.items.find(function (i) { return i === _this.activeTab; }) === undefined) {
            this.select(this.items.find(function (item, index) { return item.title === _this.activeTitle; }) ||
                this.items.first);
        }
    };
    TabsComponent.prototype.getOutletContext = function (item, index) {
        var _this = this;
        return {
            $implicit: item === this.activeTab,
            isSmall: this.isSmall,
            first: index === 0,
            last: index === this.items.length - 1,
            select: function () {
                _this.select(item);
            }
        };
    };
    TabsComponent.prototype.select = function (item) {
        var _this = this;
        if (this.isSmall && this.activeTab === item) {
            this.activeTab = undefined;
        }
        else {
            this.activeTab = item;
        }
        this.ga.setPage('/viewer/e-pilot/' + item.title);
        // Defer for later to avoid changes after view check errors
        setTimeout(function () {
            _this.titleChange.emit(_this.activeTab ? _this.activeTab.title : undefined);
        });
    };
    return TabsComponent;
}());
export { TabsComponent };
