import { OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ConfigurationService } from '../../../configuration.service';
var BasketBoxComponent = /** @class */ (function () {
    function BasketBoxComponent(configService) {
        this.configService = configService;
    }
    BasketBoxComponent.prototype.ngOnInit = function () {
    };
    BasketBoxComponent.prototype.ngOnChanges = function (changes) {
        this.amount = this.item.amount || 0;
    };
    BasketBoxComponent.prototype.remove = function () {
        this.configService.updateAmountForItem(this.item.modelItem, 0);
    };
    return BasketBoxComponent;
}());
export { BasketBoxComponent };
