import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService, ModelItem } from '../../configuration.service';
import { ClipboardService } from '../../clipboard.service';
import { SocialShareService } from '../../social-share.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';

@Component({
  selector: 'app-active-equipment',
  templateUrl: './active-equipment.component.html',
  styleUrls: ['./active-equipment.component.css']
})
export class ActiveEquipmentComponent implements OnInit {
  public activeItems: ModelItem[];

  @ViewChild('link')
  public inputField: ElementRef<HTMLInputElement>;

  constructor(
    public configService: ConfigurationService,
    public clipboard: ClipboardService,
    public social: SocialShareService,
    public ga: GoogleAnalyticsService
  ) {
    configService.configurationChanged$.subscribe(() => {
      this.update();
    });
  }

  ngOnInit() {
    this.update();
    this.clipboard.input = this.inputField;
  }

  update() {
    if (this.configService.items) {
      this.activeItems = this.configService.items.filter(
        item => item.visible && item.userSelectable
      );
    }
  }

  configToBasket() {
    this.ga.sendEvent('Configuration to basket', 'Page functions', undefined);

    this.activeItems.forEach(item => {
      this.configService.updateAmountForItem(item);
    });
  }

  resetConfig() {
    this.ga.sendEvent('Reset configuration', 'Page functions', undefined);

    this.configService.resetConfiguration();
    this.configService.clearShoppingCart();
  }
}
