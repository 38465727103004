var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../../configuration.service';
import { SerializationService } from '../../serialization.service';
import { BackendService } from '../../backend.service';
import { ThreeService } from '../../three.service';
var PrinterComponent = /** @class */ (function () {
    function PrinterComponent(route, configService, threeService, serializationService, backendService) {
        this.route = route;
        this.configService = configService;
        this.threeService = threeService;
        this.serializationService = serializationService;
        this.backendService = backendService;
        this.currentDate = new Date();
        this.mywindow = window;
        this.threeService.renderingDisabled = true;
    }
    PrinterComponent.prototype.getSlices = function () {
        var source = this.configService.shoppingCart.slice();
        var slices = [];
        slices.push(source.splice(0, 4).slice());
        while (source.length > 0) {
            slices.push(source.splice(0, 4).slice());
        }
        return slices;
    };
    PrinterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mywindow.renderingDone = false;
        this.route.paramMap.subscribe(function (paramMap) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(paramMap.has('product') && paramMap.has('uuid'))) return [3 /*break*/, 3];
                        this.productId = paramMap.get('product');
                        this.serializationId = paramMap.get('uuid');
                        return [4 /*yield*/, this.configService.load(this.productId, true)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.serializationService.deserialize(this.serializationId)];
                    case 2:
                        _a.sent();
                        this.slices = this.getSlices();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    PrinterComponent.prototype.imageLoaded = function () {
        window.renderingDone = true;
    };
    PrinterComponent.prototype.print = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pdf, link, blob, objectURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.backendService.printService.create({
                            productId: this.productId,
                            serializationId: this.serializationId
                        })];
                    case 1:
                        pdf = _a.sent();
                        link = document.createElement('a');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        blob = new Blob([pdf.pdf], { type: 'application/pdf' });
                        objectURL = URL.createObjectURL(blob);
                        link.href = objectURL;
                        link.href = URL.createObjectURL(blob);
                        link.download = 'data.pdf';
                        link.click();
                        return [2 /*return*/];
                }
            });
        });
    };
    return PrinterComponent;
}());
export { PrinterComponent };
