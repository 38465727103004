import { PipeTransform } from '@angular/core';
var ItemLanguagePipe = /** @class */ (function () {
    function ItemLanguagePipe() {
    }
    ItemLanguagePipe.prototype.transform = function (value, language) {
        if (value) {
            return value.filter(function (v) { return v.countryAvailability.includes(language.code); });
        }
        else {
            return null;
        }
    };
    return ItemLanguagePipe;
}());
export { ItemLanguagePipe };
