<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Product Code
    </div>
    <div *ngIf="showBody" class="card-body">
      <textarea [(ngModel)]="innerText" (ngModelChange)="check()">

      </textarea>
    </div>
    <div *ngIf="error" class="card-footer">
      {{error}}
    </div>
    <div *ngIf="error === undefined && showBody" class="card-footer">
      <button class="btn btn-primary" (click)="save()">Save</button>
      <button class="btn btn-primary ml-2" (click)="setInnerText()">Reload</button>
    </div>
  </div>
</div>
