import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReponsiveTabsModule } from './reponsive-tabs/reponsive-tabs.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localePt);
registerLocaleData(localeIt);
registerLocaleData(localeNl);

const appRoutes: Routes = [
  { path: 'viewer/:product', component: ShopComponent },
  { path: 'viewer/:product/:uuid', component: ShopComponent },
  { path: ':lang/viewer/:product', component: ShopComponent },
  { path: ':lang/viewer/:product/:uuid', component: ShopComponent },
  { path: 'printer/:product/:uuid', component: PrinterComponent },
  { path: 'viewer', component: ShopComponent },
  { path: 'admin', component: AdminComponent },
  { path: '', redirectTo: '/viewer/e-pilot', pathMatch: 'full' },
  { path: '**', redirectTo: '/viewer/e-pilot' }
];

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ViewerComponent } from './viewer/viewer.component';
import { AdminComponent } from './admin/admin.component';
import { ProductSelectComponent } from './admin/product-select/product-select.component';
import { ProductDetailComponent } from './admin/product-detail/product-detail.component';
import { ProductItemsComponent } from './admin/product-items/product-items.component';
import { ProductLanguagesComponent } from './admin/product-languages/product-languages.component';
import { ProductItemDetailComponent } from './admin/product-item-detail/product-item-detail.component';
import { ProductItemDependenciesComponent } from './admin/product-item-dependencies/product-item-dependencies.component';
import { ProductCoderComponent } from './admin/product-coder/product-coder.component';
import { ProductCamerasComponent } from './admin/product-cameras/product-cameras.component';
import { ProductMaterialsComponent } from './admin/product-materials/product-materials.component';
import { PrinterComponent } from './printer/printer/printer.component';
import { ShopComponent } from './shop/shop.component';
import { ActiveEquipmentComponent } from './shop/active-equipment/active-equipment.component';
import { SelectorComponent } from './shop/selector/selector.component';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { ChipsComponent } from './admin/chips/chips.component';
import { ProductAppearanceComponent } from './admin/product-appearance/product-appearance.component';
import { EquipmentBoxComponent } from './shop/selector/equipment-box/equipment-box.component';
import { ToggleButtonComponent } from './shop/toggle-button/toggle-button.component';
import { ImageDropComponent } from './admin/image-drop/image-drop.component';
import { IsUserSelectablePipe } from './shop/selector/is-user-selectable.pipe';
import { ScrollListComponent } from './shop/scroll-list/scroll-list.component';
import { DotsComponent } from './shop/scroll-list/dots/dots.component';
import { ProductAnimationComponent } from './admin/product-animation/product-animation.component';
import { BasketBoxComponent } from './shop/selector/basket-box/basket-box.component';
import { ShoppingBasketComponent } from './shop/selector/shopping-basket/shopping-basket.component';
import { DropdownComponent } from './shop/dropdown/dropdown.component';
import { ControlButtonsComponent } from './shop/control-buttons/control-buttons.component';
import { HoverImageDirective } from './shop/hover-image.directive';
import { SharingPopupComponent } from './shop/control-buttons/sharing-popup/sharing-popup.component';
import { ArticleOverviewComponent } from './shop/selector/article-overview/article-overview.component';
import { VisibleItemsPipe } from './visible-items.pipe';
import { ProductLanguageAvailabilityComponent } from './admin/product-language-availability/product-language-availability.component';
import { ProductCategoryComponent } from './admin/product-category/product-category.component';
import { ItemCategoryPipe } from './item-category.pipe';
import { ItemLanguagePipe } from './item-language.pipe';
import { IsActiveLanguagePipe } from './is-active-language.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    ViewerComponent,
    AdminComponent,
    ProductSelectComponent,
    ProductDetailComponent,
    ProductItemsComponent,
    ProductLanguagesComponent,
    ProductItemDetailComponent,
    ProductItemDependenciesComponent,
    ProductCoderComponent,
    ProductCamerasComponent,
    ProductMaterialsComponent,
    PrinterComponent,
    ShopComponent,
    ActiveEquipmentComponent,
    SelectorComponent,
    ConfiguratorComponent,
    ChipsComponent,
    ProductAppearanceComponent,
    EquipmentBoxComponent,
    ToggleButtonComponent,
    ImageDropComponent,
    IsUserSelectablePipe,
    ScrollListComponent,
    DotsComponent,
    ProductAnimationComponent,
    BasketBoxComponent,
    ShoppingBasketComponent,
    DropdownComponent,
    ControlButtonsComponent,
    HoverImageDirective,
    SharingPopupComponent,
    ArticleOverviewComponent,
    VisibleItemsPipe,
    ProductLanguageAvailabilityComponent,
    ProductCategoryComponent,
    ItemCategoryPipe,
    ItemLanguagePipe,
    IsActiveLanguagePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReponsiveTabsModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
