import { OnInit, AfterContentChecked, TemplateRef, QueryList } from '@angular/core';
var TabItemContent = /** @class */ (function () {
    function TabItemContent(templateRef) {
        this.templateRef = templateRef;
    }
    return TabItemContent;
}());
export { TabItemContent };
var TabItemTitle = /** @class */ (function () {
    function TabItemTitle(templateRef) {
        this.templateRef = templateRef;
    }
    return TabItemTitle;
}());
export { TabItemTitle };
var TabItemContext = /** @class */ (function () {
    function TabItemContext(active) {
        this.active = active;
    }
    return TabItemContext;
}());
export { TabItemContext };
var TabItemComponent = /** @class */ (function () {
    function TabItemComponent() {
    }
    TabItemComponent.prototype.ngOnInit = function () { };
    TabItemComponent.prototype.ngAfterContentChecked = function () {
        console.assert(this.titleTpls.length > 0, 'Title template marked ng-template[tabItemTitle] is mandatory');
        this.contentTpl = this.contentTpls ? this.contentTpls.first : undefined;
        this.titleTpl = this.titleTpls.first;
    };
    return TabItemComponent;
}());
export { TabItemComponent };
