import { ClipboardService } from './clipboard.service';
import { TranslateService } from '@ngx-translate/core';
import { SerializationService } from './serialization.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "./clipboard.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./serialization.service";
import * as i4 from "./google-analytics.service";
function facebookLink(url) {
    return "https://www.facebook.com/sharer.php?u=" + url;
}
function googlePlusLink(url, text, language_code) {
    return "https://plus.google.com/share?url=" + url + "&text=" + text + "&hl=" + language_code;
}
function pinterestLink(url, imageLink, text) {
    return "https://pinterest.com/pin/create/button/?url=" + url + "&media=" + imageLink + "&description=" + text;
}
function mailLink(subject, body) {
    return "mailto:?subject=" + subject + "&body=" + body;
}
function twitterLink(text) {
    return "https://twitter.com/home?status=" + text;
}
var SocialShareService = /** @class */ (function () {
    function SocialShareService(clipboard, translateService, serializationService, ga) {
        this.clipboard = clipboard;
        this.translateService = translateService;
        this.serializationService = serializationService;
        this.ga = ga;
    }
    SocialShareService.prototype.share = function (where) {
        this.ga.sendEvent('Share button clicked', 'Page functions', where);
        switch (where) {
            case 'link':
                this.clipboard.copyToClipboard(location.href, true);
                break;
            case 'facebook':
                window.open(facebookLink(location.href), '_blank');
                break;
            case 'googleplus':
                window.open(googlePlusLink(location.href, this.translateService.instant('sharing text'), undefined), '_blank');
                break;
            case 'pinterest':
                window.open(pinterestLink(location.href, location.origin + '/' + this.serializationService.screenshotUrl, this.translateService.instant('sharing text')), '_blank');
                break;
            case 'twitter':
                window.open(twitterLink(this.translateService.instant('tweet', { url: location.href })), '_blank');
                break;
            case 'mail':
                window.open(mailLink(this.translateService.instant('sharing mail subject'), this.translateService.instant('sharing mail body', {
                    url: location.href
                })), '_blank');
        }
        this.serializationService.lock();
    };
    SocialShareService.ngInjectableDef = i0.defineInjectable({ factory: function SocialShareService_Factory() { return new SocialShareService(i0.inject(i1.ClipboardService), i0.inject(i2.TranslateService), i0.inject(i3.SerializationService), i0.inject(i4.GoogleAnalyticsService)); }, token: SocialShareService, providedIn: "root" });
    return SocialShareService;
}());
export { SocialShareService };
