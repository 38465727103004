import { OnInit, ElementRef } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { ClipboardService } from '../../clipboard.service';
import { SocialShareService } from '../../social-share.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
var ActiveEquipmentComponent = /** @class */ (function () {
    function ActiveEquipmentComponent(configService, clipboard, social, ga) {
        var _this = this;
        this.configService = configService;
        this.clipboard = clipboard;
        this.social = social;
        this.ga = ga;
        configService.configurationChanged$.subscribe(function () {
            _this.update();
        });
    }
    ActiveEquipmentComponent.prototype.ngOnInit = function () {
        this.update();
        this.clipboard.input = this.inputField;
    };
    ActiveEquipmentComponent.prototype.update = function () {
        if (this.configService.items) {
            this.activeItems = this.configService.items.filter(function (item) { return item.visible && item.userSelectable; });
        }
    };
    ActiveEquipmentComponent.prototype.configToBasket = function () {
        var _this = this;
        this.ga.sendEvent('Configuration to basket', 'Page functions', undefined);
        this.activeItems.forEach(function (item) {
            _this.configService.updateAmountForItem(item);
        });
    };
    ActiveEquipmentComponent.prototype.resetConfig = function () {
        this.ga.sendEvent('Reset configuration', 'Page functions', undefined);
        this.configService.resetConfiguration();
        this.configService.clearShoppingCart();
    };
    return ActiveEquipmentComponent;
}());
export { ActiveEquipmentComponent };
