<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Item Configuration Step
    </div>
    <div *ngIf="showBody" class="card-body">
      <h3 *ngIf="!(configurationService.isReady)">Loading model file failed</h3>
      <ng-container *ngIf="configurationService.isReady">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Trivial Name</th>
              <th scope="col">Configuration Step</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of product.items; let i = index; let first = first; let last = last">
              <td>{{i+1}}</td>
              <td>
                {{item.trivialName}}
              </td>
              <td>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="item.category">
                  <label ngbButtonLabel class="btn-outline-secondary">
                    <input ngbButton type="radio" value="step1">Step 1
                  </label>
                  <label ngbButtonLabel class="btn-outline-secondary">
                    <input ngbButton type="radio" value="step2"> Step 2
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Removal</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Delete {{currentItem.trivialName}} ({{currentItem.productId}})?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="c('Save click')">Remove</button>
    <button type="button" class="btn btn-primary pull-right" (click)="d('Cancel')">Cancel</button>
  </div>
</ng-template>
