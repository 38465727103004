import { OnInit, EventEmitter } from '@angular/core';
import { Vector2 } from 'three';
var ToggleButtonComponent = /** @class */ (function () {
    function ToggleButtonComponent() {
        this.left = 'assets/icon/functions/day.svg';
        this.middle = 'assets/icon/functions/toggl-day.svg';
        this.right = 'assets/icon/functions/night.svg';
        this.state = false;
        this.stateChanged = new EventEmitter();
    }
    ToggleButtonComponent.prototype.ngOnInit = function () { };
    ToggleButtonComponent.prototype.touchStart = function (e) {
        console.log('touch start');
        this.startPosition = new Vector2(e.touches.item(0).clientX, e.touches.item(0).clientX);
    };
    ToggleButtonComponent.prototype.touchMove = function (e) {
        console.log('touch move');
        this.currentPosition = new Vector2(e.touches.item(0).clientX, e.touches.item(0).clientX);
        if (this.startPosition && this.startPosition.distanceTo(this.currentPosition) > 40) {
            this.toggleState();
            this.startPosition = undefined;
        }
    };
    ToggleButtonComponent.prototype.clicked = function () {
        console.log('click');
        this.toggleState();
    };
    ToggleButtonComponent.prototype.toggleState = function () {
        this.state = !this.state;
        this.stateChanged.next(this.state);
    };
    return ToggleButtonComponent;
}());
export { ToggleButtonComponent };
