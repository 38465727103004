import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../../configuration.service';
import { SerializationService } from '../../../serialization.service';
import { BackendService } from '../../../backend.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';

@Component({
  selector: 'app-shopping-basket',
  templateUrl: './shopping-basket.component.html',
  styleUrls: ['./shopping-basket.component.css']
})
export class ShoppingBasketComponent implements OnInit {
  public printing = false;
  faCircleNotch = faCircleNotch;

  constructor(
    public configService: ConfigurationService,
    public serializationService: SerializationService,
    private backendService: BackendService,
    private translateService: TranslateService,
    private ga: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  async print() {
    if (this.printing) {
      return;
    }
    this.ga.sendEvent('Generate Datasheet', 'Page functions', undefined);
    this.printing = true;
    try {
      let pdf = await this.backendService.printService.create({
        productId: this.configService.productId,
        serializationId: this.serializationService.serializationId
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);

      const blob = new Blob([pdf.pdf], { type: 'application/pdf' });
      const objectURL = URL.createObjectURL(blob);

      link.href = objectURL;
      link.href = URL.createObjectURL(blob);
      link.download = this.translateService.instant('DOWNLOAD_FILENAME');
      link.click();
    } catch (e) {
    } finally {
      this.printing = false;
    }
  }

  clearSelection() {
    this.ga.sendEvent('Clear Shopping Cart', 'Page functions', undefined);
    this.configService.clearShoppingCart();
  }

  openLink() {
    this.ga.sendEvent('Open Retailer Link', 'Page functions', undefined);
    window.open(this.translateService.instant('retailerLink'), '_blank');
  }
}
