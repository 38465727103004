import { ModelItem, Language, Perspective } from './configuration.service';

export class ConfigurationStatic {
  public static content = {
    _id: '5b39e306255de905afc06aac',
    availableLanguages: [
      {
        code: 'de',
        label: 'Deutsch',
        currency: 'EUR'
      },
      {
        code: 'pt',
        label: 'Brasil',
        currency: 'BRL'
      },
      {
        code: 'en',
        label: 'English',
        currency: 'GBP'
      }
    ],
    perspectives: [
      {
        name: 'Front Camera',
        cameraReference: 'Camera_1',
        duration: 1200,
        lockPosition: false,
        lockTarget: false,
        animation: true
      },
      {
        name: 'Front Camera (locked)',
        cameraReference: 'Camera_2',
        duration: 3000,
        lockPosition: false,
        lockTarget: false,
        animation: true
      }
    ],
    appearance: {
      envMapIntensity: 2.5,
      envMapIntensity_night: 0.3,
      envMapName: 'Bridge2',
      viewerBackground: 'white',
      viewerBackground_night: 'darkgrey',
      toneMappingExposure: 1.2
    },
    name: 'E-Pilot',
    url: 'epilot',
    published: false,
    items: [
      {
        productDependencies: [],
        viewDependencies: ['gepaecktraeger_TRANSF'],
        viewCollisions: ['taschenhalter_TRANSF'],
        mandatoryAlternatives: [],
        materialVariantsTarget: [],
        productId: '01',
        objectName: 'tasche_TRANSF',
        initialVisibility: false,
        countries: {
          de: {
            title: 'Tasche',
            price: 1
          },
          pt: {
            title: 'Bolsa',
            price: 30
          },
          en: {
            title: 'Bag',
            price: 12
          }
        },
        materialVariants: []
      },
      {
        productDependencies: [],
        viewDependencies: ['ePilot_TRANSF'],
        viewCollisions: [],
        mandatoryAlternatives: [],
        materialVariantsTarget: [],
        productId: '02',
        objectName: 'gepaecktraeger_TRANSF',
        initialVisibility: false,
        countries: {
          de: {
            title: 'Gepäckträger',
            price: 2
          },
          pt: {
            title: 'Suporte',
            price: 2
          }
        },
        materialVariants: []
      },
      {
        productDependencies: [],
        viewDependencies: ['gepaecktraeger_TRANSF'],
        viewCollisions: ['tasche_TRANSF'],
        mandatoryAlternatives: [],
        materialVariantsTarget: ['Suzanne'],
        productId: '03',
        objectName: 'taschenhalter_TRANSF',
        initialVisibility: false,
        countries: {
          de: {
            title: 'Taschenhalter',
            price: 3
          },
          pt: {
            title: 'Garupa',
            price: 1
          }
        },
        materialVariants: []
      },
      {
        productDependencies: [],
        viewDependencies: ['ePilot_TRANSF'],
        viewCollisions: ['supernova_TRANSF'],
        mandatoryAlternatives: ['supernova_TRANSF'],
        materialVariantsTarget: ['ePilot_TRANSF'],
        productId: '04',
        objectName: 'ePilot_REFlicht_standard',
        initialVisibility: true,
        materialVariants: [],
        countries: {
          de: {
            title: 'Licht (Standard)'
          },
          pt: {
            title: 'Luz',
            price: 12
          }
        }
      },
      {
        productDependencies: [],
        viewDependencies: [],
        viewCollisions: [],
        mandatoryAlternatives: [],
        materialVariantsTarget: [
          'ePilot_REFaufkleber1Shape',
          'ePilot_REFaufkleberShape',
          'ePilot_REFnPower_55_INSTALODShape'
        ],
        productId: '06',
        objectName: 'ePilot_TRANSF',
        initialVisibility: true,
        materialVariants: [
          {
            materialName: 'material_cube_blaze_orangeShape',
            countries: {
              de: {
                name: 'Orange',
                price: 0
              },
              pt: {
                name: 'Laranja',
                price: 0
              },
              en: {
                name: 'Orange',
                price: 0
              }
            }
          },
          {
            materialName: 'material_cube_fire_refShape',
            countries: {
              de: {
                name: 'Rot',
                price: 0
              },
              pt: {
                name: 'Vermelho',
                price: 0
              },
              en: {
                name: 'Fire',
                price: 0
              }
            }
          },
          {
            materialName: 'material_cube_light_blueShape',
            countries: {
              de: {
                name: 'Blau',
                price: 0
              },
              pt: {
                name: 'Azul claro',
                price: 0
              },
              en: {
                name: 'Light Blue',
                price: 0
              }
            }
          },
          {
            materialName: 'material_cube_lime_greenShape',
            countries: {
              de: {
                name: 'Grün',
                price: 0
              },
              pt: {
                name: 'Verde',
                price: 0
              },
              en: {
                name: 'Lime Green',
                price: 0
              }
            }
          }
        ],
        countries: {
          de: {
            title: 'ePilot',
            price: 3000
          },
          pt: {
            title: 'E-Pilot',
            price: 3000
          }
        }
      },
      {
        productDependencies: [],
        viewDependencies: ['gepaecktraeger_TRANSF'],
        viewCollisions: ['tasche_klein_TRANSF'],
        mandatoryAlternatives: [],
        materialVariantsTarget: [],
        productId: '05',
        objectName: 'flaschenhalter_TRANSF',
        initialVisibility: false,
        materialVariants: [],
        countries: {
          de: {
            title: 'Flaschenhalter',
            price: 12
          },
          pt: {
            title: 'Suporte garrafa',
            price: 55
          }
        }
      },
      {
        productDependencies: [],
        viewDependencies: [],
        viewCollisions: ['ePilot_REFlicht_standard'],
        mandatoryAlternatives: ['ePilot_REFlicht_standard'],
        materialVariantsTarget: [],
        productId: '07',
        objectName: 'supernova_TRANSF',
        initialVisibility: false,
        materialVariants: [],
        countries: {
          de: {
            title: 'Supernova'
          },
          pt: {
            title: 'Supernova',
            price: 300
          }
        }
      },
      {
        productDependencies: [],
        viewDependencies: ['gepaecktraeger_TRANSF'],
        viewCollisions: ['flaschenhalter_TRANSF'],
        mandatoryAlternatives: [],
        materialVariantsTarget: [],
        productId: '08',
        objectName: 'tasche_klein_TRANSF',
        initialVisibility: false,
        materialVariants: [],
        countries: {
          de: {
            title: 'Tasche (klein)',
            price: 15
          },
          pt: {
            title: 'Bolsa (pequena)',
            price: 23
          }
        }
      },
      {
        productDependencies: [],
        viewDependencies: [],
        viewCollisions: [],
        mandatoryAlternatives: [],
        materialVariantsTarget: [],
        productId: '09',
        objectName: 'Rueckspiegel_TRANSF',
        initialVisibility: true,
        materialVariants: [],
        countries: {
          de: {
            title: 'Rückspiegel',
            price: 123
          },
          pt: {
            title: 'Retrovisor',
            price: 12
          }
        }
      },
      {
        productDependencies: [],
        viewDependencies: [],
        viewCollisions: ['Rueckspiegel_TRANSF'],
        mandatoryAlternatives: [],
        materialVariantsTarget: [],
        productId: '10',
        objectName: 'warndreieck_TRANSF',
        initialVisibility: false,
        materialVariants: [],
        countries: {
          de: {
            title: 'Warndreieck',
            price: 140
          },
          pt: {
            title: 'Atenção',
            price: 500
          },
          en: {
            title: 'Warning',
            price: 60
          }
        }
      }
    ],
    createdAt: '2018-07-02T08:32:06.685Z',
    updatedAt: '2018-07-31T09:09:39.013Z',
    __v: 0,
    modelUrl: 'assets/ePilot_komplett_09/ePilot_komplett_09.gltf',
    screenshotCamera: {
      cameraReference: 'Camera_1',
      frustum: 150,
      height: 400,
      width: 400
    },
    initialPerspective: 'Front Camera',
    udpatedAt: '2018-07-31T09:09:39.013Z'
  };
}
