import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as TWEEN from '@tweenjs/tween.js';
import { AnimationService } from '../../animation.service';
import { BehaviorSubject } from 'rxjs';

const SCROLL_DURATION = 700;
const SCROLL_AMOUNT = 0.4;

@Component({
  selector: 'app-scroll-list',
  templateUrl: './scroll-list.component.html',
  styleUrls: ['./scroll-list.component.css']
})
export class ScrollListComponent implements OnInit, OnChanges {
  @ViewChild('longContent') private longContent: ElementRef<HTMLDivElement>;
  @ViewChild('contentContainer')
  private contentContainer: ElementRef<HTMLDivElement>;

  public dots: number;
  public activeDot = 0;

  private animationNeeded$;

  constructor(private animationService: AnimationService) {}

  ngOnInit() {
    setTimeout(() => {
      this.updateDots();
    }, 200);
    this.animationNeeded$ = new BehaviorSubject<boolean>(false);
    this.animationService.registerAnimationSource(this.animationNeeded$);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateDots();
  }

  @HostListener('window:resize')
  resize() {
    this.updateDots();
  }

  scroll(direction: string) {
    let smaller = this.longContent.nativeElement;
    // let bigger = this.contentContainer.nativeElement;
    let amount = (direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT) * smaller.clientWidth;
    this.executeScroll(smaller.scrollLeft + amount);
  }

  executeScroll(scrollLeft) {
    let smaller = this.longContent.nativeElement;
    let bigger = this.contentContainer.nativeElement;

    let maxScroll = (bigger.clientWidth - smaller.clientWidth);

    scrollLeft = Math.min(maxScroll, scrollLeft);
    scrollLeft = Math.max(scrollLeft, 0);

    let tween = new TWEEN.Tween(smaller).to({
      scrollLeft: scrollLeft
    }, SCROLL_DURATION);
    tween.easing(TWEEN.Easing.Quartic.InOut);

    tween.onStop(() => {
      this.animationNeeded$.next(false);
    });
    tween.onComplete(() => {
      this.animationNeeded$.next(false);
    });
    this.animationNeeded$.next(true);
    tween.start();
  }

  onScroll() {
    this.updateDots();
  }

  activeDotChange(dot) {
    let smaller = this.longContent.nativeElement;
    this.executeScroll(smaller.clientWidth * dot);
  }

  updateDots() {
    if (this.contentContainer && this.longContent) {
      let smaller = this.longContent.nativeElement;
      let bigger = this.contentContainer.nativeElement;

      setTimeout(() => {
        this.dots = Math.ceil(bigger.clientWidth / smaller.clientWidth);
        this.activeDot = Math.floor(smaller.scrollLeft / smaller.clientWidth);
        if (smaller.scrollLeft === bigger.clientWidth - smaller.clientWidth) {
          this.activeDot = this.dots - 1;
        }
      }, 100);
    }
  }
}
