/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chips.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chips.component";
var styles_ChipsComponent = [i0.styles];
var RenderType_ChipsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChipsComponent, data: {} });
export { RenderType_ChipsComponent as RenderType_ChipsComponent };
function View_ChipsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "badge badge-secondary mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "clickable ml-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ChipsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChipsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChipsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChipsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.list && (_co.list.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChipsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chips", [], null, null, null, View_ChipsComponent_0, RenderType_ChipsComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChipsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChipsComponentNgFactory = i1.ɵccf("app-chips", i3.ChipsComponent, View_ChipsComponent_Host_0, { list: "list" }, {}, []);
export { ChipsComponentNgFactory as ChipsComponentNgFactory };
