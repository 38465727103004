import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { Product } from '../../configuration.service';
import { ConfigurationService } from '../../configuration.service';
import { Subscription } from 'rxjs';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-product-cameras',
  templateUrl: './product-cameras.component.html',
  styleUrls: ['./product-cameras.component.css']
})
export class ProductCamerasComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public showBody = false;
  @Input() public product: Product;

  faTrashAlt = faTrashAlt;

  private subscription: Subscription;

  constructor(public configurationService: ConfigurationService) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.product && this.product.screenshotCamera === undefined) {
      this.product.screenshotCamera = {
        cameraReference: '',
        frustum: 5,
        height: 200,
        width: 300
      };
    }
  }

  create() {
    if (this.product.perspectives === undefined) {
      this.product.perspectives = [];
    }
    this.product.perspectives.push({
      name: 'Camera',
      cameraReference: 'Null',
      duration: 1200,
      lowerDistance: 100,
      upperDistance: 300,
      lockPosition: false,
      lockTarget: false,
      animation: true
    });
  }

  remove(index: number) {
    this.product.perspectives.splice(index, 1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
