import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
var ProductSelectComponent = /** @class */ (function () {
    function ProductSelectComponent() {
        this.currentProductChange = new EventEmitter();
        this.requestCreate = new EventEmitter();
        this.showBody = false;
    }
    ProductSelectComponent.prototype.ngOnInit = function () {
        this.title = 'No selected product';
    };
    ProductSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes['currentProduct']) {
            if (changes.currentProduct.currentValue) {
                this.title = this.currentProduct.name;
            }
        }
        if (changes['products']) {
            if (changes.products.currentValue) {
                this.checkForLastProject();
            }
        }
    };
    ProductSelectComponent.prototype.checkForLastProject = function () {
        var _this = this;
        var lastId = localStorage.getItem('product.select.id');
        if (lastId != null) {
            var lastProduct_1 = this.products.find(function (product) { return product._id === lastId; });
            setTimeout(function () {
                _this.currentProductChange.emit(lastProduct_1);
            }, 100);
        }
    };
    ProductSelectComponent.prototype.clickedProduct = function (product) {
        this.currentProductChange.next(product);
        this.showBody = false;
        localStorage.setItem('product.select.id', product._id);
    };
    ProductSelectComponent.prototype.create = function () {
        this.requestCreate.emit();
    };
    return ProductSelectComponent;
}());
export { ProductSelectComponent };
