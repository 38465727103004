/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-box.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./equipment-box.component";
import * as i4 from "../../../configuration.service";
var styles_EquipmentBoxComponent = [i0.styles];
var RenderType_EquipmentBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentBoxComponent, data: {} });
export { RenderType_EquipmentBoxComponent as RenderType_EquipmentBoxComponent };
function View_EquipmentBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/icon/buttons/arrow.svg"]], null, null, null, null, null))], null, null); }
function View_EquipmentBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "link"]], null, [[null, "dblclick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        _co.linkClicked.emit();
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentBoxComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.link; _ck(_v, 4, 0, currVal_1); }); }
function View_EquipmentBoxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }); }
export function View_EquipmentBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "img-container"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentBoxComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentBoxComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.info === undefined); _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.info; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addClass; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "url('", _co.imageUrl, "')"); _ck(_v, 2, 0, currVal_2); }); }
export function View_EquipmentBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-equipment-box", [], null, null, null, View_EquipmentBoxComponent_0, RenderType_EquipmentBoxComponent)), i1.ɵdid(1, 114688, null, 0, i3.EquipmentBoxComponent, [i4.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentBoxComponentNgFactory = i1.ɵccf("app-equipment-box", i3.EquipmentBoxComponent, View_EquipmentBoxComponent_Host_0, { title: "title", imageUrl: "imageUrl", link: "link", info: "info", addClass: "addClass" }, { linkClicked: "linkClicked" }, []);
export { EquipmentBoxComponentNgFactory as EquipmentBoxComponentNgFactory };
