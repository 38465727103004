<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Model Items
    </div>
    <div *ngIf="showBody" class="card-body">
      <h3 *ngIf="!(configurationService.isReady)">Loading model file failed</h3>
      <ng-container *ngIf="configurationService.isReady">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Product ID</th>
              <th scope="col">Model ID</th>
              <th scope="col">Trivial Name</th>
              <th scope="col">Options</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of product.items; let i = index; let first = first; let last = last">
              <td>{{i+1}}</td>
              <td>
                <input type="text" class="form-control" name="code" [(ngModel)]="item.productId">
              </td>
              <td>
                <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="item.objectName" [ngbTypeahead]="search" />
              </td>
              <td>
                <input type="text" class="form-control" name="trivialName" [(ngModel)]="item.trivialName"/>
              </td>
              <td>
                <fa-icon [icon]="faTrashAlt" (click)="remove(i)" class="clickable"></fa-icon>
                <fa-icon *ngIf="!first" [icon]="faCaretSquareUp" (click)="move(i, 'up')" class="clickable"></fa-icon>
                <fa-icon *ngIf="!last" [icon]="faCaretSquareDown" (click)="move(i, 'down')" class="clickable"></fa-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col">
            <button class="btn btn-primary btn-sm" (click)="create()">Create</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Removal</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Delete {{currentItem.trivialName}} ({{currentItem.productId}})?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="c('Save click')">Remove</button>
    <button type="button" class="btn btn-primary pull-right" (click)="d('Cancel')">Cancel</button>
  </div>
</ng-template>
