import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { Product } from '../../configuration.service';


@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.css']
})
export class ProductSelectComponent implements OnInit, OnChanges {
  @Input() public products: Product[];
  @Input() public currentProduct: Product;
  @Output() public currentProductChange = new EventEmitter<Product>();
  @Output() public requestCreate = new EventEmitter<void>();

  public showBody = false;

  public title: string;

  constructor() {}

  ngOnInit() {
    this.title = 'No selected product';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentProduct']) {
      if (changes.currentProduct.currentValue) {
        this.title = this.currentProduct.name;
      }
    }
    if (changes['products']) {
      if (changes.products.currentValue) {
        this.checkForLastProject();
      }
    }
  }

  checkForLastProject() {
    let lastId = localStorage.getItem('product.select.id');
    if (lastId != null) {
      let lastProduct = this.products.find(product => product._id === lastId);
      setTimeout(() => {
        this.currentProductChange.emit(lastProduct);
      }, 100);
    }
  }

  clickedProduct(product) {
    this.currentProductChange.next(product);
    this.showBody = false;

    localStorage.setItem('product.select.id', product._id);
  }

  create() {
    this.requestCreate.emit();
  }
}
