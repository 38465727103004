import { ElementRef } from '@angular/core';
import * as select from 'select';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var ClipboardService = /** @class */ (function () {
    function ClipboardService(translateService) {
        this.translateService = translateService;
    }
    ClipboardService.prototype.copyToClipboard = function (text, notify) {
        if (notify === void 0) { notify = false; }
        this.input.nativeElement.value = text;
        select(this.input.nativeElement);
        document.execCommand('copy');
        if (notify) {
            var msg = this.translateService.instant('Copied link');
            alert(msg);
        }
    };
    ClipboardService.ngInjectableDef = i0.defineInjectable({ factory: function ClipboardService_Factory() { return new ClipboardService(i0.inject(i1.TranslateService)); }, token: ClipboardService, providedIn: "root" });
    return ClipboardService;
}());
export { ClipboardService };
