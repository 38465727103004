import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';
import { BackendService } from '../../backend.service';
import { ConfigurationService } from '../../configuration.service';

@Component({
  selector: 'app-image-drop',
  templateUrl: './image-drop.component.html',
  styleUrls: ['./image-drop.component.css']
})
export class ImageDropComponent implements OnInit {
  @Input() public imageUrl: string;
  @Input() public context = 'stuff';
  @Output() public imageUrlChange = new EventEmitter<string>();
  @HostBinding('class.form-control') public isFormGroup = true;
  @HostBinding('class.active') public isActive;

  constructor(
    private backendService: BackendService,
    private configService: ConfigurationService
  ) {}

  @HostListener('drop', ['$event'])
  async dropHandler(event) {
    event.preventDefault();
    let filenames = await this.backendService.uploadFiles(
      event.dataTransfer.files,
      this.configService.url,
      this.context
    );
    this.imageUrlChange.next(filenames[0]);
    this.isActive = false;
  }

  @HostListener('dragover', ['$event'])
  dragOverHandler(event) {
    event.preventDefault();

    this.isActive = this.eventIsAccepted(event);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    event.preventDefault();
    this.isActive = false;
  }

  eventIsAccepted(event) {
    if (event.dataTransfer) {
      if (event.dataTransfer.items.length === 0) {
        return false;
      }
      let types = [];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        types.push(event.dataTransfer.items[i].type);
      }
      return true; // types.every(type => type === 'image/png' || type === 'image/jpeg');
    } else {
      return false;
    }
  }

  ngOnInit() {}
}
