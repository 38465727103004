<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Languages
    </div>
    <div *ngIf="showBody" class="card-body">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Code</th>
            <th scope="col">Label</th>
            <th scope="col">Short Label</th>
            <th scope="col">Currency</th>
            <th scope="col">VAT (%)</th>
            <th scope="col">Show Price</th>
            <th scope="col">Retailer Search</th>
            <th scope="col">Active</th>
            <th scope="col">Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let language of product.availableLanguages; let i = index">
            <td>{{i + 1}}</td>
            <td>
              <input type="text" class="form-control" name="code" [(ngModel)]="language.code">
            </td>
            <td>
              <input type="text" class="form-control" name="label" [(ngModel)]="language.label">
            </td>
            <td>
              <input type="text" class="form-control" name="shortLabel" [(ngModel)]="language.shortLabel">
            </td>
            <td>
              <input type="text" class="form-control" name="currency" [(ngModel)]="language.currency">
            </td>
            <td>
              <input type="text" class="form-control" name="vat" [(ngModel)]="language.vat">
            </td>
            <td>
              <input type="checkbox" class="form-control" name="showPrice" [(ngModel)]="language.showPrice">
            </td>
            <td>
              <input type="checkbox" class="form-control" name="showPrice" [(ngModel)]="language.showRetailerButton">
            </td>
            <td>
              <input type="checkbox" class="form-control" name="code" [(ngModel)]="language.active">
            </td>
            <td>
              <fa-icon [icon]="faTrashAlt" (click)="remove(i)" class="clickable"></fa-icon>
            </td>

          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <button class="btn btn-primary btn-sm" (click)="create()">Create</button>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Removal</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Delete {{currentLanguage?.label}}?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="c('remove click')">Remove</button>
    <button type="button" class="btn btn-primary pull-right" (click)="d('Cancel')">Cancel</button>
  </div>
</ng-template>
