import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { Product } from '../../configuration.service';

@Component({
  selector: 'app-product-coder',
  templateUrl: './product-coder.component.html',
  styleUrls: ['./product-coder.component.css']
})
export class ProductCoderComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Output() productChange = new EventEmitter<Product>();
  @Input() showBody = false;

  public innerText: string;
  public error: string;

  constructor() {}

  ngOnInit() {
    this.setInnerText();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInnerText();
  }

  setInnerText() {
    this.innerText = JSON.stringify(this.product, undefined, 2);
  }

  save() {
    this.productChange.next(JSON.parse(this.innerText) as Product);
  }

  check() {
    try {
      JSON.parse(this.innerText);
      this.error = undefined;
    } catch (e) {
      this.error = e.toString();
    }
  }
}
