import { Pipe, PipeTransform } from '@angular/core';
import { ModelItem } from '../../configuration.service';

@Pipe({
  name: 'isUserSelectable',
  pure: true
})
export class IsUserSelectablePipe implements PipeTransform {

  transform(value: ModelItem[], args?: any): ModelItem[] {
    if (value) {
      return value.filter(item => item.userSelectable);
    } else {
      return null;
    }
  }

}
