/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-category.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap/buttons/radio";
import * as i3 from "@angular/forms";
import * as i4 from "@ng-bootstrap/ng-bootstrap/buttons/label";
import * as i5 from "@angular/common";
import * as i6 from "./product-category.component";
import * as i7 from "../../configuration.service";
var styles_ProductCategoryComponent = [i0.styles];
var RenderType_ProductCategoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductCategoryComponent, data: {} });
export { RenderType_ProductCategoryComponent as RenderType_ProductCategoryComponent };
function View_ProductCategoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading model file failed"]))], null, null); }
function View_ProductCategoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 16, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "btn-group btn-group-toggle"], ["name", "radioBasic"], ["ngbRadioGroup", ""], ["role", "group"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.category = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.NgbRadioGroup, [], { name: [0, "name"] }, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.NgbRadioGroup]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "label", [["class", "btn-outline-secondary"], ["ngbButtonLabel", ""]], [[2, "btn", null], [2, "active", null], [2, "disabled", null], [2, "focus", null]], null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i4.NgbButtonLabel, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "input", [["ngbButton", ""], ["type", "radio"], ["value", "step1"]], [[8, "checked", 0], [8, "disabled", 0], [8, "name", 0]], [[null, "change"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onChange() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = ((i1.ɵnov(_v, 15).focused = true) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = ((i1.ɵnov(_v, 15).focused = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 147456, null, 0, i2.NgbRadio, [i2.NgbRadioGroup, i4.NgbButtonLabel, i1.Renderer2, i1.ElementRef], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["Step 1 "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "label", [["class", "btn-outline-secondary"], ["ngbButtonLabel", ""]], [[2, "btn", null], [2, "active", null], [2, "disabled", null], [2, "focus", null]], null, null, null, null)), i1.ɵdid(18, 16384, null, 0, i4.NgbButtonLabel, [], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "input", [["ngbButton", ""], ["type", "radio"], ["value", "step2"]], [[8, "checked", 0], [8, "disabled", 0], [8, "name", 0]], [[null, "change"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onChange() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = ((i1.ɵnov(_v, 20).focused = true) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = ((i1.ɵnov(_v, 20).focused = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 147456, null, 0, i2.NgbRadio, [i2.NgbRadioGroup, i4.NgbButtonLabel, i1.Renderer2, i1.ElementRef], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, [" Step 2 "]))], function (_ck, _v) { var currVal_9 = "radioBasic"; _ck(_v, 7, 0, currVal_9); var currVal_10 = "radioBasic"; var currVal_11 = _v.context.$implicit.category; _ck(_v, 9, 0, currVal_10, currVal_11); var currVal_19 = "step1"; _ck(_v, 15, 0, currVal_19); var currVal_27 = "step2"; _ck(_v, 20, 0, currVal_27); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.trivialName; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 11).ngClassValid; var currVal_7 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = true; var currVal_13 = i1.ɵnov(_v, 13).active; var currVal_14 = i1.ɵnov(_v, 13).disabled; var currVal_15 = i1.ɵnov(_v, 13).focused; _ck(_v, 12, 0, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = i1.ɵnov(_v, 15).checked; var currVal_17 = i1.ɵnov(_v, 15).disabled; var currVal_18 = i1.ɵnov(_v, 15).nameAttr; _ck(_v, 14, 0, currVal_16, currVal_17, currVal_18); var currVal_20 = true; var currVal_21 = i1.ɵnov(_v, 18).active; var currVal_22 = i1.ɵnov(_v, 18).disabled; var currVal_23 = i1.ɵnov(_v, 18).focused; _ck(_v, 17, 0, currVal_20, currVal_21, currVal_22, currVal_23); var currVal_24 = i1.ɵnov(_v, 20).checked; var currVal_25 = i1.ɵnov(_v, 20).disabled; var currVal_26 = i1.ɵnov(_v, 20).nameAttr; _ck(_v, 19, 0, currVal_24, currVal_25, currVal_26); }); }
function View_ProductCategoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "table", [["class", "table table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["#"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Trivial Name"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Configuration Step"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductCategoryComponent_4)), i1.ɵdid(12, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.items; _ck(_v, 12, 0, currVal_0); }, null); }
function View_ProductCategoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductCategoryComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductCategoryComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.configurationService.isReady; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.configurationService.isReady; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProductCategoryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Removal"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" Delete ", " (", ")? "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close("Save click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Remove"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss("Cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentItem.trivialName; var currVal_1 = _co.currentItem.productId; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
export function View_ProductCategoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "card-header clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showBody = !_co.showBody) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Item Configuration Step "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductCategoryComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_ProductCategoryComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBody; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ProductCategoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-category", [], null, null, null, View_ProductCategoryComponent_0, RenderType_ProductCategoryComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProductCategoryComponent, [i7.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductCategoryComponentNgFactory = i1.ɵccf("app-product-category", i6.ProductCategoryComponent, View_ProductCategoryComponent_Host_0, { showBody: "showBody", product: "product" }, {}, []);
export { ProductCategoryComponentNgFactory as ProductCategoryComponentNgFactory };
