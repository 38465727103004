var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConfigurationService } from './configuration.service';
import { ThreeService } from './three.service';
import { Vector3 } from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { BehaviorSubject, timer, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, debounce } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "./configuration.service";
import * as i2 from "./three.service";
import * as i3 from "./google-analytics.service";
var AnimationService = /** @class */ (function () {
    function AnimationService(configurationService, threeService, ga) {
        var _this = this;
        this.configurationService = configurationService;
        this.threeService = threeService;
        this.ga = ga;
        this._animationRunning = false;
        this.time = 0;
        this.animationDuration$ = new BehaviorSubject(1);
        this.animationPosition$ = new BehaviorSubject(0);
        this.animationInUse$ = new BehaviorSubject(false);
        this.cameraMoveInUse$ = new BehaviorSubject(false);
        this.animationSources = [];
        this.clock = new THREE.Clock();
        this.threeService.subscribe(function (event) {
            if (event.type === 'update') {
                _this.update();
            }
        });
    }
    AnimationService.prototype.registerAnimationSource = function (observable) {
        var _this = this;
        this.animationSources.push(observable);
        if (this.animationSourceSubscription) {
            this.animationSourceSubscription.unsubscribe();
        }
        this.animationSourceSubscription = combineLatest(this.animationSources)
            .pipe(map(function (v) {
            return v.some(function (a) { return a; });
        }), distinctUntilChanged(), debounce(function (v) { return (v ? timer(0) : timer(1000)); }))
            .subscribe(function (v) {
            _this.threeService.setRunning(v);
        });
    };
    AnimationService.prototype.init = function () {
        var _this = this;
        this.prepareAnimations();
        this.currentState = this.configurationService.animation.startState;
        if (this.currentState === 'end') {
            // this.mixer.time = this.duration;
            this.configurationService.animations.map(function (animation) { return _this.mixer.clipAction(animation); }).forEach(function (action) {
                action.time = _this.duration;
                action.play();
            });
        }
        if (this.configurationService.initialPerspective) {
            if (this.configurationService.initialPerspective.pointOfView === undefined) {
                var pos = new THREE.Object3D();
                var lookat = new THREE.Object3D();
                pos.position.set(1, 1, 1);
                lookat.position.set(0, 0, 0);
                this.configurationService.initialPerspective.pointOfView = {
                    name: this.configurationService.initialPerspective.cameraReference,
                    position: pos,
                    lookAt: lookat
                };
                // throw new Error('No initial perspective defined or found'
            }
            this.currentPerspective = this.configurationService
                .initialPerspective;
            this.setPointOfView(this.configurationService.initialPerspective
                .pointOfView);
            this.threeService.controls.minDistance = this.currentPerspective.lowerDistance;
            this.threeService.controls.maxDistance = this.currentPerspective.upperDistance;
        }
        this.threeService.controls.dampingFactor = 0.1;
        this.threeService.controls.rotateSpeed = 0.1;
        this.threeService.controls.enableDamping = true;
        var zooming$ = new BehaviorSubject(false);
        var zoomTimer;
        this.threeService.controls.addEventListener('change', function () {
            zooming$.next(true);
            if (zoomTimer) {
                clearTimeout(zoomTimer);
            }
            zoomTimer = setTimeout(function () {
                zooming$.next(false);
            }, 500);
        });
        this.threeService.nightModeShift$.subscribe(function (mode) {
            console.log("night shift mode " + mode);
        });
        [
            this.configurationService.configurationInUse$,
            this.animationInUse$,
            this.cameraMoveInUse$,
            this.threeService.nightModeShift$,
            zooming$
        ].forEach(function (animationSource) { return _this.registerAnimationSource(animationSource); });
        this.threeService.setRunning(true);
        this.threeService.setRunning(false);
    };
    Object.defineProperty(AnimationService.prototype, "animationRunning", {
        get: function () {
            return this._animationRunning;
        },
        set: function (value) {
            this._animationRunning = value;
            this.animationInUse$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    AnimationService.prototype.setPointOfView = function (camera) {
        this.threeService.scene.updateMatrixWorld(true);
        var camLookAt = camera.lookAt.getWorldPosition(new Vector3());
        var camPosition = camera.position.getWorldPosition(new Vector3());
        this.threeService.camera.position.set(camPosition.x, camPosition.y, camPosition.z);
        this.threeService.controls.target.set(camLookAt.x, camLookAt.y, camLookAt.z);
        // this.threeService.controls.update();
    };
    AnimationService.prototype.update = function () {
        TWEEN.update();
        if (this.animationRunning) {
            this.checkCameraLock();
        }
        var delta = this.clock.getDelta();
        if (this.mixer) {
            this.time += delta;
            this.mixer.time = this.time;
            this.mixer.update(delta);
            if (this.animationAction) {
                this.animationPosition$.next(this.animationAction.time);
            }
        }
        if (this.threeService.controls.enableDamping) {
            this.threeService.controls.update();
        }
    };
    AnimationService.prototype.checkCameraLock = function () {
        if (this.currentPerspective) {
            if (this.currentPerspective.lockTarget) {
                var target = new Vector3();
                this.currentPerspective.pointOfView.lookAt.getWorldPosition(target);
                this.threeService.controls.target = target;
            }
            if (this.currentPerspective.lockPosition) {
                var target = new Vector3();
                this.currentPerspective.pointOfView.position.getWorldPosition(target);
                Object.assign(this.threeService.camera.position, target);
            }
            // this.threeService.controls.update();
        }
    };
    AnimationService.prototype.changePerspective = function (perspective, changePosition, changeTarget) {
        if (changePosition === void 0) { changePosition = true; }
        if (changeTarget === void 0) { changeTarget = true; }
        return __awaiter(this, void 0, void 0, function () {
            var promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentPerspective = perspective;
                        promises = [];
                        if (changePosition &&
                            this.globalDistance(this.threeService.camera, perspective.pointOfView.position) >= 0.01) {
                            promises.push(this.getTween(this.threeService.camera.position, perspective.pointOfView.position.getWorldPosition(new Vector3()), perspective.duration));
                        }
                        if (changeTarget &&
                            this.globalDistance(this.threeService.controls.target, perspective.pointOfView.lookAt) >= 0.01) {
                            promises.push(this.getTween(this.threeService.controls.target, perspective.pointOfView.lookAt.getWorldPosition(new Vector3()), perspective.duration));
                        }
                        this.cameraMoveInUse$.next(true);
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        this.cameraMoveInUse$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    AnimationService.prototype.getTween = function (object, to, duration) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var tween = new TWEEN.Tween(object).to(to, duration);
            _this.threeService.controls.enableDamping = false;
            tween.easing(TWEEN.Easing.Quartic.InOut);
            tween.onUpdate(function (a) {
                // this.threeService.controls.update();
            });
            tween.onStart(function () {
                _this.threeService.controls.enableDamping = false;
                _this.threeService.controls.enabled = false;
            });
            tween.onComplete(function () {
                _this.threeService.controls.enableDamping = true;
                _this.threeService.controls.enabled = true;
                resolve();
            });
            tween.start();
        });
    };
    AnimationService.prototype.prepareCameraForAnimation = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.currentPerspective) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.changePerspective(this.currentPerspective, this.currentPerspective.lockPosition, this.currentPerspective.lockTarget)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AnimationService.prototype.togglePlay = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ga.sendEvent('Play Animation', 'Page functions', this.currentState === 'begin' ? 'Forward' : 'Backward');
                        this.clock.getDelta();
                        return [4 /*yield*/, this.prepareCameraForAnimation()];
                    case 1:
                        _a.sent();
                        this.prepareModelForAnimation();
                        this.animationRunning = true;
                        if (this.currentState === 'begin') {
                            this.mixer.timeScale = this.configurationService.animation.timeScale;
                            this.currentState = 'end';
                        }
                        else {
                            this.mixer.timeScale = -this.configurationService.animation.timeScale;
                            this.currentState = 'begin';
                        }
                        this.animations
                            .map(function (animation) { return _this.mixer.existingAction(animation); })
                            .forEach(function (action) {
                            action.play();
                            action.paused = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AnimationService.prototype.prepareModelForAnimation = function () {
        var _this = this;
        var dependencies = this.configurationService.items.filter(function (item) {
            return _this.configurationService.animation.dependencies.includes(item.objectName);
        });
        dependencies.forEach(function (dependency) { return _this.configurationService.changeVisibility(dependency, true); });
    };
    AnimationService.prototype.prepareAnimations = function () {
        var _this = this;
        this.mixer = new THREE.AnimationMixer(this.threeService.scene);
        this.mixer.timeScale = this.configurationService.animation.timeScale;
        this.animations = this.configurationService.animations || [];
        this.animations.forEach(function (animation) {
            var action = _this.mixer.clipAction(animation);
            _this.duration = animation.duration;
            action.clampWhenFinished = true;
            action.setLoop(THREE.LoopOnce, 1);
        });
        if (this.animations && this.animations.length > 0) {
            this.animationAction = this.mixer.existingAction(this.animations[0]);
        }
        this.mixer.addEventListener('finished', function () {
            _this.animationRunning = false;
        });
    };
    AnimationService.prototype.globalDistance = function (o1, o2) {
        var p1, p2;
        if (o1.isVector3) {
            p1 = o1;
        }
        else {
            p1 = o1.getWorldPosition(new Vector3());
        }
        if (o2.isVector3) {
            p2 = o2;
        }
        else {
            p2 = o2.getWorldPosition(new Vector3());
        }
        return p1.distanceTo(p2);
    };
    AnimationService.ngInjectableDef = i0.defineInjectable({ factory: function AnimationService_Factory() { return new AnimationService(i0.inject(i1.ConfigurationService), i0.inject(i2.ThreeService), i0.inject(i3.GoogleAnalyticsService)); }, token: AnimationService, providedIn: "root" });
    return AnimationService;
}());
export { AnimationService };
