<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Product Details
    </div>
    <div *ngIf="showBody" class="card-body">
      <form>
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="name">Product Name</label>
            <input [(ngModel)]="product.name" name="name" type="text" class="form-control" id="name" placeholder="Product Name">
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="url">URL (
              <a [href]="'viewer/' + product.url">{{'/viewer/' + product.url}}</a>)</label>
            <input [(ngModel)]="product.url" name="url" type="text" class="form-control" id="url" placeholder="product_name">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col">
            <label for="model_url">Model URL</label>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <fa-icon *ngIf="configurationService.isReady" [icon]="faCheck" class="input-group-text text-success"></fa-icon>
                <fa-icon *ngIf="!(configurationService.isReady)" [icon]="faTimes" class="input-group-text text-danger"></fa-icon>
              </div>
              <input [(ngModel)]="product.modelUrl" (keyup.enter)="modelUrlChanged.next(product.modelUrl)" name="modelUrl" type="text"
                class="form-control" id="model_url">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <div class="form-group">
              <label for="dropzone">Model files</label>
              <span [class.active]="acceptFile" (drop)="drop_handler($event)" (dragover)="dragover_handler($event)" (dragleave)="dragleave_handler($event)"
                type="text" id="dropzone" class="form-control" placeholder="Drop here">
                Drop Here
                </span>
            </div>
            <ngb-progressbar *ngIf="(backendService.progress | async) !== undefined" type="success" [value]="backendService.progress | async"></ngb-progressbar>
          </div>
          <div class="form-group col-6">
            <div class="form-check">
              <input class="form-check-input" [(ngModel)]="product.published" name="published" type="checkbox" id="published">
              <label class="form-check-label" for="published">Published</label>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col">
          <button class="btn btn-danger btn-sm float-right" (click)="remove()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Removal</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Delete {{product.name}}?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="c('Save click')">Remove</button>
    <button type="button" class="btn btn-primary pull-right" (click)="d('Cancel')">Cancel</button>
  </div>
</ng-template>
