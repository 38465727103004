<div class="heading semi-bold large-show">{{'Current Configuration' | translate}}</div>
<div class="table-container">
  <table>
    <tr *ngFor="let item of activeItems">
      <td>
        {{item.information.shortTitle}}
        <span *ngIf="item.currentVariant"> - {{item.currentVariant.information.name}}</span>
      </td>
      <td *ngIf="configService.language.showPrice">
        {{configService.getPrice(item) | currency:configService.language.currency}}
      </td>
    </tr>
  </table>
</div>
<div class="active-links">
  <div class="to-basket clickable" (click)="configToBasket()">{{'Configuration into basket' | translate}}</div>
  <div class="to-basket clickable" (click)="resetConfig()">{{'Reset configuration' | translate}}</div>
  <div class="to-basket clickable" (click)="this.social.share('link')">{{'Copy configuration link' | translate}}</div>
</div>
<div class="tax-included" *ngIf="activeItems && activeItems.length > 0 && configService.language.showPrice">{{'All prices with tax' |
  translate:configService.language}}</div>
<input #link class="hidden-link">
