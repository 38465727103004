var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NgZone, EventEmitter } from '@angular/core';
import 'imports-loader?THREE=three!three/examples/js/controls/OrbitControls';
import 'imports-loader?THREE=three!three/examples/js/pmrem/PMREMCubeUVPacker';
import 'imports-loader?THREE=three!three/examples/js/pmrem/PMREMGenerator';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { HttpClient } from '../../node_modules/@angular/common/http';
import * as TWEEN from '@tweenjs/tween.js';
import { BehaviorSubject } from '../../node_modules/rxjs';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./google-analytics.service";
var myScreen = screen;
var ThreeService = /** @class */ (function (_super) {
    __extends(ThreeService, _super);
    function ThreeService(zone, http, ga) {
        var _this = _super.call(this) || this;
        _this.zone = zone;
        _this.http = http;
        _this.ga = ga;
        _this.window = window;
        _this.nightModeShift$ = new BehaviorSubject(false);
        _this.envMapIntensity = 0;
        _this.envMapIntensity_night = 0;
        _this.currentEnvMapIntensity = 0;
        _this.isReady = false;
        _this.renderingDisabled = false;
        _this.nightMode = false;
        _this.materials = new Set();
        window.threeService = _this;
        _this.registerFullscreenChanges();
        _this.camera = new THREE.PerspectiveCamera(45);
        _this.scene = new THREE.Scene();
        _this.scene.addEventListener('added', function (event) {
            console.log(event);
        });
        _this.http.get('assets/envmap/envmaps.json').subscribe(function (data) {
            _this.envmaps = data.envmaps;
            _this.envmap =
                _this.envmaps.find(function (map) { return map.name === data.default; }) || _this.envmaps[0];
        });
        _this.rendererPromise = new Promise(function (resolve) {
            _this.resolveRenderer = resolve;
        });
        _this.window.scene = _this.scene;
        return _this;
    }
    ThreeService.prototype.fullscreen = function () {
        var domElement = this.renderer.domElement.parentElement;
        if ('requestFullscreen' in domElement) {
            domElement.requestFullscreen();
        }
        else if ('webkitRequestFullscreen' in domElement) {
            domElement.webkitRequestFullscreen();
        }
        else if ('mozRequestFullScreen' in domElement) {
            domElement.mozRequestFullScreen();
        }
        else if ('msRequestFullscreen' in domElement) {
            domElement.msRequestFullscreen();
        }
        this.controls.autoRotateSpeed = 0.4;
    };
    ThreeService.prototype.registerFullscreenChanges = function () {
        var _this = this;
        var handler = function () {
            var isFullscreen = (window.fullScreen) ||
                (window.innerWidth === screen.width && window.innerHeight === screen.height);
            _this.controls.autoRotate = isFullscreen;
            _this.ga.sendEvent('Fullscreen', 'Page functions', isFullscreen ? 'Active' : 'Inactive');
            _this.controls.dispatchEvent({ target: _this, type: 'change' });
        };
        document.onfullscreenchange = handler;
        document.onwebkitfullscreenchange = handler;
        document.onmozfullscreenchange = handler;
    };
    ThreeService.prototype.clearScene = function () {
        this.scene.children = [];
    };
    ThreeService.prototype.addToScene = function (object) {
        this.scene.add(object);
        this.applyEnvmap();
        this.createMaterialList();
    };
    ThreeService.prototype.createMaterialList = function () {
        var _this = this;
        this.materials.clear();
        this.scene.traverse(function (child) {
            if ('material' in child) {
                _this.materials.add(child.material);
            }
        });
    };
    Object.defineProperty(ThreeService.prototype, "domElement", {
        set: function (value) {
            var _this = this;
            this._canvasContainer = value;
            this.renderer = new THREE.WebGLRenderer({
                antialias: true,
                alpha: true
            });
            this.resolveRenderer(this.renderer);
            this.window.renderer = this.renderer;
            this.window.fixLight = function () {
                var material = _this.scene.getObjectByName('light_planeShape')
                    .material;
                material.alphaMap = material.emissiveMap;
                material.emissiveMap = null;
                material.emissive = new THREE.Color('white');
                material.needsUpdate = true;
            };
            while (value.firstChild) {
                value.removeChild(value.firstChild);
            }
            value.appendChild(this.renderer.domElement);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.gammaOutput = true;
            if (this.controls) {
                this.controls.dispose();
            }
            this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
            this.controls.addEventListener('end', function () {
                var isFullscreen = (window.fullScreen) ||
                    (window.innerWidth === screen.width && window.innerHeight === screen.height);
                if (!isFullscreen) {
                    _this.controls.autoRotate = false;
                }
            });
            this.controls.maxPolarAngle = 1.746;
            this.controls.autoRotateSpeed = 0.4;
            this.controls.autoRotate = true;
            this.controls.enablePan = false;
            this.resize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThreeService.prototype, "screenshotCamera", {
        set: function (screenshotCamera) {
            this._screenshotSetting = screenshotCamera;
            var aspect = screenshotCamera.width / screenshotCamera.height;
            this._screenshotCamera = new THREE.OrthographicCamera((screenshotCamera.frustum * aspect) / -2, (screenshotCamera.frustum * aspect) / 2, screenshotCamera.frustum / 2, screenshotCamera.frustum / -2, 0.25, 2000);
            if (screenshotCamera.pointOfView) {
                this.scene.updateMatrixWorld(true);
                var pos = screenshotCamera.pointOfView.position.getWorldPosition(new Vector3());
                var lookAt = screenshotCamera.pointOfView.lookAt.getWorldPosition(new Vector3());
                this._screenshotCamera.position.copy(pos);
                this._screenshotCamera.lookAt(lookAt);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThreeService.prototype, "envmap", {
        get: function () {
            return this._envmap;
        },
        set: function (value) {
            if (this._envmap === value) {
                return;
            }
            this._envmap = value;
            this.updateEnvmap(value, true);
        },
        enumerable: true,
        configurable: true
    });
    ThreeService.prototype.screenshot = function () {
        var _this = this;
        if (this._screenshotCamera === undefined) {
            console.warn('No screenshot camera defined');
            return;
        }
        var renderTarget = new THREE.WebGLRenderTarget(this._screenshotSetting.width, this._screenshotSetting.height, {
            minFilter: THREE.LinearMipMapLinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat
        });
        // Take screenshot at daytime
        this.materials.forEach(function (material) {
            material.envMapIntensity = _this.envMapIntensity;
        });
        // Make objects invisible that shouldn't be there
        var previousVisiblityState = this.screenshotUnwantedObjectNames.map(function (name) {
            var object = _this.scene.getObjectByName(name);
            var previousState = object.visible;
            _this.scene.getObjectByName(name).visible = false;
            return {
                object: object,
                previousState: previousState
            };
        });
        this.renderer.setClearColor(new THREE.Color('white'), 1.0);
        this.renderer.render(this.scene, this._screenshotCamera, renderTarget, true);
        this.renderer.setClearColor(new THREE.Color('white'), 0.0);
        previousVisiblityState.forEach(function (state) {
            state.object.visible = state.previousState;
        });
        this.materials.forEach(function (material) {
            material.envMapIntensity = _this.nightMode
                ? _this.envMapIntensity_night
                : _this.envMapIntensity;
        });
        var pixelBuffer = new Uint8Array(renderTarget.width * renderTarget.height * 4);
        this.renderer.readRenderTargetPixels(renderTarget, 0, 0, renderTarget.width, renderTarget.height, pixelBuffer);
        var clampedArray = new Uint8ClampedArray(pixelBuffer);
        var DAT = new ImageData(clampedArray, renderTarget.width, renderTarget.height);
        var mycanvas = document.createElement('canvas');
        mycanvas.width = renderTarget.width;
        mycanvas.height = renderTarget.height;
        var ctx = mycanvas.getContext('2d');
        ctx.putImageData(DAT, 0, 0);
        ctx.scale(1, -1);
        // ctx.translate(mycanvas.height, 0);
        // ctx.scale(1, -1);
        return mycanvas;
    };
    ThreeService.prototype.resize = function () {
        if (this.camera === undefined ||
            this.renderer === undefined ||
            this.renderer.domElement === undefined) {
            return;
        }
        this.camera.aspect =
            this._canvasContainer.clientWidth / this._canvasContainer.clientHeight;
        this.camera.updateProjectionMatrix();
        this.camera.near = 0.25;
        this.camera.far = 2000;
        this.renderer.setSize(this._canvasContainer.clientWidth, this._canvasContainer.clientHeight);
        if (!this._rendering) {
            this.setRunning(true);
            this.setRunning(false);
        }
    };
    ThreeService.prototype.setRunning = function (v) {
        var _this = this;
        if (v === this._rendering) {
            return;
        }
        this._rendering = v;
        if (this._rendering) {
            this.zone.runOutsideAngular(function () {
                _this.runloop();
            });
        }
    };
    ThreeService.prototype.setEnvMapIntensity = function () { };
    ThreeService.prototype.setNightMode = function (value) {
        var _this = this;
        this.currentEnvMapIntensity = value
            ? this.envMapIntensity_night
            : this.envMapIntensity;
        if (this.nightMode === value) {
            return;
        }
        this.nightMode = value;
        this.ga.sendEvent('Night Mode', 'Page functions', value ? 'Active' : 'Inactive');
        var tweens = Array.from(this.materials).map(function (mat) {
            return new TWEEN.Tween(mat).to({ envMapIntensity: _this.currentEnvMapIntensity }, 1000);
        });
        if (tweens.length > 0) {
            tweens[0].onComplete(function () {
                _this.nightModeShift$.next(false);
            });
        }
        this.nightModeShift$.next(true);
        tweens.forEach(function (tween) { return tween.start(); });
    };
    ThreeService.prototype.runloop = function () {
        var _this = this;
        this.zone.run(function () {
            _this.emit({ type: 'update' });
        });
        this.scene.updateMatrixWorld(true);
        this.renderer.render(this.scene, this.camera);
        if (this._rendering) {
            requestAnimationFrame(function () {
                _this.runloop();
            });
        }
    };
    ThreeService.prototype.updateEnvmap = function (envmap, pmrem) {
        if (pmrem === void 0) { pmrem = true; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.renderingDisabled === false)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.rendererPromise];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, new Promise(function (resolve) {
                            new THREE.CubeTextureLoader().load(envmap.images, function (ldrCubeMap) {
                                if (_this.renderer === undefined || pmrem === false) {
                                    console.log('returnd ldr cubemap');
                                    ldrCubeMap.generateMipmaps = true;
                                    _this.envmapTexture = ldrCubeMap;
                                    resolve(ldrCubeMap);
                                    return;
                                }
                                // ldrCubeMap.encoding = THREE.GammaEncoding;
                                // ldrCubeMap.flipY = true;
                                var t1 = performance.now();
                                var pmremGenerator = new THREE.PMREMGenerator(ldrCubeMap);
                                pmremGenerator.update(_this.renderer);
                                // pmremGenerator.update(this._screenshotRenderer);
                                var pmremCubeUVPacker = new THREE.PMREMCubeUVPacker(pmremGenerator.cubeLods);
                                pmremCubeUVPacker.update(_this.renderer);
                                // pmremCubeUVPacker.update(this._screenshotRenderer);
                                var ldrCubeRenderTarget = pmremCubeUVPacker.CubeUVRenderTarget;
                                ldrCubeMap.dispose();
                                pmremGenerator.dispose();
                                pmremCubeUVPacker.dispose();
                                var t2 = performance.now();
                                console.log('pmrem generation time', t2 - t1);
                                _this.envmapTexture = ldrCubeRenderTarget.texture;
                                resolve();
                            });
                        })];
                    case 3:
                        _a.sent();
                        this.applyEnvmap();
                        this.isReady = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ThreeService.prototype.applyEnvmap = function () {
        var _this = this;
        this.scene.traverse(function (child) {
            if ('geometry' in child) {
                child.geometry.computeVertexNormals();
            }
            if ('material' in child && !Array.isArray(child.material)) {
                if ('envMap' in child.material) {
                    child.material.envMap = _this.envmapTexture;
                    child.material.envMapIntensity = _this.currentEnvMapIntensity;
                    child.material.needsUpdate = true;
                }
            }
            else if (Array.isArray(child.material)) {
                child.material.forEach(function (material) {
                    material.envMap = _this.envmapTexture;
                    material.envMapIntensity = _this.currentEnvMapIntensity;
                    material.needsUpdate = true;
                });
            }
        });
    };
    ThreeService.ngInjectableDef = i0.defineInjectable({ factory: function ThreeService_Factory() { return new ThreeService(i0.inject(i0.NgZone), i0.inject(i1.HttpClient), i0.inject(i2.GoogleAnalyticsService)); }, token: ThreeService, providedIn: "root" });
    return ThreeService;
}(EventEmitter));
export { ThreeService };
