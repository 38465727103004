import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Routes } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localePt);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
var appRoutes = [
    { path: 'viewer/:product', component: ShopComponent },
    { path: 'viewer/:product/:uuid', component: ShopComponent },
    { path: ':lang/viewer/:product', component: ShopComponent },
    { path: ':lang/viewer/:product/:uuid', component: ShopComponent },
    { path: 'printer/:product/:uuid', component: PrinterComponent },
    { path: 'viewer', component: ShopComponent },
    { path: 'admin', component: AdminComponent },
    { path: '', redirectTo: '/viewer/e-pilot', pathMatch: 'full' },
    { path: '**', redirectTo: '/viewer/e-pilot' }
];
import { HttpClient } from '@angular/common/http';
import { AdminComponent } from './admin/admin.component';
import { PrinterComponent } from './printer/printer/printer.component';
import { ShopComponent } from './shop/shop.component';
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
