import * as i0 from "@angular/core";
var ProgressService = /** @class */ (function () {
    function ProgressService() {
        this.active = false;
        this._progress = 0;
    }
    Object.defineProperty(ProgressService.prototype, "progress", {
        get: function () {
            return this._progress;
        },
        set: function (value) {
            var _this = this;
            this._progress = value;
            if (this._progress > 0) {
                this.active = true;
            }
            if (this._progress > 0.99) {
                setTimeout(function () {
                    _this.active = false;
                }, 2000);
            }
        },
        enumerable: true,
        configurable: true
    });
    ProgressService.ngInjectableDef = i0.defineInjectable({ factory: function ProgressService_Factory() { return new ProgressService(); }, token: ProgressService, providedIn: "root" });
    return ProgressService;
}());
export { ProgressService };
