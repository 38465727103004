import { Component, OnInit, Input } from '@angular/core';
import { ModelItem, Product } from '../../configuration.service';

@Component({
  selector: 'app-product-item-dependencies',
  templateUrl: './product-item-dependencies.component.html',
  styleUrls: ['./product-item-dependencies.component.css']
})
export class ProductItemDependenciesComponent implements OnInit {

  @Input() showBody = false;
  @Input() product: Product;

  public currentItem: ModelItem;

  constructor() { }

  ngOnInit() {
    // this.currentItem = this.product.items[0];
  }

}
