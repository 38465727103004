import { OnInit, EventEmitter } from '@angular/core';
import { ConfigurationService } from '../../../configuration.service';
var EquipmentBoxComponent = /** @class */ (function () {
    function EquipmentBoxComponent(configService) {
        this.configService = configService;
        this.linkClicked = new EventEmitter();
    }
    EquipmentBoxComponent.prototype.ngOnInit = function () {
    };
    return EquipmentBoxComponent;
}());
export { EquipmentBoxComponent };
