import { OnInit, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
var ProductLanguagesComponent = /** @class */ (function () {
    function ProductLanguagesComponent(modalService) {
        this.modalService = modalService;
        this.showBody = false;
        this.faTrashAlt = faTrashAlt;
    }
    ProductLanguagesComponent.prototype.ngOnInit = function () {
        if (this.product.availableLanguages === undefined) {
            this.product.availableLanguages = [];
        }
    };
    ProductLanguagesComponent.prototype.create = function () {
        this.product.availableLanguages.push({
            code: 'de',
            label: 'Deutsch',
            shortLabel: 'DE',
            currency: 'EUR',
            showPrice: false,
            showRetailerButton: false,
            vat: 19,
            active: false
        });
    };
    ProductLanguagesComponent.prototype.remove = function (index) {
        var _this = this;
        this.currentLanguage = this.product.availableLanguages[index];
        this.open(this.content)
            .then(function () {
            _this.product.availableLanguages.splice(index, 1);
        })
            .catch(function () {
            // nothing
        });
    };
    ProductLanguagesComponent.prototype.open = function (content) {
        return this.modalService.open(content).result;
    };
    return ProductLanguagesComponent;
}());
export { ProductLanguagesComponent };
