import { Pipe, PipeTransform } from '@angular/core';
import { Language, ModelItem } from './configuration.service';

@Pipe({
  name: 'itemLanguage'
})
export class ItemLanguagePipe implements PipeTransform {

  transform(value: ModelItem[], language?: Language): any {
    if (value) {
      return value.filter(v => v.countryAvailability.includes(language.code));
    } else {
      return null;
    }
  }

}
