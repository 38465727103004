import { Injectable, ElementRef } from '@angular/core';
import * as select from 'select';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  public input: ElementRef<HTMLInputElement>;

  constructor(private translateService: TranslateService) { }

  copyToClipboard(text: string, notify = false) {
    this.input.nativeElement.value = text;
    select(this.input.nativeElement);
    document.execCommand('copy');

    if (notify) {
      let msg = this.translateService.instant('Copied link');
      alert(msg);
    }
  }
}
