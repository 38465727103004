import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from '../../../configuration.service';

@Component({
  selector: 'app-article-overview',
  templateUrl: './article-overview.component.html',
  styleUrls: ['./article-overview.component.css']
})
export class ArticleOverviewComponent implements OnInit {

  @Input()
  heading: string;

  @Input()
  category: string;

  constructor(public configService: ConfigurationService) {}

  ngOnInit() {
  }

}
