var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ConfigurationService } from '../configuration.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { ThreeService } from '../three.service';
export var SaveState;
(function (SaveState) {
    SaveState[SaveState["Pristine"] = 0] = "Pristine";
    SaveState[SaveState["Saving"] = 1] = "Saving";
    SaveState[SaveState["Changed"] = 2] = "Changed";
})(SaveState || (SaveState = {}));
var CHANGE_CHECK_TIME_MS = 500;
var AdminComponent = /** @class */ (function () {
    function AdminComponent(backend, modalService, configurationService, threeService, router) {
        this.backend = backend;
        this.modalService = modalService;
        this.configurationService = configurationService;
        this.threeService = threeService;
        this.router = router;
        this.credentials = {
            email: '',
            password: ''
        };
        this.faCircleNotch = faCircleNotch;
        this.SaveStates = SaveState;
        this.pane = 'products';
        threeService.renderingDisabled = true;
    }
    AdminComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.saveState = SaveState.Pristine;
                        return [4 /*yield*/, this.loginLoop()];
                    case 1:
                        _a.sent();
                        this.load();
                        setInterval(function () {
                            _this.checkSaveState();
                        }, CHANGE_CHECK_TIME_MS);
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminComponent.prototype.createProductCopy = function () {
        this.currentProductCopy = _.cloneDeep(this.currentProduct);
    };
    AdminComponent.prototype.checkSaveState = function () {
        if (!_.isEqual(this.currentProduct, this.currentProductCopy)) {
            this.saveState = SaveState.Changed;
        }
        else {
            this.saveState = SaveState.Pristine;
        }
    };
    AdminComponent.prototype.loginLoop = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, dismissReason;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.backend.loggedIn) return [3 /*break*/, 11];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 10]);
                        if (!(this.credentials.email === '')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.backend.login()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.backend.login(this.credentials)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 10];
                    case 6:
                        e_1 = _a.sent();
                        console.error(e_1);
                        dismissReason = void 0;
                        _a.label = 7;
                    case 7:
                        if (!(dismissReason !== 'login')) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.open(this.loginContent)];
                    case 8:
                        dismissReason = _a.sent();
                        return [3 /*break*/, 7];
                    case 9: return [3 /*break*/, 10];
                    case 10: return [3 /*break*/, 0];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    AdminComponent.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.backend.logout().then(function () {
                            _this.router.navigateByUrl('/viewer');
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminComponent.prototype.productChanged = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!product) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.save(product)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.createProductCopy();
                        this.updateModelUrl();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminComponent.prototype.updateModelUrl = function () {
        if (this.currentProduct) {
            this.configurationService
                ._dev_load(this.currentProduct.modelUrl)
                .catch(function (err) {
                console.error('loading model failed', err);
            });
        }
    };
    AdminComponent.prototype.open = function (content) {
        return this.modalService
            .open(content)
            .result.then(function () {
            return Promise.resolve('login');
        })
            .catch(function () {
            return Promise.resolve('cancel');
        });
    };
    AdminComponent.prototype.load = function () {
        var _this = this;
        return this.backend.productService
            .find({
            query: {
                $sort: 'updatedAt'
            }
        })
            .then(function (productResponse) {
            _this.products = productResponse.data;
            _this.currentProduct = undefined;
        });
    };
    AdminComponent.prototype.create = function () {
        var _this = this;
        this.backend.productService
            .create({
            name: 'Product',
            url: 'product',
            published: false
        })
            .then(function () {
            return _this.load();
        })
            .catch(function (e) {
            alert('Product not created. ' + e);
        });
    };
    AdminComponent.prototype.save = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.saveState = SaveState.Saving;
                        return [4 /*yield*/, this.backend.productService
                                .update(product._id, product)
                                .then(function (updatedProduct) {
                                var foundProduct = _this.products.find(function (thisProduct) { return thisProduct._id === updatedProduct._id; });
                                if (foundProduct) {
                                    // Object.assign(foundProduct, updatedProduct);
                                    _this.currentProduct = updatedProduct;
                                    _this.createProductCopy();
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminComponent.prototype.delete = function (product) {
        var _this = this;
        return this.backend.productService.remove(product._id).then(function () {
            _this.load();
        });
    };
    return AdminComponent;
}());
export { AdminComponent };
