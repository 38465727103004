/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tab-item.component";
var styles_TabItemComponent = [i0.styles];
var RenderType_TabItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabItemComponent, data: {} });
export { RenderType_TabItemComponent as RenderType_TabItemComponent };
export function View_TabItemComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_TabItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-tab-item", [], null, null, null, View_TabItemComponent_0, RenderType_TabItemComponent)), i1.ɵdid(1, 2211840, null, 2, i2.TabItemComponent, [], null, null), i1.ɵqud(603979776, 1, { titleTpls: 1 }), i1.ɵqud(603979776, 2, { contentTpls: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabItemComponentNgFactory = i1.ɵccf("app-tab-item", i2.TabItemComponent, View_TabItemComponent_Host_0, { title: "title" }, {}, []);
export { TabItemComponentNgFactory as TabItemComponentNgFactory };
