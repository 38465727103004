/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-language-availability.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./product-language-availability.component";
var styles_ProductLanguageAvailabilityComponent = [i0.styles];
var RenderType_ProductLanguageAvailabilityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductLanguageAvailabilityComponent, data: {} });
export { RenderType_ProductLanguageAvailabilityComponent as RenderType_ProductLanguageAvailabilityComponent };
function View_ProductLanguageAvailabilityComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa-icon", [["class", "clickable ng-fa-icon"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAll(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer], { iconProp: [0, "iconProp"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faCheckDouble; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.shortLabel; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_1); }); }
function View_ProductLanguageAvailabilityComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change(_v.parent.context.$implicit, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.countryAvailability == null) ? null : _v.parent.context.$implicit.countryAvailability.includes(_v.context.$implicit.code)); _ck(_v, 1, 0, currVal_0); }); }
function View_ProductLanguageAvailabilityComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductLanguageAvailabilityComponent_4)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.product.availableLanguages; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.trivialName; _ck(_v, 2, 0, currVal_0); }); }
function View_ProductLanguageAvailabilityComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "table", [["class", "table table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Item"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductLanguageAvailabilityComponent_2)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductLanguageAvailabilityComponent_3)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.availableLanguages; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.product.items; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_ProductLanguageAvailabilityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "card-header clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showBody = !_co.showBody) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Country Restrictions "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductLanguageAvailabilityComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBody; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ProductLanguageAvailabilityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-language-availability", [], null, null, null, View_ProductLanguageAvailabilityComponent_0, RenderType_ProductLanguageAvailabilityComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProductLanguageAvailabilityComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductLanguageAvailabilityComponentNgFactory = i1.ɵccf("app-product-language-availability", i6.ProductLanguageAvailabilityComponent, View_ProductLanguageAvailabilityComponent_Host_0, { showBody: "showBody", product: "product" }, {}, []);
export { ProductLanguageAvailabilityComponentNgFactory as ProductLanguageAvailabilityComponentNgFactory };
