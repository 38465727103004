import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { ConfigurationService } from '../configuration.service';
import { SerializationService } from '../serialization.service';
import { AnimationService } from '../animation.service';
import { ModelService } from '../model.service';
import { ThreeService } from '../three.service';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit {
  @ViewChild('canvasContainer') canvasContainer: ElementRef<HTMLElement>;

  public modelUrl: string;
  public productId: string;
  public mywindow = window as any;
  public serializationId: string;

  constructor(
    public configurationService: ConfigurationService,
    public serializationService: SerializationService,
    public animationService: AnimationService,
    public threeService: ThreeService,
    public modelService: ModelService,
    public route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private backendService: BackendService
  ) {
    (window as any).renderingDone = false;
    (window as any).services = {
      three: threeService,
      backend: this.backendService
    };
  }

  @HostListener('window:resize')
  resize() {
    this.threeService.resize();
  }

  async serialize() {
    if (this.productId) {
      let canvas = this.threeService.screenshot();
      this.serializationId = await this.serializationService.serialize(
        this.serializationId,
        canvas
      );

      if (this.configurationService.language.code) {
        this.location.go(`${this.configurationService.language.code}/viewer/${this.productId}/${this.serializationId}`);
      }
    }
  }

  async ngOnInit() {
    this.modelUrl = localStorage.getItem('modelUrl');
    if (this.modelUrl == null) {
      this.modelUrl = '';
    }

    this.route.paramMap.subscribe(async (param: ParamMap) => {
      let id;
      if (param.has('product')) {
        id = param.get('product');
        setInterval(() => {
          this.serialize();
        }, 5000);
      } else {
        (window as any).debug = true;
      }
      if (param.has('uuid')) {
        this.serializationId = param.get('uuid');
        this.serializationService.serializationId = this.serializationId;
      }
      this.threeService.domElement = this.canvasContainer.nativeElement;
      await this.load(id);
      this.animationService.init();
    });
  }

  async load(product?) {
    if (product === undefined) {
      localStorage.setItem('modelUrl', this.modelUrl);
      await this.configurationService._dev_load(this.modelUrl);
    } else {
      try {
        if (this.productId !== product) {
          await this.serializationService.deserialize(this.serializationId, true);
          await this.configurationService.load(product);
          this.productId = product;
          await this.serializationService.deserialize(this.serializationId);
        }
      } catch (e) {
        console.error('Error loading product');
        console.error(e);
        // this.router.navigateByUrl('/viewer');
      }
    }
  }
}
