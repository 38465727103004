import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import {
  faTrashAlt,
  faCaretSquareDown,
  faCaretSquareUp
} from '@fortawesome/free-solid-svg-icons';

import {
  ModelItem,
  Product,
  ConfigurationService
} from '../../configuration.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {

  @Input() public showBody = false;
  @Input() public product: Product;

  constructor(public configurationService: ConfigurationService) {}

  ngOnInit() {}


}
