/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./active-equipment.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./active-equipment.component";
import * as i5 from "../../configuration.service";
import * as i6 from "../../clipboard.service";
import * as i7 from "../../social-share.service";
import * as i8 from "../../google-analytics.service";
var styles_ActiveEquipmentComponent = [i0.styles];
var RenderType_ActiveEquipmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActiveEquipmentComponent, data: {} });
export { RenderType_ActiveEquipmentComponent as RenderType_ActiveEquipmentComponent };
function View_ActiveEquipmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.currentVariant.information.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ActiveEquipmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.configService.getPrice(_v.parent.context.$implicit), _co.configService.language.currency)); _ck(_v, 1, 0, currVal_0); }); }
function View_ActiveEquipmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActiveEquipmentComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActiveEquipmentComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.currentVariant; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.configService.language.showPrice; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.information.shortTitle; _ck(_v, 2, 0, currVal_0); }); }
function View_ActiveEquipmentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tax-included"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("All prices with tax", _co.configService.language)); _ck(_v, 1, 0, currVal_0); }); }
export function View_ActiveEquipmentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵqud(402653184, 1, { inputField: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "heading semi-bold large-show"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "table-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActiveEquipmentComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 9, "div", [["class", "active-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "to-basket clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.configToBasket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "to-basket clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetConfig() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "to-basket clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.social.share("link") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActiveEquipmentComponent_4)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, [[1, 0], ["link", 1]], null, 0, "input", [["class", "hidden-link"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.activeItems; _ck(_v, 9, 0, currVal_1); var currVal_5 = ((_co.activeItems && (_co.activeItems.length > 0)) && _co.configService.language.showPrice); _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Current Configuration")); _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("Configuration into basket")); _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("Reset configuration")); _ck(_v, 15, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Copy configuration link")); _ck(_v, 18, 0, currVal_4); }); }
export function View_ActiveEquipmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-active-equipment", [], null, null, null, View_ActiveEquipmentComponent_0, RenderType_ActiveEquipmentComponent)), i1.ɵdid(1, 114688, null, 0, i4.ActiveEquipmentComponent, [i5.ConfigurationService, i6.ClipboardService, i7.SocialShareService, i8.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActiveEquipmentComponentNgFactory = i1.ɵccf("app-active-equipment", i4.ActiveEquipmentComponent, View_ActiveEquipmentComponent_Host_0, {}, {}, []);
export { ActiveEquipmentComponentNgFactory as ActiveEquipmentComponentNgFactory };
