import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Product, ConfigurationService } from '../../configuration.service';
import { ThreeService } from '../../three.service';

@Component({
  selector: 'app-product-animation',
  templateUrl: './product-animation.component.html',
  styleUrls: ['./product-animation.component.css']
})
export class ProductAnimationComponent implements OnInit, OnChanges {
  @Input() public showBody = false;
  @Input() public product: Product;


  constructor(
    public configService: ConfigurationService,
    public threeService: ThreeService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.product && this.product.animation === undefined) {
      this.product.animation = {
        dependencies: [],
        startState: 'begin',
        timeScale: 1.0
      };
    }
  }
}
