import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import {
  faTrashAlt,
  faCaretSquareDown,
  faCaretSquareUp
} from '@fortawesome/free-solid-svg-icons';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ModelItem,
  Product,
  ConfigurationService
} from '../../configuration.service';

@Component({
  selector: 'app-product-items',
  templateUrl: './product-items.component.html',
  styleUrls: ['./product-items.component.css']
})
export class ProductItemsComponent implements OnInit {
  @Input() public showBody = false;
  @Input() public product: Product;

  @ViewChild('content') private content;

  public faTrashAlt = faTrashAlt;
  public faCaretSquareUp = faCaretSquareUp;
  public faCaretSquareDown = faCaretSquareDown;

  public currentItem: ModelItem;
  constructor(
    public configurationService: ConfigurationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  remove(index: number) {
    this.currentItem = this.product.items[index];
    this.open(this.content).then(() => {
      this.product.items.splice(index, 1);
    });
  }

  move(index: number, direction: 'up' | 'down') {
    let items = this.product.items.splice(index, 1)[0];
    this.product.items.splice(index + (direction === 'up' ? -1 : 1), 0, items);
  }

  create() {
    if (this.product.items === undefined) {
      this.product.items = [];
    }

    this.product.items.push({
      productId: '0',
      objectName: '',
      initialVisibility: true,
      userSelectable: true
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term =>
          term.length < 2
            ? []
            : this.configurationService.modelIds
                .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
                .slice(0, 10)
      )
    )

  open(content: ElementRef) {
    return this.modalService.open(content).result;
  }
}
