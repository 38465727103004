import { PipeTransform } from '@angular/core';
var IsActiveLanguagePipe = /** @class */ (function () {
    function IsActiveLanguagePipe() {
    }
    IsActiveLanguagePipe.prototype.transform = function (value, args) {
        console.log(value);
        if (value && Array.isArray(value)) {
            return value.filter(function (v) { return v.active; });
        }
        else {
            return null;
        }
    };
    return IsActiveLanguagePipe;
}());
export { IsActiveLanguagePipe };
