import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private id = 'UA-9330466-7';

  constructor() {
    (window as any).gtag('js', new Date());
    this.setPage('viewer/e-pilot');
  }

  public send(...args: any[]) {
    // console.log('Sending gtag: ', ...args);
    (window as any).gtag(...args);
  }

  public setPage(page: string) {
    (window as any).gtag('config', this.id, { 'page_path': page });
  }

  public sendEvent(action: string, category: string, label: string, value?: any) {
    (window as any).gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
}
