<div class="col">
  <div class="card">
    <div class="card-header clickable" (click)="showBody = !showBody">
      Cameras
    </div>
    <ul *ngIf="showBody" class="list-group list-group-flush">
      <li class="list-group-item">

        <h3 *ngIf="!(configurationService.isReady)">Loading model file failed</h3>
        <ng-container *ngIf="configurationService.isReady">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Reference</th>
                <th scope="col">Duration</th>
                <th scope="col">Lower Distance</th>
                <th scope="col">Upper Distance</th>
                <th scope="col">Lock Target</th>
                <th scope="col">Lock Position</th>
                <th scope="col">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let perspective of product.perspectives; let i = index">
                <td>
                  <input type="text" class="form-control" name="name" [(ngModel)]="perspective.name">
                </td>
                <td>
                  <select [(ngModel)]="perspective.cameraReference" class="form-control">
                    <option *ngFor="let pov of configurationService.pointsOfView">{{pov.name}}</option>
                  </select>
                </td>
                <td>
                  <input type="number" class="form-control" name="duration" [(ngModel)]="perspective.duration">
                </td>
                <td>
                  <input type="number" class="form-control" name="lowerDistance" [(ngModel)]="perspective.lowerDistance">
                </td>
                <td>
                  <input type="number" class="form-control" name="upperDistance" [(ngModel)]="perspective.upperDistance">
                </td>
                <td>
                  <input type="checkbox" [(ngModel)]="perspective.lockTarget">
                </td>
                <td>
                  <input type="checkbox" [(ngModel)]="perspective.lockPosition">
                </td>
                <td>
                  <fa-icon [icon]="faTrashAlt" (click)="remove(i)" class="clickable"></fa-icon>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary btn-sm" (click)="create()">Create</button>
            </div>
          </div>
        </ng-container>
      </li>
      <li class="list-group-item">
        <form>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="initialCam">Initial Perspective</label>
              <select class="form-control" name="initialPerspective" [(ngModel)]="product.initialPerspective">
                <option *ngFor="let perspective of product.perspectives" [ngValue]="perspective.name">
                  {{perspective.name}}
                </option>
              </select>
            </div>
          </div>
        </form>
      </li>
      <li class="list-group-item">
        <form>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="initialCam">Screenshot Camera</label>
              <select class="form-control" name="screenshotCamera" [(ngModel)]="product.screenshotCamera.cameraReference">
                <option *ngFor="let pov of configurationService.pointsOfView">
                  {{pov.name}}
                </option>
              </select>
            </div>
            <div class="form-group col-6">
              <label for="frustum">Frustum</label>
              <input type="number" class="form-control" name="frustum" [(ngModel)]="product.screenshotCamera.frustum">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="width">Width (px)</label>
              <input type="number" class="form-control" name="width" [(ngModel)]="product.screenshotCamera.width">
            </div>
            <div class="form-group col-6">
              <label for="height">Height (px)</label>
              <input type="number" class="form-control" name="height" [(ngModel)]="product.screenshotCamera.height">
            </div>
          </div>
        </form>
      </li>
    </ul>
  </div>
