import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import {
  TabItemComponent,
  TabItemContent,
  TabItemTitle
} from './tab-item/tab-item.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TabsComponent,
    TabItemComponent,
    TabItemContent,
    TabItemTitle
  ],
  exports: [
    TabsComponent,
    TabItemComponent,
    TabItemContent,
    TabItemTitle
  ]
})
export class ReponsiveTabsModule {}
