import { OnInit, AfterContentInit } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { ThreeService } from '../../three.service';
import { SerializationService } from '../../serialization.service';
import { AnimationService } from '../../animation.service';
import { ProgressService } from '../../progress.service';
var SelectorComponent = /** @class */ (function () {
    function SelectorComponent(configService, threeService, serializationService, animationService, progressService) {
        var _this = this;
        this.configService = configService;
        this.threeService = threeService;
        this.serializationService = serializationService;
        this.animationService = animationService;
        this.progressService = progressService;
        this.maxWidth = 810;
        configService.configurationChanged$.subscribe(function () {
            _this.update();
        });
    }
    SelectorComponent.prototype.ngOnInit = function () { };
    SelectorComponent.prototype.ngAfterContentInit = function () {
        this.resize();
    };
    SelectorComponent.prototype.resize = function () {
        this.small = window.innerWidth <= this.maxWidth;
    };
    SelectorComponent.prototype.update = function () {
        this.variantItems = this.configService.items.filter(function (item) {
            return item.materialVariants && item.materialVariants.length > 0;
        });
    };
    SelectorComponent.prototype.tabChanged = function (tabName) {
        this.currentTabName = tabName;
    };
    return SelectorComponent;
}());
export { SelectorComponent };
