import { Pipe, PipeTransform } from '@angular/core';
import { Language } from './configuration.service';

@Pipe({
  name: 'isActiveLanguage'
})
export class IsActiveLanguagePipe implements PipeTransform {

  transform(value: Language[], args?: any): any {
    console.log(value);
    if (value && Array.isArray(value)) {
      return value.filter(v => v.active);
    } else {
      return null;
    }
  }

}
