import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { ThreeService } from '../three.service';
import { AnimationService } from '../animation.service';
import { TranslateService } from '@ngx-translate/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {
  public window: Window = window;
  public burgerMenu = false;

  notInIframe = true;

  redirectURL
  redirectLabel

  constructor(
    public configService: ConfigurationService,
    public threeService: ThreeService,
    public animationService: AnimationService,
    public translate: TranslateService,
    public route: ActivatedRoute
  ) {
    route.params.subscribe(p => {
      console.log('params', p);
      if (p.hasOwnProperty('lang')) {
        this.configService.languageHint = p.lang;
      }
    });
    route.queryParams.pipe(take(1)).subscribe(p => {
      if (p.hasOwnProperty("redirectURL") && p.hasOwnProperty("redirectLabel")) {
        this.redirectLabel = decodeURIComponent(p.redirectLabel);
        this.redirectURL = decodeURIComponent(p.redirectURL)
      }

    })
    this.notInIframe = this.window == this.window.top;
  }

  ngOnInit() {}

  getLinkBack() {

    if (this.redirectURL != null) {
      return this.redirectURL;
    }

    return this.translate.instant('BACK LINK');
  }
}
