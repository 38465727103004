import { OnInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { faTrashAlt, faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../../configuration.service';
var ProductItemsComponent = /** @class */ (function () {
    function ProductItemsComponent(configurationService, modalService) {
        var _this = this;
        this.configurationService = configurationService;
        this.modalService = modalService;
        this.showBody = false;
        this.faTrashAlt = faTrashAlt;
        this.faCaretSquareUp = faCaretSquareUp;
        this.faCaretSquareDown = faCaretSquareDown;
        this.search = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : _this.configurationService.modelIds
                        .filter(function (v) { return v.toLowerCase().indexOf(term.toLowerCase()) > -1; })
                        .slice(0, 10);
            }));
        };
    }
    ProductItemsComponent.prototype.ngOnInit = function () { };
    ProductItemsComponent.prototype.remove = function (index) {
        var _this = this;
        this.currentItem = this.product.items[index];
        this.open(this.content).then(function () {
            _this.product.items.splice(index, 1);
        });
    };
    ProductItemsComponent.prototype.move = function (index, direction) {
        var items = this.product.items.splice(index, 1)[0];
        this.product.items.splice(index + (direction === 'up' ? -1 : 1), 0, items);
    };
    ProductItemsComponent.prototype.create = function () {
        if (this.product.items === undefined) {
            this.product.items = [];
        }
        this.product.items.push({
            productId: '0',
            objectName: '',
            initialVisibility: true,
            userSelectable: true
        });
    };
    ProductItemsComponent.prototype.open = function (content) {
        return this.modalService.open(content).result;
    };
    return ProductItemsComponent;
}());
export { ProductItemsComponent };
