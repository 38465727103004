import { Pipe, PipeTransform } from '@angular/core';
import { ModelItem, Language } from './configuration.service';

@Pipe({
  name: 'itemCategory'
})
export class ItemCategoryPipe implements PipeTransform {

  transform(value: ModelItem[], category: string): any {

    if (category) {
      if (value && Array.isArray(value)) {
        return value.filter(v => v.category === category);
      } else {
        return null;
      }
    } else {
      return value;
    }
  }

}
